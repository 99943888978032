import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { CustomCurrencyFormat, DateFormat } from "../../../common/Setting";
import { subDomain, StyledActiveLink, showError } from '../../../common/Common';
import { GetAllInsuredPoliciesByUserId, GetAllInsuredPoliciesByPaymentId, GetAllMethodofPayments } from '../../../services/PaymentServices';
import { GetUserProfile } from '../../../services/UserServices';
import ReactPaginate from 'react-paginate';
import { LoaderContents } from "../../layout/LoaderContents";
import $ from 'jquery';

class InsuredDashboard extends Component {
    //this is for insured user
    constructor(props) {
        super(props);
        this.state = {
            AllInsuredPolicies: [],
            userId: null,
            AllInsuredPolicyByPaymentId: [],
            expanded: {},
            AllMethodOfPayments: [],
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 25,
                PageNumber: 1
            },
            nextDueDate: "",
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
            TotalAmountPaid: 0
        };
    }

    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        const user = JSON.parse(localStorage.getItem("UserCredential"));
        if (user != null) {
            this._GetUserProfile();
        }
    };

    // get user profile
    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ userId: response.data.id });
                        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, response.data.id);
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });

    }

    GetAllInsuredPoliciesByUserId = (searchMdl, userId) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllInsuredPoliciesByUserId(searchMdl, userId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                    this.setState({ AllInsuredPolicies: response.data.data });
                    this.ViewDetails(response.data.data[0].paymentId);
                    this.setState({ PageLoaderStatus: false });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    GetAllInsuredPoliciesByPaymentId = (paymentId) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllInsuredPoliciesByPaymentId(paymentId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllInsuredPolicyByPaymentId: response.data });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    _GetAllMethodofPayments = (paymentId, emailAddress) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllMethodofPayments(paymentId, emailAddress).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllMethodOfPayments: response.data });
                    
                    const totalAmountPaid = (response.data).reduce((total, payment) => {
                        return total + parseFloat(payment.amountPaid);
                    }, 0);
                    
                    this.setState({ TotalAmountPaid: totalAmountPaid });
                    var myDate = new Date(response.data[0].datePaid);
                    var result = new Date(myDate.setMonth(myDate.getMonth() + 1));
                    var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                    var t = (month[result.getMonth()] +
                        " " + result.getDate() +
                        ", " + result.getFullYear());
                    this.setState({ nextDueDate: t });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    static displayName = InsuredDashboard.name;
    onClickHandler = (target) => {
        //const hiddenElement = e.currentTarget.nextSibling;
        const hiddenElement = document.querySelector(".section-" + target);
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    ViewDetails = (paymentId) => {
        this.setState({
            expanded: {
                [paymentId]: !this.state.expanded[paymentId]
            }
        });
        this.setState({ AllInsuredPolicyByPaymentId: [] });
        GetAllInsuredPoliciesByPaymentId(paymentId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllInsuredPolicyByPaymentId: response.data });
                    this._GetAllMethodofPayments(paymentId, response.data[0].emailAddress)
                }
                else {
                    showError(response.message);
                }
                //this.setState({ SaveLoaderStatus: false });
            }
        });
    };

    //start funstions for pagination
    handlePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, this.state.userId);
    }

    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, this.state.userId);
    }

    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, this.state.userId);
    }

    //end pagination function
    handleChange = (selectedUsers) => {
        this.setState({ selectedUsers });
    };
    
    toggleDetails = (paymentId) => {
        const detailsRow = document.getElementById(paymentId);
        detailsRow.style.removeProperty('display');
    }


    render() {
        const { AllInsuredPolicyByPaymentId } = this.state;

        let url = subDomain().SubDomain === 'Xgen' ? "" : "/" + subDomain().SubDomain;
        return (
            <>
                <div className="main-wrapper-newDesign account-overview">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <h1 className="h3 mb-0 fw-normal"><strong>Policy Information</strong></h1>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Account Number" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>
    
                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Billing Account Number</th>
                                                        <th>Policy Number</th>
                                                        <th>Insurance Company</th>
                                                        <th className="mobile-table">Monthly Payment</th>
                                                        <th className="mobile-table">Policy Status</th>
                                                        <th className="mobile-table">Number of Payments</th>
                                                        <th className="mobile-table">Effective Date</th>
                                                        <th className="mobile-table">Expiration Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.AllInsuredPolicies.map((items) => (
                                                        <Fragment key={items.id}>
                                                            <tr >
                                                                <td id={items.paymentId} className="mobile-table">{items.billingAccountNumber}</td>
                                                                <td><span className="clickable" onClick={() => this.ViewDetails(items.paymentId)}>{items.policyNumber}</span></td>
                                                                <td>{items.insuranceCompany}</td>
                                                                <td id={items.paymentId} className="mobile-table">{CustomCurrencyFormat(items.minimumPaymentDue)}</td>
                                                                <td id={items.paymentId} className="mobile-table">{items.policyStatus}</td>
                                                                <td id={items.paymentId} className="mobile-table">{items.policyPeriod}</td>
                                                                <td id={items.paymentId} className="mobile-table">{DateFormat(items.policyEffectiveDate)}</td>
                                                                <td id={items.paymentId} className="mobile-table">{DateFormat(items.expirationDate)}</td>
                                                                <td id={items.paymentId} className="mobile-table">
                                                                    <div className="button-container mobile-table">
                                                                        <button type="button" onClick={() => this.ViewDetails(items.paymentId)} className="btn btn-newDesign">View</button>
                                                                        <NavLink to={url + "/make-payment?PaymentId=" + items.paymentId + ""} className='btn btn-newDesign-foil'>Pay Now</NavLink>
                                                                    </div>
                                                                    </td>
                                                            </tr>
                                                           
                                                            {this.state.expanded[items.paymentId] &&
                                                                <tr className="table-expanded">
                                                                    <td colSpan="10">
                                                                        {AllInsuredPolicyByPaymentId.map((details, index) => (
                                                                            index === 0 && (
                                                                        <div className="card" key={index}>
                                                                            <div className="ml-auto">
                                                                                <NavLink to={url + "/make-payment?PaymentId=" + items.paymentId + ""} className='d-none btn btn-newDesign-foil mobile-table-show'>Pay Now</NavLink>
                                                                                {details.remainingMonths != 0 &&
                                                                                    <h6 className="mt-2 mb-3"><span className="text-danger px-3">Next Due Date : {this.state.nextDueDate}</span></h6>
                                                                                }
                                                                            </div>
                                                                            {/*<hr />*/}
                                                                            <div className="align-items-baseline ">
                                                                                <div className="ml-auto p-0">
                                                                                    <table className="table table-clear">
                                                                                        <thead>
                                                                                            <tr className="table-header-no-color">
                                                                                                <th>Premium Amount</th>
                                                                                                <th>Account Balance</th>
                                                                                                <th>Number of Remaining Payments</th>
                                                                                                <th>Next Payment Amount</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {CustomCurrencyFormat(details.premimumAmount)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {CustomCurrencyFormat(details.accountBalance)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {details.remainingMonths}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {CustomCurrencyFormat(details.minimumPaymentDue)}
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div className="ml-auto">
                                                                                        <h6 className="mb-3 pt-3 px-3">Payment History</h6>
                                                                                        <table className="table table-clear">
                                                                                            <thead>
                                                                                                <tr className="table-header-no-color">
                                                                                                    <th>Date paid</th>
                                                                                                    <th>Amount paid</th>
                                                                                                    <th>Method of payment</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                    {this.state.AllMethodOfPayments.map((items1) => (
                                                                                                        <>
                                                                                                            <tr>
                                                                                                            <td className="left">{DateFormat(items1.datePaid)}</td>
                                                                                                            <td className="right">{CustomCurrencyFormat(items1.amountPaid)}</td>
                                                                                                                <td className="right">{items1.methodofPayment}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    {/*<div className="ml-auto border">*/}
                                                                                    {/*    <h6 className="border-bottom mb-3 p-3 pt-3">Wallet History :</h6>*/}
                                                                                    {/*    <table className="table table-clear">*/}
                                                                                    {/*        <thead>*/}
                                                                                    {/*            <tr>*/}
                                                                                    {/*            <th>Date paid</th>*/}
                                                                                    {/*                <th>Commision Amount</th>*/}
                                                                                    {/*            </tr>*/}
                                                                                    {/*        </thead>*/}
                                                                                    {/*        */}{/*<tbody>*/}
                                                                                    {/*        */}{/*    {this.state.AllMethodOfPayments.map((items1) => (*/}
                                                                                    {/*        */}{/*        <>*/}
                                                                                    {/*        */}{/*            <tr>*/}
                                                                                    {/*        */}{/*                <td className="left">{DateFormat(items1.datePaid)}</td>*/}
                                                                                    {/*        */}{/*                <td className="right">{details.flatCommission === 0.00 ? CustomCurrencyFormat(items1.amountPaid / details.commissionPercent) : details.flatCommission}</td>*/}
                                                                                    {/*        */}{/*            </tr>*/}
                                                                                    {/*        */}{/*        </>*/}
                                                                                    {/*        */}{/*    ))}*/}
                                                                                    {/*        */}{/*</tbody>*/}
                                                                                    {/*    </table>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )))}
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {this.state.expanded[items.paymentId] && AllInsuredPolicyByPaymentId.length === 0 &&
                                                                <tr className="table-bg">
                                                                    <td colSpan="10" className="pd-space">No Data</td>
                                                                </tr>
                                                            }
                                                        </Fragment>
                                                    ))
                                                    }

                                                </tbody>
                                            </table>
                                            <p className="text-center">
                                                {(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
                                            </p>
                                        </div>
                                        <div className="pt20px">
                                            { /*loader show befoure load contents*/}
                                            {this.state.PageLoaderStatus && <LoaderContents />}
                                        </div>
                                        <div className="d-flex paginate-div">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination justify-content-start"}
                                                pageCount={this.state.Pagecount ?? 0}
                                                pageClassName={"page-item page-item-none"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item page-item-none"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                                disabledLinkClassName={"disabled"} />
                                                <div className="pagination-page">
                                                    Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
            </>
        );
    }
}
export default InsuredDashboard;