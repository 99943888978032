import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { NavLink } from 'react-router-dom';
import { authHeader, subDomain } from '../../../common/Common';
import { FlowGroups, Reportsfilters } from '../../../common/Enum';
class Reports extends Component {
    render() {
        let url = (subDomain() === null || subDomain().SubDomain === 'Xgen') ? "" : "/" + subDomain().SubDomain;
        let userDetails = authHeader();
        let isSuperAdmin = false;

        isSuperAdmin = userDetails.UserRoleIds.some(element => {
            if (element === 2) {
                return true;
            }
        });
        return (

            <div className="main-wrapper-newDesign w-100">
                <InternalLayoutHeader />
                <div className="content-main p-lg-4 p-3">
                    <h1 className="h3 mb-3 fw-normal"><strong>Reports</strong></h1>
                    {(FlowGroups.Users === authHeader().FlowGroupId || FlowGroups.SuperAdmin === authHeader().FlowGroupId) &&
                        <div className="row reports-content">
                        {subDomain().SubDomain != "geico" &&
                                <>

                                    <div className="col-lg-3  col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountsDetailsbyInsured}`} className="change-payment-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Billing Account Detail By Insured</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3  col-md-3 col-sm-6  col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountsPending}`} className="change-payment-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Pending Billing Accounts</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    {isSuperAdmin ? <>
                                        <div className="col-lg-3  col-md-3 col-sm-6 col-12">
                                            <div className="mysetting-btn">
                                                <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.CommissionPaidandDue}`} className="autopay-button">
                                                    <div className="icon-svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                    </div>
                                                    <div className="btn-content">Wallet Reconciliation Report</div></NavLink>
                                            </div>
                                        </div>
                                    </> : ''}

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountsDue}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Billing Accounts Due</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountsLate}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Billing Accounts Late</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountsCancelled}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Billing Accounts Cancelled </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountyDetailsByPaymentTerm}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Billing Account Summary By Payment Term</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.PaymentTermSummaryByInsuredwtAgencyId}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Payment Term Summary By Insured And Agency Total</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    {isSuperAdmin ? <>
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                            <div className="mysetting-btn">
                                                <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.BillingAccountDetailsByAgents}`} className="change-address-btn">
                                                    <div className="icon-svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                    </div>
                                                    <div className="btn-content">Billing Account Detail By Agent</div>
                                                </NavLink>
                                            </div>
                                        </div>


                                        <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                            <div className="mysetting-btn">
                                                <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.AgentMasterLists}`} className="change-address-btn">
                                                    <div className="icon-svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                    </div>
                                                    <div className="btn-content">Agent Master List</div>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </> : ''}


                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.AgentMasterLists}`} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Endorsement Activity Detail</div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={url + "/reports-filter"} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Outstanding Balance And Cancellation Balance Detail</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={url + "/reports-filter"} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Insurance Company And Coverage Type By Program</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={url + "/reports-filter"} className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Payment Term Summary At The Producing Level</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to="#" className="change-address-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Cancellation By Non-pay</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="col-lg-3  col-md-3 col-sm-6 col-12">
                                <div className="mysetting-btn">
                                    <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.PaymentHistory}`} className="change-payment-btn">
                                        <div className="icon-svg">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                        </div>
                                        <div className="btn-content">Payment History</div>
                                    </NavLink>
                                </div>
                            </div>
                        {subDomain().SubDomain != "geico" && subDomain().SubDomain != "knight" &&
                                <>
                                    <div className="col-lg-3  col-md-3 col-sm-6 col-12">
                                        <div className="mysetting-btn">
                                            <NavLink to={`${url}/reports-filter?filter=${Reportsfilters.PaymentLog}`} className="change-payment-btn">
                                                <div className="icon-svg">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M7 22v-16h14v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-5.362zm16-7.614v-10.386h-18v20h8.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-4v-1h4v1zm6-4h-10v1h10v-1zm0-3h-10v1h10v-1zm1-7h-17v19h-2v-21h19v2z" /></svg>
                                                </div>
                                                <div className="btn-content">Payment Logs</div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </>
                            }

                        </div>
                    }
                </div>
                <SiteFooter />  {/*this call for site footer */}
            </div>
        );
    }
}
export default Reports;