import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { DateFormatShort } from "../../../common/Setting";
import {LoaderContents} from "../../layout/LoaderContents";
import {GetAllAISAccountInformation, GetAllAISAccountActivity} from "../../../services/AISServices";
import {showError, subDomain} from "../../../common/Common";
import Select from "react-select";
import ReactPaginate from "react-paginate";

class AISSupportAccountActivity extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1
            },
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
            AISAccounts: [],
            SubDomain: subDomain(),
            AISAccountActivity: [],
            SelectedAISAccountActivity: [],
            Pagecount: 0,
            PaginationAISAccounts: {},
            cachedBillingAccount: JSON.parse(localStorage.getItem("cachedBillingAccount"))
        }
    }
    
    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        this._GetAllAISAccountInformation();
    };
    
     _GetAllAISAccountInformation() {
        GetAllAISAccountInformation().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({AISAccounts: response.data});
                        this.setState({PageLoaderStatus: false});
                        if (this.state.cachedBillingAccount) {
                            this.setState({selectedOption: this.state.cachedBillingAccount});
                        } else {
                            const firstOption = {
                                value: response.data[0].userId,
                                label: response.data[0].insuredName,
                                aisAccountNumber: response.data[0].billingAccountNumber,
                            };
                            this.setState({selectedOption: firstOption});
                            localStorage.setItem("cachedBillingAccount", JSON.stringify({
                                billingAccountNumber: response.data[0].billingAccountNumber,
                                value: response.data[0].userId,
                                label: response.data[0].insuredName
                            }));
                        }
                        this._GetAllAISAccountActivity();
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }
    
     _GetAllAISAccountActivity() {
        GetAllAISAccountActivity().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ AISAccountActivity: response.data.sort((a, b) => new Date(b.datePaid) - new Date(a.datePaid)) });
                        this.setState({ PageLoaderStatus: false });
                        this.setState({
                            SelectedAISAccountActivity: this.state.AISAccountActivity.filter(activity => activity.userId === this.state.selectedOption.value)
                        });
                        this.setState({ Pagecount: Math.ceil((this.state.SelectedAISAccountActivity.length / this.state.searchMdl.LimitTo)) });
                        if (this.state.SelectedAISAccountActivity.length > this.state.searchMdl.LimitTo) { // Pagination
                            this.setState({PaginationAISAccounts: this.createPagination(this.state.SelectedAISAccountActivity)})
                            this.setState({SelectedAISAccountActivity: this.state.PaginationAISAccounts[1]})
                        }
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }
    
    handleSearchData = (event) => {
         if (event.target.value === "") {
             this.setState({
                SelectedAISAccountActivity: this.state.AISAccountActivity.filter(activity => activity.userId === this.state.selectedOption.value)
            });
            this.setState({ Pagecount: Math.ceil((this.state.SelectedAISAccountActivity.length / this.state.searchMdl.LimitTo)) });
            if (this.state.SelectedAISAccountActivity.length > this.state.searchMdl.LimitTo) { // Pagination
                this.setState({PaginationAISAccounts: this.createPagination(this.state.SelectedAISAccountActivity)})
                this.setState({SelectedAISAccountActivity: this.state.PaginationAISAccounts[1]})
            }
         }
         else {
            let allAIS = this.state.AISAccountActivity.filter(activity => activity.userId === this.state.selectedOption.value)
            this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
            let searchAIS = allAIS.filter(activity => 
                activity.methodofPayment.toLowerCase().includes(event.target.value.toLowerCase()));
            this.setState({ Pagecount: Math.ceil((allAIS.length / this.state.searchMdl.LimitTo)) });
            if (searchAIS.length > this.state.searchMdl.LimitTo) { // Pagination
                let pagination = this.createPagination(searchAIS)
                this.setState({PaginationAISAccounts: pagination})
                this.setState({SelectedAISAccountActivity: pagination[1]})
            }
            else {
                this.setState({SelectedAISAccountActivity: searchAIS})
                this.setState({Pagecount: 1})
                this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
            }
         }
    }
    
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        let allAIS = this.state.AISAccountActivity.filter(activity => activity.userId === this.state.selectedOption.value)
        this.setState({ Pagecount: Math.ceil((allAIS.length / this.state.searchMdl.LimitTo)) });
        if (allAIS.length > this.state.searchMdl.LimitTo) { // Pagination
            let pagination = this.createPagination(allAIS)
            this.setState({PaginationAISAccounts: pagination})
            this.setState({SelectedAISAccountActivity: pagination[1]})
        }
        else {
            this.setState({SelectedAISAccountActivity: allAIS})
            this.setState({Pagecount: 1})
            this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
        }
    }
    
    handlePageClick = (data) => {
		let PageNumber = data.selected + 1;
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this.setState({SelectedAISAccountActivity: this.state.PaginationAISAccounts[PageNumber]});
	}
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        const billingAccountNumber = selectedOption.aisAccountNumber;
        const userId = selectedOption.value;
        const label = selectedOption.label;
        localStorage.setItem("cachedBillingAccount", JSON.stringify({billingAccountNumber: billingAccountNumber, value: userId, label: label}));
        
        this.ChangeSelectedInsured(userId);
    };
    
     ChangeSelectedInsured = (userId) => {
         this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
        this.setState((prevState) => {
            const selectedActivities = prevState.AISAccountActivity.filter(activity => activity.userId === userId);
            const pageCount = Math.ceil(selectedActivities.length / prevState.searchMdl.LimitTo);
            let paginatedAccounts = [];
            if (selectedActivities.length > prevState.searchMdl.LimitTo) {
                paginatedAccounts = this.createPagination(selectedActivities);
            }
            const pageKeys = Object.keys(paginatedAccounts);
            return {
                SelectedAISAccountActivity: Object.keys(paginatedAccounts).length > 0 ? paginatedAccounts[1] : selectedActivities,
                Pagecount: pageCount,
                PaginationAISAccounts: paginatedAccounts
            };
        });
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
    }
    
    createPagination = (arr)=> {
        const limit = parseInt(this.state.searchMdl.LimitTo, 10);
        const hashmap = {};
        let pageNumber = 1;
        for (let i = 0; i < arr.length; i += limit) {
          hashmap[pageNumber] = arr.slice(i, i + limit);
          pageNumber++;
        }
        return hashmap;
    }
    render() {
        const options = this.state.AISAccounts.map((items) => ({
            value: items.userId,
            label: items.insuredName,
            aisBillingAccountNumber: items.billingAccountNumber
        }));
        const { Pagecount } = this.state;
        return (
            <>
                <div className="main-wrapper-newDesign account-overview w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">    
                        <h1 className="h3 mb-0 fw-normal"><strong>Account Activity</strong></h1>
                        <Select className="form-control input-newDesign input-wrapper-payment" 
                            options={options}
                            onChange={this.handleChange}
                            isSearchable
                            value={this.state.selectedOption}>
                        </Select>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Description" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>
            
                                            <div className="card-header bg-white border-white p-2 custom-select">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="Details-table-header">Date</th>
                                                        <th className="Details-table-header">Amount</th>
                                                        <th className="Details-table-header">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.SelectedAISAccountActivity.map((activity, index) => (
                                                    <tr key={index}>
                                                        <td className="Details-table-header">{DateFormatShort(new Date(activity.datePaid).toDateString())}</td>
                                                        <td className="Details-table-header">${activity.amountPaid}</td>
                                                        <td className="Details-table-header">{activity.methodofPayment}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex paginate-div">
											<ReactPaginate
												previousLabel={"Previous"}
												nextLabel={"Next"}
												breakLabel={"..."}
												pageCount={Pagecount ?? 0}
												marginPagesDisplayed={"4"}
												pageRangeDisplayed={"3"}
												onPageChange={this.handlePageClick}
												containerClassName={"pagination justify-content-start"}
												pageClassName={"page-item page-item-none"}
												pageLinkClassName={"page-link"}
												previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
												nextClassName={"page-item"}
												nextLinkClassName={"page-link"}
												breakClassName={"page-item page-item-none"}
												breakLinkClassName={"page-link"}
												activeClassName={"active"}
												disabledLinkClassName={"disabled"}
                                                forcePage={this.state.searchMdl.PageNumber - 1}/>
											<div className="pagination-page">
												Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
											</div>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />
                </div>
            </>
        );
    }
}
export default AISSupportAccountActivity;