import { authHeader } from '../common/Common';

//GetAISResponseByPaymentId
async function GetAISResponseByPaymentId(PaymentId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch(`/api/AIS/GetAISResponseByPaymentId/${PaymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllAISAccountInformation() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch(`/api/AIS/GetAllAisAccountInformation/`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllAISAccountActivity() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch(`/api/AIS/GetAllAisAccountActivity/`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

export {
    GetAISResponseByPaymentId,
    GetAllAISAccountInformation,
    GetAllAISAccountActivity
};