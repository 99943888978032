import React, { Component } from 'react';
import { Launcher } from 'react-chat-window'
export class LiveChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageList: []
        };
       
    }
    _onMessageWasSent(message) {
        this.setState({
            messageList: [...this.state.messageList, message]
        })
    }

    _sendMessage(text) {
        if (text.length > 0) {
            this.setState({
                messageList: [...this.state.messageList, {
                    author: 'them',
                    type: 'text',
                    data: { text }
                }]
            })
        }
    }
    render() {
        return (
			<>
                <Launcher
                    agentProfile={{
                        teamName: 'XGEN',
                        imageUrl: 'assets/Content/images/chat-user.png'
                    }}
                    onMessageWasSent={this._onMessageWasSent.bind(this)}
                    messageList={this.state.messageList}
                    showEmoji
                />
            </>
        );
    }
}

