import React, { Component } from 'react';
import validator from 'validator';
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { LoaderContents } from "../../layout/LoaderContents";
import { ChangePassword, ChangeAddress, GetUserProfile } from '../../../services/UserServices';
import { GetPaymentProfile, UpsertPaymentProfile, GetStripeCustomer, GetAllRetrivePaymentAccount, UpdatePaymentMethod, GetAllRetriveAccountInfo, GetAISAccountInfoById, GetCustomerDetailById, GetCustomerDetailByToken, UpdateAutoPayStatus } from '../../../services/PaymentServices';
import { DeleteCustomerPaymentMethod } from '../../../services/PaymentAPIServices';
import GetPlace from "../../../services/GetPlace";
import { showError, showSuccess, Button } from '../../../common/Common';
import $ from 'jquery';
import { PaymentTypeList } from '../../../common/Enum';
import CreditCardForm from '../Common/CreditCardForm';
import ACHForm from '../Common/ACHForm';
import {CardElement, useStripe, useElements, Elements} from "@stripe/react-stripe-js";
import {stripePromise} from "../../../common/Setting";


class InsuredMySettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Errors: {},
            ChangePassLoaderStatus: false,
            ChangeAddressLoaderStatus: false,
            LoaderStatus: false,
            PaymentProfileMdl:
            {
                paymentProfileId: 0,
                paymentTypeId: 1,
                achAccountHolderName: null,
                achRoutingNumber: null,
                achAccountNumber: null,
                creditCardHolderName: null,
                creditCardNumber: null,
                creditCardExpiryMonth: null,
                creditCardExpiryYear: null,
                creditCardCVV: null,
                creditCardExpiry: null,
                is_AutoPay: true
            },
            UserProfileMdl:
            {
                address: null
            },
            CustomerDetailMdl: {},
            DeleteLoader: false,
            Disabled: true,
            Error: null,
            IsChecked: 2,
            isOutAutoPay: true,
            InsuredId: null,
            AutoPayLoaderStatus: true, 
            aisAccountNumber: null,
            UpdatePaymentMethodLoader: false,
            subDomainId: "",
            loading: true,
        };
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangePaymentInfo = this.handleChangePaymentInfo.bind(this);
    }

    componentDidMount() {
        this._GetUserProfile();
        this.togglePaymentForms(this.state.PaymentProfileMdl.paymentTypeId);
        
    }
    //form submit on enter key down (change password)
    _ChangePassword_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangePassword();
        };
    };
    //form submit on enter key down (change payment method)
    _UpsertPaymentProfile_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._UpsertPaymentProfile();
        };
    };
    //form submit on enter key down (change payment info auto pay)
    //_UpsertCreditCardPaymentInfo_EnterButton = (event) => {
    //    let keyCode = event.which || event.keyCode;
    //    if (keyCode === 13) {
    //        this._UpsertCreditCardPaymentInfo();
    //    };
    //};
    //form submit on enter key down (change payment info auto pay)
    _ChangeAddress_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangeAddress();
        };
    };
    //get payment profile
    _GetPaymentProfile = () => {
        this.setState({ LoaderStatus: true });
        this._GetUserProfile();
        GetPaymentProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data !== null) {
                        this.setState({ PaymentProfileMdl: response.data });
                        if (response.data.paymentTypeId === PaymentTypeList.ACH) {
                            $("#radio-ACH").prop("checked", true);
                            $("#radio-CreditCard").prop("checked", false);
                        }
                        if (response.data.paymentTypeId === PaymentTypeList.CreditCard) {
                            $("#radio-CreditCard").prop("checked", true);
                            $("#radio-ACH").prop("checked", false);
                        }
                    }
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    //change payment method
    _UpsertPaymentProfile = () => {
        this.setState({ LoaderStatus: true }); // show loader
        UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ PaymentProfileMdl: response.data });
                    window.$('#modalChangePaymentMethod').modal('hide');
                    showSuccess(response.message);
                    window.$('#modalChangeAccountInfo').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    // change payment info
    _UpsertPaymentInfo = () => {
        let status = this.PaymentInfoValidation(this.state.PaymentProfileMdl);
        if (status) {
            this.setState({ LoaderStatus: true }); // show loader
            UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ PaymentProfileMdl: response.data });
                        window.$('#modalChangeAccountInfo').modal('hide');
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }

    //_UpsertCreditCardPaymentInfo = () => {
    //    const stripe = useStripe();
    //    const elements = useElements();
    //    const cardElement = elements.getElement(CardElement);
       
    //    var t = stripe.createPaymentMethod({
    //            type: 'card',
    //            card: elements,
    //            billing_details: {
    //                name: 'Jenny Rosen',
    //            },
    //        })
    //        .then(function (result) {
    //            // Handle result.error or result.paymentMethod
    //        });

    //    let status = this.PaymentInfoValidation(this.state.PaymentProfileMdl);
    //    if (status) {
    //        this.setState({ LoaderStatus: true }); // show loader
    //        UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
    //            let response = data;
    //            if (response.message !== Error) {
    //                if (response.status === true) {
    //                    this.setState({ PaymentProfileMdl: response.data });
    //                    window.$('#modalChangeAccountInfo').modal('hide');
    //                    showSuccess(response.message);
    //                }
    //                else {
    //                    showError(response.message);
    //                }
    //                this.setState({ LoaderStatus: false }); // hide loader
    //            }
    //        });
    //    }
    //}

    _DeletePaymentMethod = (CusId, Token) => {
        this.setState({ DeleteLoader: true });
        DeleteCustomerPaymentMethod(CusId, Token, this.state.subDomainId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this._GetUserProfile();
                    window.$('#confirmdeletemdl').modal('hide');
                    showSuccess(response.message);
                    this.setState({ CustomerDetailMdl: {} })
                }
                else {
                    showError(response.message);
                }
                this.setState({ DeleteLoader: false }); // hide loader
            }
        });

    }

    //validation for change payment info
    PaymentInfoValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.paymentTypeId === PaymentTypeList.ACH) {
            if (mdl.achAccountHolderName === null || mdl.achAccountHolderName.trim() === "" || mdl.achAccountHolderName === undefined) {
                errors['IsEmpty_achAccountHolderName'] = true;
                errors['achAccountHolderNameMsg'] = "Required field: Account Holder Name";
                count++;
            }
            if (mdl.achRoutingNumber === null || mdl.achRoutingNumber.trim() === "" || mdl.achRoutingNumber === undefined) {
                errors['IsEmpty_achRoutingNumber'] = true;
                errors['achRoutingNumberMsg'] = "Required field: Routing Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achRoutingNumber)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.achRoutingNumber, 9, 9)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Should contain at least 9 digit";
                    count++;
                }
            }
            if (mdl.achAccountNumber === null || mdl.achAccountNumber.trim() === "" || mdl.achAccountNumber === undefined) {
                errors['IsEmpty_achAccountNumber'] = true;
                errors['achAccountNumberMsg'] = "Required field: Account Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achAccountNumber)) {
                    errors['IsEmpty_achAccountNumber'] = true;
                    errors['achAccountNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
        }
        if (mdl.paymentTypeId === PaymentTypeList.CreditCard) {
            if (mdl.creditCardHolderName === null || mdl.creditCardHolderName.trim() === "" || mdl.creditCardHolderName === undefined) {
                errors['IsEmpty_creditCardHolderName'] = true;
                errors['creditCardHolderNameMsg'] = "Required field: Card Holder Name";
                count++;
            }
            if (mdl.creditCardNumber === null || mdl.creditCardNumber.trim() === "" || mdl.creditCardNumber === undefined) {
                errors['IsEmpty_creditCardNumber'] = true;
                errors['creditCardNumberMsg'] = "Required field: Card Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardNumber)) {
                    errors['IsEmpty_creditCardNumber'] = true;
                    errors['creditCardNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
            if (mdl.creditCardExpiryMonth === null || mdl.creditCardExpiryMonth.trim() === "" || mdl.creditCardExpiryMonth === undefined) {
                errors['IsEmpty_creditCardExpiryMonth'] = true;
                errors['creditCardExpiryMonthMsg'] = "Required field: Month";
                count++;
            }
            if (mdl.creditCardExpiryYear === null || mdl.creditCardExpiryYear.trim() === "" || mdl.creditCardExpiryYear === undefined) {
                errors['IsEmpty_creditCardExpiryYear'] = true;
                errors['creditCardExpiryYearMsg'] = "Required field: Year";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardExpiryYear)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid contain : year";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardExpiryYear, 4, 4)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid year format";
                    count++;
                }
            }
            if (mdl.creditCardCVV === null || mdl.creditCardCVV.trim() === "" || mdl.creditCardCVV === undefined) {
                errors['IsEmpty_creditCardCVV'] = true;
                errors['creditCardCVVMsg'] = "Required field: CVV";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardCVV)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardCVV, 3, 4)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Should contain at least 3 or 4 digit";
                    count++;
                }
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    _GetAllRetrivePaymentAccount = async (paymentDetail, subDomainId) => {
        if (paymentDetail?.aisAccountNumber) {
            this.setState({ ContentsLoaderStatus: true });
            const requestModel = {
                BillingAccountNumber: paymentDetail?.aisAccountNumber,
                ConsumerReferenceNumber: "",
                subDomainId: subDomainId
            };
            this.setState({ aisAccountNumber: paymentDetail?.aisAccountNumber });
            try {
                this.setState({ loading: true });
                const res = await GetAllRetrivePaymentAccount(requestModel);
                const response = res.data?.data;

                const CcdPaymentAccount = response?.uiCcdPaymentAccounts;
                const AchPaymentAccount = response?.uiAchPaymentAccounts;

                const resp = await GetAllRetriveAccountInfo(requestModel);
                const response1 = resp.data?.data?.uiBillingAccountInfo[0];
                const isAutoPay = response1?.autoPaySetup;
                const autoPayAccountType = response1?.autoPayAccountType;
                this.setState({ isOutAutoPay: isAutoPay == "YES" ? true : false });
                if (isAutoPay === "YES") {
                    if (autoPayAccountType === "CCD") {
                        const ccdData = await GetCustomerDetailById(paymentDetail.insuredId, 1);
                        const tokenSetValues = new Set(ccdData.data?.token.map(item => item.token));
                        const account = CcdPaymentAccount.find(
                            (account) => tokenSetValues.has(account.cardToken) && (account.usedForAutopay === "YES" || account.usedOnlyForAutopay === "YES")
                        );
                        if (account) {
                            this.setState(prevState => ({ CustomerDetailMdl: { ...prevState.CustomerDetailMdl, customerDetails: { ...prevState.CustomerDetailMdl.customerDetails, token: account.cardToken, id: paymentDetail.insuredId, name: response1.insuredName1 }, cardDetails: { ...prevState.CustomerDetailMdl.cardDetails, CardBrand: account.cardScheme, last4: account.cardLastFourDigit, token: account.cardToken } } }));
                            this.setState(prevState => ({ PaymentProfileMdl: { ...prevState.PaymentProfileMdl, paymentTypeId: 1 } }));
                        }
                    } else {
                        const achData = await GetCustomerDetailById(paymentDetail.insuredId, 2);
                        const tokenSetValues = new Set(achData.data?.token.map(item => item.token));
                        const account = AchPaymentAccount.find(
                            (account) => tokenSetValues.has(account.bankAccountToken) && (account.usedForAutopay === "YES" || account.usedOnlyForAutopay === "YES")
                        );
                        console.log("account", account);
                        if (account) {
                            this.setState(prevState => ({ CustomerDetailMdl: { ...prevState.CustomerDetailMdl, customerDetails: { ...prevState.CustomerDetailMdl.customerDetails, token: account.bankAccountToken, id: paymentDetail.insuredId, name: response1.insuredName1 }, usBankDetails: { ...prevState.CustomerDetailMdl.usBankDetails, routingNumber: account.bankAbaNumber, last4: account.bankAccountLastFourDigit, token: account.bankAccountToken } } }));
                            this.setState(prevState => ({ PaymentProfileMdl: { ...prevState.PaymentProfileMdl, paymentTypeId: 2 } }));
                            this.setState({ loading: false });
                        }
                    }
                }
                this.setState({ AutoPayLoaderStatus: false })
                this.setState({ loading: false });
            } catch (error) {
                console.error("Error occurred while retrieving payment account:", error);
            } finally {
                this.setState({ ContentsLoaderStatus: false });
            }
        }
    };

    // _GetCustomerDetailByToken
    //_GetCustomerDetailByToken = (token) => {
    //    GetCustomerDetailByToken(token).then(res => {
    //        console.log("customer tabl response", res)
    //        let response = res.data
    //        const cardExpiry = response.ccExpiryMonth + '/' + response.ccExpiryYear.slice(-2);
    //        this.setState(prevState => ({ PaymentProfileMdl: { ...prevState.PaymentProfileMdl, paymentTypeId: response.paymentType, creditCardHolderName: response.ccHolderName, creditCardExpiry: cardExpiry, creditCardNumber: response.ccNumber, achAccountHolderName: response.achAccountHolderName, achRoutingNumber: response.achRoutingNumber, achAccountNumber: response.achAccountNumber, achBankName: '' } }));
    //        this.setState({ AutoPayLoaderStatus: false})
    //        //PaymentProfileMdl.creditCardHolderName, creditCardExpiry, creditCardNumber, achAccountHolderName, achRoutingNumber, achAccountNumber , achBankName
    //    });
    //}

    // get user profile
    _GetUserProfile = () => {
        this.setState({ LoaderStatus: true });
        this.setState({ loading: true });
        GetUserProfile().then(data => {
            let response = data;
            this.setState({ subDomainId: response.data.subDomainId });
            GetAISAccountInfoById(response?.data?.id).then(respons => {
                this._GetAllRetrivePaymentAccount(respons.data, response.data.subDomainId)
            });
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ UserProfileMdl: response.data.userInfoMdl });
                        this.setState({ InsuredId: response?.data?.id })
                        this.setState({ LoaderStatus: false });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });

    }

    handleChangePaymentMethod = (event) => {
        const paymentTypeId = event.target.value;
        this.setState(prevState => ({
            PaymentProfileMdl: {
                ...prevState.PaymentProfileMdl,
                paymentTypeId: parseInt(paymentTypeId)
            }
        }));
        this.togglePaymentForms(paymentTypeId);
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'creditCardExpiry') {
            let formattedValue = value.replace(/\D/g, ''); // Remove all non-numeric characters
            let month = '';
            let year = '';
            if (formattedValue.length >= 3) {
                month = parseInt(formattedValue.slice(0, 2), 10);
                year = formattedValue.slice(2, 4);

                if (month > 12) {
                    month = (month.toString().charAt(0)).padStart(2, '0') % 12 || 12; // Map values like 13 -> 01, 35 -> 11
                }

                formattedValue = `${String(month).padStart(2, '0')}/${year}`;

            }
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    creditCardExpiryMonth: String(month).padStart(2, '0'),
                    creditCardExpiryYear: `20${year}`,
                    creditCardExpiry: formattedValue
                }
            }));

        }
        else if (name === 'creditCardNumber') {
            let formattedValue = value.replace(/\D/g, ''); // Remove all non-numeric characters
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    [name]: formattedValue
                }
            }));
        }
        else {
            this.setState(prevState => ({
                PaymentProfileMdl: {
                    ...prevState.PaymentProfileMdl,
                    [name]: value
                }
            }));
        }
    };

    togglePaymentForms(paymentTypeId) {
        if (paymentTypeId === '2') {
            document.getElementById('cc-form').classList.add('d-none');
            document.getElementById('ach-form').classList.remove('d-none');
        } else {
            document.getElementById('cc-form').classList.remove('d-none');
            document.getElementById('ach-form').classList.add('d-none');
        }
    }
    // this is for update payment method
    _UpdatePaymentMethod = async () => {
        this.setState({ UpdatePaymentMethodLoader: true });
        const paymentProfileValue = this.state.PaymentProfileMdl;
        const paymentProfile = {
            CardExpMonth: paymentProfileValue.creditCardExpiryMonth,
            CardExpYear: paymentProfileValue.creditCardExpiryYear,
            CardNickName: paymentProfileValue.creditCardHolderName,
            CardNumber: paymentProfileValue.creditCardNumber,
            CvvNumber: paymentProfileValue.creditCardCVV,
            BankAccountNumber: paymentProfileValue.achAccountNumber,
            BankRoutingNumber: paymentProfileValue.achRoutingNumber,
            BankAccountNickName: paymentProfileValue.achAccountHolderName,
            PaymentAccountType: paymentProfileValue.paymentTypeId == 2 ? "ACH" : "CARD",
            InsuredId: this.state.InsuredId,
            InsuredName: this.state.InsuredName,
            IsAutoPay: this.state.isAutoPay,
            aisAccountNumber: this.state.aisAccountNumber,
        };
        await UpdatePaymentMethod(paymentProfile).then(data => {
            let response = data.data;
            document.getElementById("btnclosePaymentMethodMdl")?.click();
            if (response.status == "true") {
                showSuccess("AutoPay Updated Successfully ");
                this._GetAllRetrivePaymentAccount(this.state.currentPolicy);
            } else {
                showError(response.message);
            }
            this.setState({ UpdatePaymentMethodLoader: false });
        });
        this.setState({
            PaymentProfileMdl: {
                creditCardExpiry: '',
                creditCardExpiryMonth: '',
                creditCardExpiryYear: '',
                creditCardHolderName: '',
                creditCardNumber: '',
                creditCardCVV: '',
                achAccountNumber: '',
                achRoutingNumber: '',
                achAccountHolderName: '',
                paymentTypeId: 1 
            }
        });
        const modalElement = document.getElementById('modalChangePaymentMethod');
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal?.hide();
    }
    // this is for change addredd
    _ChangeAddress = async () => {
        let status = this.ChangeAddressValidation(this.state.UserProfileMdl);
        if (status) {
            this.setState({ ChangeAddressLoaderStatus: true });
            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: this.refs.address.value })));
            try {
                const response = await ChangeAddress(this.state.UserProfileMdl);
                if (response.status) {
                    document.getElementById('btncloseAddressMdl').click();
                    showSuccess(response.message);
                } else {
                    showError(response.message);
                }
            } catch (error) {
                showError("An error occurred while updating the address.");
            } finally {
                this.setState({ ChangeAddressLoaderStatus: false });
            }
        }
    };
    //validtion for address validtion 
    ChangeAddressValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.address === null || mdl.address === undefined || mdl.address.trim() === "") {
            errors['IsEmpty_Address'] = true;
            errors['AddressMsg'] = "Required fields : Address";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //for change passwoed
    _ChangePassword = () => {
        let ChangePasswordMdl = {
            currentPassword: this.refs.currentPassword.value,
            newPassword: this.refs.newPassword.value,
            confirmPassword: this.refs.confirmPassword.value,
        }
        let status = this.ChangePasswordValidation(ChangePasswordMdl);
        if (status) {
            this.setState({ ChangePassLoaderStatus: true }); // show loader befour  change pass response form server
            ChangePassword(ChangePasswordMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseMdl').click();
                        showSuccess(response.message);
                        //document.getElementById("frmchangepassword").reset();
                        $("#frmchangepassword").trigger("reset");
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangePassLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    // validtion for change password validation
    ChangePasswordValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.currentPassword === null || mdl.currentPassword.trim() === "" || mdl.currentPassword === undefined) {
            errors['IsEmpty_currentPassword'] = true;
            errors['currentPasswordMsg'] = "Required fields : Current password";
            count++;
        }
        if (mdl.newPassword === null || mdl.newPassword.trim() === "" || mdl.newPassword === undefined) {
            errors['IsEmpty_newPassword'] = true;
            errors['newPasswordMsg'] = "Required fields : New password";
            count++;
        }
        else {
            if (!validator.isLength(mdl.newPassword, 8)) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (mdl.confirmPassword === null || mdl.confirmPassword === undefined || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required fields : Confirm password";
            count++;
        }
        else {
            if (!validator.equals(mdl.newPassword, mdl.confirmPassword)) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
            else if (mdl.newPassword === mdl.currentPassword) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "New password must be different from the current password";
                count++;
            }
        }
        if (!mdl.confirmPassword || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required field: Confirm password";
            count++;
        } else {
            // Check if confirm password matches new password
            if (mdl.newPassword !== mdl.confirmPassword) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords do not match: Confirm password";
                count++;
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //check number only
    checkNumber = (event) => {
        let errors = {};
        let value = event.target.value;
        let fld = event.target.name;
        if (!validator.isNumeric(value)) {
            errors['IsEmpty_' + fld] = true;
            errors[fld + 'Msg'] = "Not a valid number";
        }
        this.setState(Object.assign(this.state.Errors, errors));
    }
    //for change payment method
    //handleChangePaymentMethod = (event) => {
    //    if (this.state.CustomerDetailMdl.usBankDetails != null && this.state.CustomerDetailMdl.cardDetails != null) {
    //        let PaymentType = parseInt(event.target.value);
    //        this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: PaymentType }));
    //    }
    //}
    //for change payment info
    handleChangePaymentInfo = (event) => {
        let ccForm = document.getElementById("cc-form")
        let achForm = document.getElementById("ach-form")
        if (ccForm.classList.contains("d-none")) {
            ccForm.classList.remove("d-none")
            achForm.classList.add("d-none")
        }
        else {
            ccForm.classList.add("d-none")
            achForm.classList.remove("d-none")
        }
    }
    
    IsOutAutoPay = (event) => {
        let element = document.getElementById("autopay-table");
        if (event.target.checked) {
            this.setState({ isOutAutoPay: true });
            element.classList.remove("disabled");
            UpdateAutoPayStatus(true, this.state.aisAccountNumber);
        }
        else {
            this.setState({ isOutAutoPay: false });
            element.classList.add("disabled");
            UpdateAutoPayStatus(false, this.state.aisAccountNumber);
        }
    }
    render() {
        return (
            <>
                <div className="main-wrapper-newDesign settings-page-bg w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <h1 className="h3 mb-3 fw-normal"><strong>Account Settings</strong></h1>
                        <div className="row">
                            <div className="overview-card settings-page-card">
                                <div className="settings-page-items">
                                    <p className="fw-bold">Security</p>
                                    <hr/>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                                <p>Password</p>
                                                <p className="label-overview-newDesign">**********</p>
                                            </div>
                                            <a href="#modalChangePassword" data-bs-toggle="modal">
                                                <button type="button" className="btn-newDesign settings-button">Change Password</button>
                                            </a>
                                        </div>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                                <p>Address</p>
                                            <p className="label-overview-newDesign">{this.state.UserProfileMdl.address + ", " + this.state.UserProfileMdl.city + ", " + this.state.UserProfileMdl.state + " " + this.state.UserProfileMdl.zip}</p>
                                            </div>
                                            <a href="#modalChangeAddress" data-bs-toggle="modal">
                                                <button type="button" className="btn-newDesign settings-button">Change Address</button>
                                            </a>
                                        </div>
                                </div>
                            </div>
                            <div className="overview-card settings-page-card">
                                <div className="settings-page-items">
                                    <p className="fw-bold">Payment</p>
                                    <hr/>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                            <p>Payment Method</p>
                                            <p className="label-overview-newDesign">{this.state.CustomerDetailMdl.cardDetails?.last4 || this.state.CustomerDetailMdl.usBankDetails?.last4 ? `${this.state.CustomerDetailMdl.cardDetails?.last4 ? "Card" : "ACH"} Payment ending in ${this.state.CustomerDetailMdl.cardDetails?.last4 || this.state.CustomerDetailMdl.usBankDetails?.last4}` : "Pending..."}</p>
                                            </div>
                                            <a href="#modalChangePaymentMethod" data-bs-toggle="modal">
                                                <button type="button" className="btn-newDesign settings-button">Update Payment</button>
                                            </a>
                                        </div>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                                <p>AutoPay</p>
                                            <p className="label-overview-newDesign">{this.state.UserProfileMdl.fullName}</p>
                                            </div>
                                            <a href="#modalChangeAccountInfo" data-bs-toggle="modal"> 
                                                <button type="button" className="btn-newDesign settings-button">Update Autopay</button>
                                            </a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-lg-3  col-md-3 col-sm-6 col-12">*/}
                        {/*        <div className="mysetting-btn">*/}
                        {/*            <NavLink to="#modalChangePassword" className="change-pass-btn" data-bs-toggle="modal">*/}
                        {/*                <div className="icon-svg"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-1.293l7.06-7.06c-.214-.26-.413-.533-.599-.815l-6.461 6.461v-2.293l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z" /></svg>*/}
                        {/*                </div>*/}
                        {/*                <div className="btn-content">Change password</div>*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-3  col-md-3 col-sm-6  col-12">*/}
                        {/*        <div className="mysetting-btn">*/}
                        {/*            <NavLink to="#modalChangePaymentMethod" className="change-payment-btn" data-bs-toggle="modal" >*/}
                        {/*                <div className="icon-svg">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M22 9.761c0 .536-.065 1.084-.169 1.627-.847 4.419-3.746 5.946-7.449 5.946h-.572c-.453 0-.838.334-.908.789l-.803 5.09c-.071.453-.456.787-.908.787h-2.736c-.39 0-.688-.348-.628-.732l1.386-8.88.062-.056h2.155c5.235 0 8.509-2.618 9.473-7.568.812.814 1.097 1.876 1.097 2.997zm-14.216 4.252c.116-.826.459-1.177 1.385-1.179l2.26-.002c4.574 0 7.198-2.09 8.023-6.39.8-4.134-2.102-6.442-6.031-6.442h-7.344c-.517 0-.958.382-1.038.901-2.304 14.835-2.97 18.607-3.038 19.758-.021.362.269.672.635.672h3.989l1.159-7.318z" /></svg>*/}
                        {/*                </div>*/}
                        {/*                <div className="btn-content">Change payment method</div>*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-3  col-md-3 col-sm-6 col-12">*/}
                        {/*        <div className="mysetting-btn">*/}
                        {/*            <NavLink to="#modalChangeAccountInfo" className="autopay-button" data-bs-toggle="modal" onClick={() => this._GetPaymentProfile()}>*/}
                        {/*                <div className="icon-svg">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" /></svg>*/}
                        {/*                </div>*/}
                        {/*                <div className="btn-content">Update autopay account information</div></NavLink>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-3 col-md-3 col-sm-6 col-12">*/}
                        {/*        <div className="mysetting-btn">*/}
                        {/*            <NavLink to="#modalChangeAddress" className="change-address-btn" data-bs-toggle="modal" onClick={() => this._GetUserProfile()}>*/}
                        {/*                <div className="icon-svg">*/}
                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" /></svg>*/}
                        {/*                </div>*/}
                        {/*                <div className="btn-content">Change address</div>*/}
                        {/*            </NavLink>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
                { /*model for change password*/}
                <div className="modal fade" tabIndex="-1" id="modalChangePassword" aria-labelledby="chagePassword" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header justify-content-start pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/key-glow.png"></img>
                                                <h5 className="modal-title text-center">Change Password</h5>
                                            </div>
                                            <div className="modal-body-newDesign">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                    {this.state.ChangePassLoaderStatus && <LoaderContents />}
                                                </div>
                                                <form className="w-75 m-auto" id="frmchangepassword" onKeyDown={this._ChangePassword_EnterButton}>
                                                    <p className="modal-header-newDesign">Current Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_currentPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="currentPassword" id="currentPassword" aria-describedby="currentPassword" placeholder="Enter your current password *" required />
                                                        {this.state.Errors['IsEmpty_currentPassword'] === true ? <div className="has-err-msg">{this.state.Errors['currentPasswordMsg']}</div> : ''}
                                                        <label htmlFor="currentPassword">Enter your current password</label>
                                                    </div>
                                                    <p>New Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_newPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="newPassword" id="newPassword" aria-describedby="newPassword" placeholder="Enter your new password *" required />
                                                        {this.state.Errors['IsEmpty_newPassword'] === true ? <div className="has-err-msg">{this.state.Errors['newPasswordMsg']}</div> : ''}
                                                        <label htmlFor="newPassword">Enter your new password</label>
                                                    </div>
                                                    <p>Confirm Password</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="password" className={(this.state.Errors['IsEmpty_confirmPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="confirmPassword" id="confirmPassword" name="confirmPassword" placeholder="Confirm password *" required />
                                                        {this.state.Errors['IsEmpty_confirmPassword'] === true ? <div className="has-err-msg">{this.state.Errors['confirmPasswordMsg']}</div> : ''}
                                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                                    </div>
                                                    <div className="text-center d-flex justify-content-center">
                                                        <button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ChangePassword()}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                { /*model for change address*/}
                <div className="modal fade" tabIndex="-1" id="modalChangeAddress" aria-labelledby="changeAddress" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center border-0">
                                <img className="modal-image" src="./assets/Content/images/map-pin-glow.png"></img>
                                <h5 className="modal-title">Change Address</h5>
                            </div>
                            <div className="modal-body">
                                <div>
                                    { /*loader show befoure load contents*/}
                                    {this.state.ChangeAddressLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="w-75 m-auto" id="frmchangeaddress" onKeyDown={this._ChangeAddress_EnterButton}>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    */}{/*<input type="text" className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')} ref="Address" id="autocomplete_address" aria-describedby="Address" placeholder="Enter your address *" name="address" defaultValue={this.state.UserProfileMdl.address ?? ""} required />*/}
                                    {/*    <GetPlace*/}
                                    {/*        className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')}*/}
                                    {/*        id="address"*/}
                                    {/*        placeholder="Enter your address *"*/}
                                    {/*        name="address"*/}
                                    {/*        inputAutocompleteValue="off"*/}
                                    {/*        defaultValue={this.state.UserProfileMdl.address ?? ""}*/}
                                    {/*        onPlaceSelected={(place) => {*/}
                                    {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                    {/*        }}*/}
                                    {/*        onChange={(place) => {*/}
                                    {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                    {/*        }}*/}
                                    {/*        required*/}
                                    {/*    />*/}
                                    {/*    {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}*/}
                                    {/*    <label htmlFor="address">Enter your Address</label>*/}
                                    {/*</div>*/}
                                    <p>Enter your address</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className={(this.state.Errors['IsEmpty_Address'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} defaultValue={this.state.UserProfileMdl.address || ""} ref="address" id="address" aria-describedby="address" placeholder="Enter your Address *" required />
                                        {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}
                                        <label htmlFor="address">Enter your Address</label>
                                    </div>

                                    <div className="text-center d-flex justify-content-center">
                                        <button type="button" id="btncloseAddressMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ChangeAddress()}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for change payment method*/}
                <div className="modal fade" tabIndex="-1" id="modalChangePaymentMethod" aria-labelledby="modalChangePaymentMethod" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content payment-method-modal">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <img className="modal-image" src="./assets/Content/images/cc-glow.png"></img>
                                <h5 className="modal-title text-center">Update Payment Method</h5>
                            </div>
                            <div className="modal-body">
                                { /*loader show befoure load contents*/}
                                    {this.state.UpdatePaymentMethodLoader ? (
                                        <LoaderContents />
                                    ) : (
                                    <form className="m-auto" id="frmchangePaymentMethod" onKeyDown={this._UpsertPaymentProfile_EnterButton}>
                                        <div className="mx-auto mb-3">
                                            <div className='input-wrapper-radio row' onChange={this.handleChangePaymentMethod}>
                                                <div className="card-header bg-white border-white p-2">
                                                    <p>Payment Method</p>
                                                    <div className="custom-select-wrapper">
                                                        <div className="custom-select-container">
                                                            <img id="payment-method-dd" className="nav-img" src="./assets/Content/images/cc-small.png" alt="Payment Method"/>
                                                            <select className="form-select input-newDesign"
                                                                value={this.state.PaymentProfileMdl.paymentTypeId} onChange={this.handleChangePaymentInfo}>
                                                                <option value="1">Credit Card</option>
                                                                <option value="2" >ACH</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="cc-form" className="credit-card-form">
                                                <div className="payment-method-cc-forms">
                                                    <div className="col-left">
                                                        <p className="modal-header-newDesign">Name on Card</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="creditCardHolderName" value={this.state.PaymentProfileMdl.creditCardHolderName} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Full Name" />
                                                            <label>Full Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-right">
                                                        <p className="modal-header-newDesign">Expiration</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="creditCardExpiry" className="input-newDesign form-control" placeholder="MM/YY" value={this.state.PaymentProfileMdl.creditCardExpiry} onChange={this.handleInputChange} />
                                                            <label>MM/YY</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="payment-method-cc-forms">
                                                    <div className="col-left">
                                                        <p className="modal-header-newDesign">Card Number</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="creditCardNumber" value={this.state.PaymentProfileMdl.creditCardNumber} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Card Number" />
                                                            <label>Card Number</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-right">
                                                        <p className="modal-header-newDesign">CVV</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="creditCardCVV" value={this.state.PaymentProfileMdl.creditCardCVV} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="CVV" />
                                                            <label>CVV</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="ach-form" className="credit-card-form d-none">
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="modal-header-newDesign">Name on Account</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="achAccountHolderName" value={this.state.PaymentProfileMdl.achAccountHolderName} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Full Name" />
                                                            <label>Full Name</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <p className="modal-header-newDesign">Bank Name</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="achBankName" value={this.state.PaymentProfileMdl.achBankName} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Legal Name" />
                                                            <label>Legal Name</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="modal-header-newDesign">Routing Number</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="achRoutingNumber" value={this.state.PaymentProfileMdl.achRoutingNumber} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Full Number" />
                                                            <label>Full Number</label>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <p className="modal-header-newDesign">Account Number</p>
                                                        <div className="form-floating input-wrapper-payment mb-3">
                                                            <input name="achAccountNumber" value={this.state.PaymentProfileMdl.achAccountNumber} onChange={this.handleInputChange} className="input-newDesign form-control" placeholder="Full Number" />
                                                            <label>Full Number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center d-flex justify-content-center">
                                                <button type="button" id="btnclosePaymentMethodMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                <button type="button" className="btn btn-newDesign" onClick={this._UpdatePaymentMethod}>Submit</button>
                                            </div>
                                        </div>
                                        {/*<div className="text-center">*/}
                                        {/*    <Button type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => this._UpsertPaymentProfile()}>Submit</Button>*/}
                                        {/*</div>*/}
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for change Auto Pay Account Info*/}
                <div className="modal fade" tabIndex="-1" id="modalChangeAccountInfo" aria-labelledby="modalChangeAccountInfo" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center border-0">
                                <img className="modal-image" src="./assets/Content/images/cc-glow.png"></img>
                                <h5 className="modal-title">Update Autopay</h5>
                            </div>
                            <div className="modal-body-newDesign">
                                <div>
                                    { /*loader show befoure load contents*/}
                                    {/*{this.state.AutoPayLoaderStatus && <LoaderContents />}*/}
                                </div>
                                <form className="w-75 m-auto" id="frmpaymentsinfo" onChange={this.handleChangePaymentInfo} onKeyDown={this._UpsertPaymentInfo_EnterButton}>

                                    {this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.ACH &&
                                        <div>
                                            {(this.state.CustomerDetailMdl?.usBankDetails !== null && this.state.CustomerDetailMdl?.usBankDetails !== "") &&
                                                <div>
                                                    <div className="table-responsive">
                                                        <table id="autopay-table" className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Account Holder Name</th>
                                                                    <th>Routing Number</th>
                                                                    <th>Account Last4</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                        <tr>
                                                            <td>{this.state.CustomerDetailMdl?.customerDetails?.name}</td>
                                                                    <td>{this.state.CustomerDetailMdl?.usBankDetails?.routingNumber}</td>
                                                                    <td>{this.state.CustomerDetailMdl?.usBankDetails?.last4}</td>
                                                                    {/*<td><NavLink to="#modalChangePaymentMethod" data-bs-toggle="modal">Edit</NavLink></td>*/}
                                                                    <td><NavLink to="#confirmdeletemdl" data-bs-toggle="modal">Cancel</NavLink></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                            <div className="col-12 promotional-modal-check w-auto">
                                                                <input type="checkbox" id="radio-Active" name="IsTextActive"  checked={this.state.isOutAutoPay} required className='form-check-input form-control' onChange={this.IsOutAutoPay} />
                                                                <label className="form-check-label mt-2">
                                                                    <h6 className="mb-0 AutoPay-label-text">Auto Pay</h6>
                                                                </label>
                                                            </div>
                                                            <div className="button-container">
                                                                <button type="button" id="btncloseAutoPayMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                            </div>
                                                        </div>
                                                    {/*<p className="pt-3">Note: This will be the account which is used for Term Deduction. </p>*/}
                                                </div>
                                            }
                                            {this.state.CustomerDetailMdl?.usBankDetails === "" &&
                                                <div className='ACH-Section' id='ACH-Section'>
                                                    <ACHForm />
                                                </div>
                                            }

                                        </div>
                                    }
                                    {this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.CreditCard &&
                                        <div>
                                            {(this.state.CustomerDetailMdl.cardDetails !== null && this.state.CustomerDetailMdl.cardDetails !== "") &&
                                                <div>
                                                    <div className="table-responsive">
                                                        <table id="autopay-table" className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Last4</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                            {
                                                                this.state.loading ? (
                                                                    <LoaderContents />
                                                                ) : this.state.CustomerDetailMdl?.cardDetails ? (
                                                                    <>
                                                                        <td>{this.state.CustomerDetailMdl.cardDetails.CardBrand}</td>
                                                                        <td>{this.state.CustomerDetailMdl.cardDetails.last4}</td>
                                                                        {/* Uncomment if needed */}
                                                                        {/* <td><NavLink to="#modalChangePaymentMethod" data-bs-toggle="modal">Edit</NavLink></td> */}
                                                                        <td>
                                                                            <NavLink to="#confirmdeletemdl" data-bs-toggle="modal">Cancel</NavLink>
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan="3">No data found</td>
                                                                    </tr>
                                                                )
                                                            }

                                                                </tr>
                                                             </tbody>
                                                        </table>
                                                    <div className="col-12 promotional-modal-check w-auto">
                                                        <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.isOutAutoPay} required className='form-check-input form-control' onChange={this.IsOutAutoPay} />
                                                        <label className="form-check-label mt-2">
                                                            <h6 className="mb-0 AutoPay-label-text">Auto Pay</h6>
                                                        </label>
                                                    </div>
                                                    <div className="button-container">
                                                        <button type="button" id="btncloseAutoPayMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                    </div>
                                                </div>
                                    {/*<p className="pt-3">Note*: This will be the account which is used for Term Deduction. </p>*/}
                                                </div>
                                            }
                                        {(this.state.CustomerDetailMdl.cardDetails === "" &&
                                            <div className='CreditCard-Section' id='CreditCard-Section'>
                                                <Elements stripe={stripePromise}>
                                                    <CreditCardForm />
                                                </Elements>
                                            </div>)
                                        }
                                         </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for confirm delete payment method */}
                <div className="modal fade" id="confirmdeletemdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Payment Method</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body">
                                Do you want to delete this payment method ? {this.state.DeleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="modal-footer">
                                <Button type="button" id="btncancle" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</Button>
                                <Button type="button" id="btndelete" className="btn btn-primary" onClick={() => this._DeletePaymentMethod(this.state.CustomerDetailMdl.customerDetails.id, this.state.CustomerDetailMdl.customerDetails.token)}>Delete</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default InsuredMySettings;