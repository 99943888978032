import React, { Component } from 'react';
class DocuSignComplete extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                <main>
                    <>
                        <div className="container form-space p-space payment-carddata">
                            <div className="row justify-content-md-center">
                                <div className="col-md-12">
                                    <p>Signing complete. You can close this window.</p>
                                </div>
                            </div>
                        </div>
                    </>

                </main>
            </>
        );
    }
}
export default DocuSignComplete;