import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { CustomCurrencyFormat, DateFormatShort } from "../../../common/Setting";
import { LoaderContents } from "../../layout/LoaderContents";
import { GetInsuredData } from '../../../services/UserServices';
import ReactPaginate from "react-paginate";


class AccountActivity extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1
            },
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
            InsuredData: [],
            SearchData: [],
            Pagecount: 1,
            PaginationData: [],
        }
    }


      componentDidMount() {
          // Example: Fetch data from an API
          const user = JSON.parse(localStorage.getItem("UserCredential"));
          this.setState({ PageLoaderStatus: true });
          if (user != null) {
              this._GetInsuredData(user.CustomerId);
          }
  }

    _GetInsuredData = (userId) => {
        this.setState({ PageLoaderStatus: true });
        GetInsuredData(userId).then(data => {
            let response = data;
            this.setState({ InsuredData: response.data });
            this.setState({ SearchData: response.data });
            this.setState({ Pagecount: Math.ceil((this.state.InsuredData.length / this.state.searchMdl.LimitTo)) });
            if (this.state.InsuredData.length > this.state.searchMdl.LimitTo) { // Pagination
                let pagination = this.createPagination(response.data)
                this.setState({PaginationData: pagination})
                this.setState({ SearchData: pagination[1] });
            }
        });
    }

    handleSearchData = (event) => {
         if (event.target.value === "") {
             if (this.state.InsuredData.length > this.state.searchMdl.LimitTo) { // Pagination
                 let pagination = this.createPagination(this.state.InsuredData);
                this.setState({ SearchData: pagination[1] });
            }
             else {
              this.setState({
                SearchData: this.state.InsuredData });
             }
         }
         else {
            let searchAIS = this.state.InsuredData.filter(activity => 
                activity.methodOfPayment.toLowerCase().includes(event.target.value.toLowerCase()));
            this.setState({ Pagecount: Math.ceil((this.state.InsuredData.length / this.state.searchMdl.LimitTo)) });
            if (searchAIS.length > this.state.searchMdl.LimitTo) { // Pagination
                let pagination = this.createPagination(searchAIS)
                this.setState({PaginationData: pagination})
                this.setState({SearchData: pagination[1]})
            }
            else {
                this.setState({SearchData: searchAIS})
                this.setState({Pagecount: 1})
                this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
            }
         }
    }
    
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this.setState({ Pagecount: Math.ceil((this.state.InsuredData.length / this.state.searchMdl.LimitTo)) });
        if (this.state.InsuredData.length > this.state.searchMdl.LimitTo) { // Pagination
            let pagination = this.createPagination(this.state.InsuredData)
            this.setState({PaginationData: pagination})
            this.setState({SearchData: pagination[1]})
        }
        else {
            this.setState({SearchData: this.state.InsuredData });
            this.setState({Pagecount: 1})
            this.setState(Object.assign(this.state.searchMdl, { PageNumber: 1 }));
        }
    }
    
    createPagination = (arr)=> {
        const limit = parseInt(this.state.searchMdl.LimitTo, 10);
        const hashmap = {};
        let pageNumber = 1;
        for (let i = 0; i < arr.length; i += limit) {
          hashmap[pageNumber] = arr.slice(i, i + limit);
          pageNumber++;
        }
        return hashmap;
    }

    handlePageClick = (data) => {
		let PageNumber = data.selected + 1;
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this.setState({SearchData: this.state.PaginationData[PageNumber]});
	}
    render() {
        const { Pagecount } = this.state;
        return (
            <>
                {this.state.PageLoaderStatus &&
                    <div className="main-wrapper-newDesign account-overview w-100">
                        <InternalLayoutHeader />
                        <div className="content-main p-lg-4 p-3">
                            <h1 className="h3 mb-0 fw-normal"><strong>Account Activity</strong></h1>
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                        <div className="card-body">
                                            <div className="dashboard-options">
                                                <div
                                                    className="card-header bg-white border-white p-2 dashboard-input-size">
                                                    <input type="text" className="form-control text-search-newDesign"
                                                           onChange={this.handleSearchData} id="search"
                                                           placeholder="Search by Description"/>
                                                    {this.state.SaveLoaderStatus &&
                                                        <span
                                                            style={{position: "absolute", right: "222px", top: "27px"}}
                                                            className="fa fa-spinner fa-spin"></span>
                                                    }
                                                </div>

                                                <div className="card-header bg-white border-white p-2 custom-select">
                                                    <select className="form-select text-search-newDesign"
                                                            defaultValue={this.state.searchMdl.LimitTo}
                                                            onChange={this.handleShowChange}>
                                                        <option value={this.state.searchMdl.LimitTo} disabled>Show
                                                        </option>
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="w-100 table-responsive">
                                                <table className="table insured-table mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th className="Details-table-header">Date</th>
                                                        <th className="Details-table-header">Amount</th>
                                                        <th className="Details-table-header">Description</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.SearchData?.map((data, index) => {
                                                            return (
                                                                <tr key={data.userId}>
                                                                    {/*<td className="Details-table-header">{DateFormatShort(new Date().toDateString())}</td>*/}
                                                                    <td className="Details-table-header">{DateFormatShort(new Date(data.datePaid))}</td>
                                                                    <td className="Details-table-header">{CustomCurrencyFormat(data.amountPaid)}</td>
                                                                    <td className="Details-table-header">{data.methodOfPayment}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex paginate-div">
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    pageCount={Pagecount ?? 0}
                                                    marginPagesDisplayed={"4"}
                                                    pageRangeDisplayed={"3"}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={"pagination justify-content-start"}
                                                    pageClassName={"page-item page-item-none"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item page-item-none"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                    disabledLinkClassName={"disabled"}
                                                    forcePage={this.state.searchMdl.PageNumber - 1}/>
                                                <div className="pagination-page">
                                                    Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SiteFooter/>
                    </div>
                }
            </>
        );
    }
}

export default AccountActivity;