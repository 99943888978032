import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
class Dashboard extends Component {
	//this for agent user
	static displayName = Dashboard.name;
	constructor(props) {
		super(props);
		this.state = {
			
		}
	}
	render() {
		return (
			<div className="main-wrapper-newDesign w-100">
				<InternalLayoutHeader />{/*internal header user wise */}
				<div className="content-main p-lg-4 p-3">
					<h1 className="h3 mb-3 fw-normal"><strong>Super Admin Dashboard</strong></h1>
					<div className="row">
						<div className="col-xl-12 col-xxl-12 d-flex">
							<div className="w-100 dashboard-portfolio">
								
							</div>
						</div>
					</div>
				</div>
				<SiteFooter />  {/*this call for site footer */}
			</div>
		);
	}
}
export default Dashboard;