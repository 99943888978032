import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { NavLink } from 'react-router-dom';
import { authHeader, subDomain } from '../../../common/Common';
import { FlowGroups, Reportsfilters } from '../../../common/Enum';
class Compliance extends Component {
    render() {
        let url = (subDomain() === null || subDomain().SubDomain === 'Xgen') ? "" : "/" + subDomain().SubDomain;
        return (
            <div className="main-wrapper-newDesign w-100">
                <InternalLayoutHeader />	 {/*internal header user wise */}
                <div className="content-main p-lg-4 p-3">
                    <h1 className="h3 mb-3 fw-normal"><strong>Compliance</strong></h1>
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                <div className="card-body">
                                    <div>
                                        
                                    </div>
                                    <div className="w-100 table-responsive">
                                        <table className="table insured-table mb-0">
                                            <thead className="tbl-head">
                                                <tr>
                                                    <th>State</th>
                                                    <th>Cancellation Date</th>
                                                    <th>Notes</th>
                                                    <th style={{ width: "22%" }}>Link</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbl-body">
                                                <tr>
                                                    <th>AL</th>
                                                    <th>10 days</th>
                                                    <th>If delivered by US mail, notification starts 5 days following postmarked date</th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr> <tr>
                                                    <th>AK</th>
                                                    <th>20 days</th>
                                                    <th>If under binder for owner occupied residential real estate, the lender and named insured must receive 15 days' written notice</th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr> <tr>
                                                    <th>AZ</th>
                                                    <th>10 days</th>
                                                    <th>Mortgagee receives 20 days' notice</th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr> <tr>
                                                    <th>AR</th>
                                                    <th>10 days</th>
                                                    <th>If there is no named creditor loss payee. If there is a named creditor loss payee, 30 days.</th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr> <tr>
                                                    <th>CO</th>
                                                    <th>10 days</th>
                                                    <th>......</th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>CT</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>DE</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>DC</th>
                                                    <th>30 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>FL</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>GA</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>HI</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>HI</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>ID</th>
                                                    <th>10 days</th>
                                                    <th></th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>IL</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>IN</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>IA</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>KS</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>KY</th>
                                                    <th>14 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>LA</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>ME</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MD</th>
                                                    <th>10 days</th>
                                                    <th></th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MI</th>
                                                    <th>10 days</th>
                                                    <th></th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MN</th>
                                                    <th>20 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MS</th>
                                                    <th>10 days</th>
                                                    <th></th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MO</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>MT</th>
                                                    <th>20 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NE</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NH</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NJ</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NM</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NY</th>
                                                    <th>15 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>NC</th>
                                                    <th>15 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>ND</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>OH</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>OK</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>OR</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>PA</th>
                                                    <th>30 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>RI</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>SC</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>SD</th>
                                                    <th>20 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>TN</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>TX</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>UT</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>VT</th>
                                                    <th>15days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>VA</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>WA</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>WV</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>WI</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr><tr>
                                                    <th>WY</th>
                                                    <th>10 days</th>
                                                    <th> </th>
                                                    <th className="table-icons"><NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PASTDUE_0091600001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_BILL_PMTDUE_0202500001.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_CANCELLATION_NOTICE 11-26-19.pdf"><i className="fa-light fa-file"></i></NavLink>
                                                        <NavLink target="_blank" to="/assets/Content/ComplainceReports/MGA_REINSTATEMENT_NOTICE 12-19-19.pdf"><i className="fa-light fa-file"></i></NavLink></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SiteFooter />  {/*this call for site footer */}
            </div>
        );
    }
}
export default Compliance;