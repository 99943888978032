import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { FlowGroups } from "../../../common/Enum";
import { showError, showSuccess, authHeader, subDomain, Button } from '../../../common/Common';
import { DateFormat } from '../../../common/Setting';
import { LoaderContents } from "../../layout/LoaderContents";
import { GetAllAgentUser, GetAllUserByRoleId } from '../../../services/UserServices';
import { SetCommission, GetCommissionList, GetCommissionById, DeleteCommissionById, UpdateCommission } from '../../../services/PaymentServices';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import validator from 'validator';
import $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Error, CustomCurrencyFormat } from '../../../common/Setting';
import CurrencyFormat from 'react-currency-format';

class ManageCommission extends Component {
	//this for agent user
	constructor(props) {
		super(props);
		this.state = {
			Errors: {},
			AllUserData: [],
			AllUserCommissionData: [],
			reponseMessage: '',
			SaveLoaderStatus: false,
			IsAgentUser: true,
			IsGAUser: false,
			selectedUsers: null,
			searchMdl: {
				GeneralSearch: null,
				LimitTo: 10,
				PageNumber: 1,
				ContentsLoaderStatus: false
			},
			Pagecount: 0,
			ModelTitle: null,
			UserProfileMdl: {
				id: null,
				email: null,
				confirmEmail: null,
				flowGroupId: 0,
				userRoleIds: [],
				userInfoMdl: {
					fullName: null
				}
			},
			UserDetails: authHeader(),
			SubDomain: subDomain(),
			CommissionId: 0,
			deleteLoader: false,
			userCommissionEditMdl: {
				id: 0,
				fullName: null,
				userId: null,
				singleUserId: null,
				commissionPercent: null,
				program: null,
				type: null,
				from: null,
				to: null,
				totalCommissionPaid: null,
				flatCommission: null
			},
			commissionFrom: null,
			commissionTo: null,
			disAblePercentCommission: false,
			disAbleFlatCommission: false,

		}
		this.handleChange = this.handleChange.bind(this)
	}
	componentDidMount() {
		this.setState({ PageLoaderStatus: true });
		this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);
	};
	_SetCommission = () => {
		if (this.state.CommissionId !== 0) {
			this._UpdateCommission();
		} else {
			this._InsertCommission();
		}
	}

	_InsertCommission = () => {
		let SelectUsersId = [];

		if (this.state.selectedUsers != null && this.state.selectedUsers.length > 0) {
			for (let i = 0; i < this.state.selectedUsers.length; i++) {
				SelectUsersId.push(this.state.selectedUsers[i].value);
			}
		}
		let userCommissionMdl = {
			UserId: SelectUsersId,
			CommissionPercent: this.refs.CommissionPercent.value,
			Program: this.refs.Program.value,
			Type: this.refs.Type.value,
			From: this.state.commissionFrom,
			To: this.state.commissionTo,
			FlatCommission: this.refs.FlatCommission.value
		}
		let status = this.ValidtionForSetCommssion(userCommissionMdl);
		if (status) {
			this.setState({ SaveLoaderStatus: true }); // show loader befour request
			SetCommission(userCommissionMdl).then(res => {
				let response = res;
				if (response.message !== Error) {
					if (response.status === true) {
						showSuccess(response.message);
						this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);
						window.$('#modalSetUserCommission').modal('hide');
					} else {
						this.setState({ SaveLoaderStatus: false }); // hide loader afte r request
						showError(response.message);
					}

					this.setState({ SaveLoaderStatus: false }); // hide loader after request
				}
			});
		}
	}

	ValidtionForSetCommssion = (mdl) => {
		let errors = {};
		let count = 0;

		if (this.state.CommissionId === 0) {
			if (mdl.UserId === null || mdl.UserId.length === 0) {
				errors['IsEmpty_UserId'] = true;
				errors['UserIdEmptyMsg'] = "Please Select the required fields : Users";
				count++;
			}
		}

		if (mdl.CommissionPercent === null || mdl.CommissionPercent === undefined || mdl.CommissionPercent.trim() === "") {
			errors['IsEmpty_CommissionPercent'] = true;
			errors['CommissionPercentEmptyMsg'] = "Please enter the required fields : Commission Percent";
			count++;
		}
		else {
			if (!validator.isNumeric(mdl.CommissionPercent)) {
				errors['IsEmpty_CommissionPercent'] = true;
				errors['CommissionPercentEmptyMsg'] = "Not a valid number Commission Percent";
				count++;
			}
		}

		if (mdl.Program === null || mdl.Program === undefined || mdl.Program.trim() === "") {
			errors['IsEmpty_Program'] = true;
			errors['ProgramEmptyMsg'] = "Please enter the required fields : Program";
			count++;
		}

		if (mdl.Type === null || mdl.Type === undefined || mdl.Type.trim() === "") {
			errors['IsEmpty_Type'] = true;
			errors['TypeEmptyMsg'] = "Please enter the required fields : Type";
			count++;
		}

		if (mdl.From === null || mdl.From === undefined) {
			errors['IsEmpty_commissionFrom'] = true;
			errors['commissionFromEmptyMsg'] = "Please enter the required fields : From";
			count++;
		}

		if (mdl.To === null || mdl.To === undefined) {
			errors['IsEmpty_commissionTo'] = true;
			errors['commissionToEmptyMsg'] = "Please enter the required fields : To";
			count++;
		}

		this.setState({ Errors: errors });
		if (count === 0) {
			return true;
		}
	}
	_GetCommissionList = (searchMdl, SubDomainId) => {
		this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
		GetCommissionList(searchMdl, SubDomainId).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					// let test = response.data.data;
					// for (let i = 0; i < 10; i++) {
					// 						response.data.data.push(test);
					// }
					this.setState({ AllUserCommissionData: response.data.data });
					this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
				} else {
					showError(response.message);
				}
				this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
				this.setState({ PageLoaderStatus: false }); // show loader befour load data
			}
		});
	}
	//start funstions for pagination
	handlePageClick = (data) => {
		let PageNumber = data.selected + 1;
		this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
		this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);

	}
	handleShowChange = (event) => {
		this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
		this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);

	}
	handleSearchData = (event) => {
		this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
		this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);
	}
	//end pagination function
	handleChange = (selectedUsers) => {
		this.setState({ selectedUsers });
	};

	//_GetAllAgentUser = () => {
	//    this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
	//    let userList = [];
	//    GetAllAgentUser().then(res => {
	//        let response = res;
	//        if (response.message !== Error) {
	//            if (response.status === true) {
	//                for (let i = 0; i < response.data.length; i++) {
	//                    userList.push({ 'value': response.data[i].id, 'label': response.data[i].userInfoMdl.fullName });
	//                }
	//                this.setState({ AllUserData: userList });

	//            } else {
	//                showError(response.message);
	//            }
	//            this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
	//        }
	//    });
	//}
	_GetAllUserByRoleId = (roleId, subDomainId) => {
		this.resetCommissionMdl();
		this.setState({ SaveLoaderStatus: true }); // show loader before load data
		let userList = [];
		GetAllUserByRoleId(roleId, subDomainId).then(res => {
			let response = res;
			if (response.message !== Error) {
				if (response.status === true) {
					for (let i = 0; i < response.data.length; i++) {
						userList.push({ 'value': response.data[i].id, 'label': response.data[i].userInfoMdl.fullName });
					}
					this.setState({ AllUserData: userList });
				} else {
					showError(response.message);
				}
				this.setState({ SaveLoaderStatus: false }); // hide loader after load data
			}
		});
	}

	_DeleteCommissionById = () => {
		this.setState({ deleteLoader: true });
		DeleteCommissionById(this.state.CommissionId).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					showSuccess(response.message);
				}
				else {
					showError(response.message);
				}
				this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);
				this.setState({ CommissionId: 0, deleteLoader: false })
				window.$('#confirmdelete').modal('hide');

			}
		});
	}

	resetCommissionMdl = () => {
		this.setState({
			CommissionId: 0,
			userCommissionEditMdl: {
				fullName: null,
				singleUserId: null,
				CommissionPercent: null,
				Program: null,
				Type: null,
			},
			commissionFrom: null,
			commissionTo: null,
			Errors: {},
			AllUserData: [],
			selectedUsers: null,
			disAblePercentCommission: false,
			disAbleFlatCommission: false,
		});
		$("#frmaddnewuser").trigger("reset");
		window.$("#modalSetUserCommission").modal('show');
	}

	editCommission = (CommissionId) => {
		this.resetCommissionMdl();
		this.setState({ SaveLoaderStatus: true, CommissionId: CommissionId }); // show loader before  data edit
		GetCommissionById(CommissionId).then(data => {
			let response = data;
			if (response.message !== Error) {
				if (response.status === true) {
					//formating date for date picker using slice
					this.setState({ userCommissionEditMdl: response.data, commissionFrom: response.data.from });
					if (response.data.to) {
						this.setState({ commissionTo: response.data.to });
					}
					window.$("#modalSetUserCommission").modal('show');
				}
				else {
					showError(response.message);
				}
				this.setState({ SaveLoaderStatus: false }); // hide loader after  data edit
			}
		});
	}

	_UpdateCommission = () => {
		let userCommissionMdl = {
			Id: this.state.CommissionId,
			CommissionPercent: this.refs.CommissionPercent.value,
			Program: this.refs.Program.value,
			Type: this.refs.Type.value,
			From: this.state.commissionFrom,
			To: this.state.commissionTo,
			FlatCommission: this.refs.FlatCommission.value
		}
		let status = this.ValidtionForSetCommssion(userCommissionMdl);
		if (status) {
			this.setState({ SaveLoaderStatus: true });
			UpdateCommission(userCommissionMdl).then(data => {
				let response = data;
				if (response.message !== Error) {
					if (response.status === true) {
						window.$("#modalSetUserCommission").modal('hide');
						this.resetCommissionMdl();
						showSuccess(response.message);
					}
					else {
						showError(response.message);
					}
					this._GetCommissionList(this.state.searchMdl, this.state.SubDomain.SubDomainId);
					this.setState({ SaveLoaderStatus: false });
					window.$('#modalSetUserCommission').modal('hide');
				}
			});
		}
	}

	_HandleTextChange = (event) => {
		if (event.target.id === 'CommissionPercent') {
			if (event.target.value == '') {
				this.setState({ disAbleFlatCommission: false });
				this.setState({ disAblePercentCommission: false });
			}
			else {
				$("#FlatCommission").val(0);
				this.setState({ disAbleFlatCommission: true });
			}
		}
		if (event.target.id === 'FlatCommission') {
			if (event.target.value == '') {
				this.setState({ disAbleFlatCommission: false });
				this.setState({ disAblePercentCommission: false });
			}
			else {
				$("#CommissionPercent").val(0);
				this.setState({ disAblePercentCommission: true });
			}
		}
	}

	render() {
		const { AllUserData, selectedUsers, AllUserCommissionData, RoleId } = this.state;
		//let data = AllUserData.slice(this.state.searchMdl.PageNumber * this.state.searchMdl.LimitTo, (this.state.searchMdl.PageNumber + 1) * this.state.searchMdl.LimitTo);
		return (
			<>
				<div className="main-wrapper-newDesign w-100">
					<InternalLayoutHeader />
					<div className="content-main p-lg-4 p-3">
						<div className="row align-items-center">
							<div className="col-xl-8 col-xxl-8 col-lg-8 col-sm-5 col-12">
								<h1 className="h3 mb-0 fw-normal"><strong>Manage Commission</strong></h1>
							</div>
							<div className="col-xl-4 col-xxl-4 col-lg-4 col-sm-7 col-12 text-end">
								{(this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.SuperAdmin) &&
									<button type="button" id="setUserCommission" data-bs-toggle="modal" data-bs-target="#modalSetUserCommission" onClick={() => this._GetAllUserByRoleId(3, this.state.SubDomain.SubDomainId)} className="btn-newDesign btn-header center-aligned">
										<span className="label">Set Commission (%)</span>
									</button>
								}
							</div>
						</div>
						<div className="row">
							<div className="col-xl-12 col-xxl-12">
								<div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                    <div className="card-body low-rows-fix">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Agent Name" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>

                                            <div className="card-header bg-white border-white p-2">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
														<thead>
															<tr>
																<th>No</th>
																<th>Name</th>
																<th>Email</th>
																<th>Phone</th>
																<th className="text-center">Commisssion</th>
																<th className="text-center">Flat Commisssion</th>
																<th>Program</th>
																<th>Type</th>
																<th>From</th>
																<th>To</th>
																<th>Total Commission Paid</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{AllUserCommissionData.map((items, index) =>
																<tr key={index}>
																	<td>{index + 1}</td>
																	<td>{items.fullName ?? ''}</td>
																	<td>{items.email ?? ''}</td>
																	<td>{items.phoneNumber ?? ''}</td>
																	<td className="text-center">{items.commissionPercent ?? ''} %</td>
																	<td className="text-center">{CustomCurrencyFormat(items.flatCommission ?? 0)} </td>
																	<td>{items.program ?? ''}</td>
																	<td>{items.type ?? ''}</td>
																	<td>{DateFormat(items.from) ?? ''}</td>
																	<td>{DateFormat(items.to) ?? '-'}</td>
																	<td>{CustomCurrencyFormat(items.totalCommissionPaid ?? 0)}</td>
																	<td>
																		<div className="button-container">
																			<button type="button" id={items.id} className="btn btn-newDesign" onClick={() => this.editCommission(items.id)}><span>Edit</span></button>
																			<button type="button" id={items.id} className="btn btn-newDesign-foil" data-bs-toggle="modal" data-bs-target="#confirmdelete" onClick={() => this.setState({ CommissionId: items.id })}><span> Delete</span></button>
																		</div>
																	</td>
																	
																</tr>
															)}
														</tbody>
													</table>
											<p className="text-center">
												{(this.state.Pagecount === 0 && this.state.PageLoaderStatus === false) && "No Record Found"}
											</p>
											<div className="pt20px">
												{ /*loader show befoure load contents*/}
												{this.state.PageLoaderStatus && <LoaderContents />}
											</div>
										</div>
										<div className="d-flex paginate-div">
											<ReactPaginate
											previousLabel={"Previous"}
											nextLabel={"Next"}
											breakLabel={"..."}
											pageCount={this.state.Pagecount ?? 0}
											marginPagesDisplayed={"4"}
											pageRangeDisplayed={"3"}
											onPageChange={this.handlePageClick}
											containerClassName={"pagination justify-content-start"}
											pageClassName={"page-item page-item-none"}
											pageLinkClassName={"page-link"}
											previousClassName={"page-item"}
											previousLinkClassName={"page-link"}
											nextClassName={"page-item"}
											nextLinkClassName={"page-link"}
											breakClassName={"page-item page-item-none"}
											breakLinkClassName={"page-link"}
											activeClassName={"active"}
											disabledLinkClassName={"disabled"} />
											<div className="pagination-page">
												Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<SiteFooter /> {/*this call for site footer */}
				</div>
				{ /*model for create/update commission */}
				<div className="modal fade" tabIndex="-1" id="modalSetUserCommission" aria-labelledby="setUserCommission" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header justify-content-center pt-4 border-0">
								<h5 className="modal-title text-center">Set Commission</h5>
							</div>
							<div className="modal-body">
								<div className="pt20px">
									{ /*loader show befoure load contents*/}
									{this.state.SaveLoaderStatus && <LoaderContents />}
								</div>

								<form className="position-relative step-register w-75 m-auto" id="frmaddnewuser">
									<fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
										<p>Select User</p>
										<div className="input-wrapper-payment text-left mb-3">
											<Select
												value={this.state.userCommissionEditMdl.singleUserId !== null ? { label: this.state.userCommissionEditMdl.fullName, value: 0 } : selectedUsers}
												defaultValue={this.state.userCommissionEditMdl.singleUserId !== null ? { label: this.state.userCommissionEditMdl.fullName, value: 0 } : ''}
												onChange={this.handleChange}
												options={AllUserData}
												isMulti
												placeholder={this.state.UserDetails !== null && this.state.UserDetails.FlowGroupId === FlowGroups.SuperAdmin ? 'Select User' : 'Select User'}
												closeMenuOnSelect={false}
												isSearchable
												className={(this.state.Errors['IsEmpty_UserId'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
											/>
											{this.state.Errors['IsEmpty_UserId'] === true ? <div className="has-err-msg">{this.state.Errors['UserIdEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Commission %</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={(this.state.Errors['IsEmpty_CommissionPercent'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Commission Percent (%)" ref="CommissionPercent" type="text" id="CommissionPercent" defaultValue={this.state.userCommissionEditMdl.commissionPercent ?? ''} required
												onChange={(e) => this._HandleTextChange(e)} disabled={this.state.disAblePercentCommission} />
											<label htmlFor="CommissionPercent">Commission Percent (%)</label>
											{this.state.Errors['IsEmpty_CommissionPercent'] === true ? <div className="has-err-msg">{this.state.Errors['CommissionPercentEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Flat Commission</p>
										<div className="form-floating nput-wrapper mx-auto mb-3">
											<input className={(this.state.Errors['IsEmpty_FlatCommission'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Flat Commission" ref="FlatCommission" type="text" id="FlatCommission"
												defaultValue={this.state.userCommissionEditMdl.flatCommission ?? ''} onChange={(e) => this._HandleTextChange(e)} disabled={this.state.disAbleFlatCommission} />
											<label htmlFor="FlatCommission">Flat Commission</label>
											{this.state.Errors['IsEmpty_FlatCommission'] === true ? <div className="has-err-msg">{this.state.Errors['CommissionPercentEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Program</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={(this.state.Errors['IsEmpty_Program'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Program" ref="Program" type="text" id="Program" defaultValue={this.state.userCommissionEditMdl.program ?? ''} required />
											<label htmlFor="Program">Program</label>
											{this.state.Errors['IsEmpty_Program'] === true ? <div className="has-err-msg">{this.state.Errors['ProgramEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Commission Type</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<input className={(this.state.Errors['IsEmpty_Type'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} placeholder="Type" ref="Type" type="text" id="Type" defaultValue={this.state.userCommissionEditMdl.type ?? ''} required />
											<label htmlFor="Type">Type</label>
											{this.state.Errors['IsEmpty_Type'] === true ? <div className="has-err-msg">{this.state.Errors['TypeEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Commission From Date</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<DatePicker
												selected={this.state.commissionFrom ? moment(this.state.commissionFrom).toDate() : ''}
												onChange={(date) => this.setState({ commissionFrom: date })}
												dateFormat="MMM dd, yyyy"
												locale="en-US"
												ref="from"
												placeholderText="From"
												id="commissionFrom"
												required
												className={(this.state.Errors['IsEmpty_commissionFrom'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
											/>
											{this.state.Errors['IsEmpty_commissionFrom'] === true ? <div className="has-err-msg">{this.state.Errors['commissionFromEmptyMsg']}</div> : ''}
										</div>
										<p>Enter Commission To Date</p>
										<div className="form-floating input-wrapper mx-auto mb-3">
											<DatePicker
												selected={this.state.commissionTo ? moment(this.state.commissionTo).toDate() : ''}
												onChange={(date) => this.setState({ commissionTo: date })}
												dateFormat="MMM dd, yyyy"
												locale="en-US"
												ref="from"
												placeholderText="To"
												id="commissionTo"
												required
												className={(this.state.Errors['IsEmpty_commissionTo'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')}
											/>
											{this.state.Errors['IsEmpty_commissionTo'] === true ? <div className="has-err-msg">{this.state.Errors['commissionToEmptyMsg']}</div> : ''}
										</div>
										<div className="text-center d-flex justify-content-center">
											<button type="button" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
											<button type="button" className="btn btn-newDesign" onClick={() => this._SetCommission()}>Submit</button>
										</div>
									</fieldset>
								</form>

							</div>
						</div>
					</div>
				</div>
				{ /* model for delete commission  */}
				<div className="modal fade" id="confirmdelete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="staticBackdropLabel">Delete Commission</h5>
								{/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
							</div>
							<div className="modal-body">
								<p>Do you want to delete this user commission ?</p> {this.state.deleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
								
								<div className="text-center d-flex justify-content-center">
									<button type="button" id="btncanclemail" className="btn btn-newDesign-reverse me-2" data-bs-dismiss="modal">Cancel</button>
									<button type="button" id="btnsendmail" className="btn btn-newDesign" onClick={() => this._DeleteCommissionById()}>Delete</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default ManageCommission;