import React, { Component, Fragment } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { DownloadInsuranceCertificate } from "../../../services/DownloadService";
import { BaseUrl, CustomCurrencyFormat, DateFormat, NumberFormat } from "../../../common/Setting";
import { NavLink } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import validator from 'validator';
import { showError, showSuccess, subDomain, authHeader, Button, StyledActiveLink } from '../../../common/Common';
import { LoaderContents } from "../../layout/LoaderContents";
import { SavePaymentLink, GetAllCancelledAccountsByCommonUsersId, ModifyPaymentLink, ReactivatePaymentLink } from '../../../services/PaymentServices';
import $ from 'jquery';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from 'react-paginate';
import { GetUserProfile } from '../../../services/UserServices';
import enUS from "date-fns/locale/en-US";

class CancelledAccounts extends Component {
     //this for agent user
      constructor(props) {
        super(props);
        this.state = {
            DownloadLoader: false,
            Errors: {},
            PaymentAmount: null,
            SaveLoaderStatus: false,
            DueDateOfPayment: null,
            UserDetails: authHeader(),
            MonthlyAmount: 0,
            DownPayment: 0,
            PolicyEffectiveDate: null,
            isPaymentAmountEmpty: true,
            AllAccountDetails: [],
            showhidedetails: 1,
            mdlinsuredName: null,
            mdlpolicyNumber: null,
            mdlphoneNumber: null,
            mdlemailAddress: null,
            mdlpremimumamount: null,
            mdldueDate: null,
            mdlinvoiceNumber: null,
            mdldownPayment: null,
            mdlmonthlyPayment: null,
            mdlinsuranceCompany: null,
            mdladdress: null,
            mdlcity: null,
            mdlstate: null,
            mdlzip: null,
            mdlpolicyEffective: null,
            mdlnoofPayments: null,
            mdlcoverageType: null,
            SubDomain: subDomain(),
            billingAccounts: 0,
            originalPremiumAmount: 0,
            accountsfinanced: 0,
            accountspaidinfull: 0,
            AllCancelledAccounts: [],
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 25,
                PageNumber: 1
            },
            showhidedetails: 1,
            PaymentId: null,
            userId: null,
            ServiceFee: 0,
            APR: 0,
            isPaymentAmountEmpty: true
        }
    }
    componentDidMount() {
        this.setState({ SaveLoaderStatus: true }); // show loader befour  data save
        registerLocale("en-US", enUS);
        $("input[name='phoneNumber']").keyup(function () {
        $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"));
        });
        const user = JSON.parse(localStorage.getItem("UserCredential"));
        if (user != null) {
            this._GetUserProfile();

        }
    }

    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ userId: response.data.id });
                        this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, response.data.id);
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }

    clearFormOnClose = () => {
        $("#frmpaymentlink").trigger("reset");
        this.setState({ PaymentAmount: null, DownPayment: 0, MonthlyAmount: 0, isPaymentAmountEmpty: true, ServiceFee: 0, APR: 0, isPaymentAmountEmpty: true, Errors: {} });
    }

    generatelink = (target) => {
        this.setState({ DueDateOfPayment: null });
        this.setState({ PolicyEffectiveDate: null });
        this.setState({
            mdlinsuredName: target.insuredName,
            //mdlinsuranceCompany: target.insuranceCompany,
            mdlcoverageType: target.coverageType,
            mdlnoofPayments: target.termLength,
            mdlpolicyEffectiveDate: target.policyEffectiveDate,
            mdladdress: target.address,
            mdlcity: target.city,
            mdlstate: target.state,
            mdlzip: target.zip,
            mdlpolicyNumber: target.policyNumber,
            mdlphoneNumber: target.phoneNumber,
            mdlemailAddress: target.emailAddress,
            PaymentAmount: target.premimumAmount,
            mdldueDate: target.dueDate,
            mdlpolicyEffective: target.policyEffective,
            mdlinvoiceNumber: target.invoiceNumber,
            DownPayment: target.downPayment,
            ServiceFee: target.serviceFee,
            APR: target.apr,
            MonthlyAmount: target.monthlyAmountBasis,
            PaymentId: target.paymentId
        });
        window.$('#modalGenerateLink').modal('show');
    }


    GetAllCancelledAccountsByCommonUsersId = (searchMdl, userId) => {
        this.setState({ SaveLoaderStatus: true }); // show loader befour load data
        GetAllCancelledAccountsByCommonUsersId(searchMdl, userId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ Pagecount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                    this.setState({ AllCancelledAccounts: response.data.data });

                } else {
                    showError(response.message);
                }
                this.setState({ SaveLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    showhidedetails = (target) => {
        if (target === 1) {
            this.setState({ showhidedetails: 1 });
        } else {
            this.setState({ showhidedetails: 2 });
        }
    };

    //form submit on enter key down
    _SavePaymentLink_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._SavePaymentLink();
        };
    };
    _SavePaymentLink = () => {
        let PaymentLinksMdl = {
            InvoiceNumber: this.refs.InvoiceNumber.value,
            //TermLength: parseInt(this.refs.TermLength.value),
            Name: this.refs.Name.value,
            //CoverageType: this.refs.CoverageType.value,
            Address: this.refs.Address.value,
            City: this.refs.City.value,
            State: this.refs.State.value,
            Zip: this.refs.Zip.value,
            //InsuranceCompany: this.refs.InsuranceCompany.value,
            //PolicyNumber: this.state.mdlpolicyNumber,
            PhoneNumber: this.refs.PhoneNumber.value,
            EmailAddress: this.state.mdlemailAddress,
            PaymentAmount: this.state.PaymentAmount,
            //MonthlyAmount: this.state.MonthlyAmount,
            DownPayment: this.state.DownPayment,
            SentBy: this.state.UserDetails.UserName,
            SentTo: this.state.mdlemailAddress,
            //Note: this.refs.Note.value
            SubDomain: this.state.SubDomain.SubDomain,
            SubDomainId: this.state.SubDomain.SubDomainId,
            PaymentId: this.state.PaymentId,
            IsExpired: true,
            //ServiceFee: this.state.ServiceFee,
            //APR: this.state.APR,
            //DueDateOfPayment: new Date(this.state.mdldueDate) ?? new Date(),
            //PolicyEffectiveDate: new Date(this.state.mdlpolicyEffective) ?? new Date(),
        }

        let status = this.PaymentLinkValidation(PaymentLinksMdl);
        if (status) {
            this.setState({ SaveLoaderStatus: true }); // show loader befour  data save
            ModifyPaymentLink(PaymentLinksMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseMdl').click(); // close model after  data save
                        showSuccess(response.message);
                        $("#frmpaymentlink").trigger("reset");
                        this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, this.state.userId);
                        /*this.props.history.push(subDomain().SubDomain + '/');*/
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ SaveLoaderStatus: false }); // hide loader after  data save
                }
                this.setState({ isPaymentAmountEmpty: true, PaymentAmount: null, DownPayment: 0, MonthlyAmount: 0, APR: 0, ServiceFee: 0,  Errors: {} });
            });
        }
    }
    PaymentLinkValidation = (PaymentLinksMdl) => {
        let errors = {};
        let count = 0;
        if (PaymentLinksMdl.PhoneNumber === null || PaymentLinksMdl.PhoneNumber === undefined || PaymentLinksMdl.PhoneNumber.trim() === "") {
            errors['IsEmpty_PhoneNumber'] = true;
            errors['PhoneNumberEmptyMsg'] = "Please fill the required fields : Phone Number";
            count++;
        }
        else {
            let pattern = new RegExp(/^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/);

            if (!pattern.test(PaymentLinksMdl.PhoneNumber)) {

                errors['IsEmpty_PhoneNumber'] = true;
                errors['PhoneNumberEmptyMsg'] = "Please enter valid phone number";
                count++;

            }
        }
        if (PaymentLinksMdl.EmailAddress === null || PaymentLinksMdl.EmailAddress === undefined || PaymentLinksMdl.EmailAddress.trim() === "") {
            errors['IsEmpty_EmailAddress'] = true;
            errors['EmailAddressEmptyMsg'] = "Please fill the required fields : Email Address";
            count++;
        }
        else {
            if (!validator.isEmail(PaymentLinksMdl.EmailAddress)) {
                errors['IsEmpty_EmailAddress'] = true;
                errors['EmailAddressEmptyMsg'] = "Please fill the Valid fields : Email Addres";
                count++;
            }
        }
        //if (PaymentLinksMdl.APR === null || PaymentLinksMdl.APR === undefined || PaymentLinksMdl.APR === "") {
        //    errors['IsEmpty_APR'] = true;
        //    errors['APREmptyMsg'] = "Please fill the required fields : APR";
        //    count++;
        //}
        //if (PaymentLinksMdl.ServiceFee === null || PaymentLinksMdl.ServiceFee === undefined || PaymentLinksMdl.ServiceFee === "") {
        //    errors['IsEmpty_ServiceFee'] = true;
        //    errors['ServiceFeeEmptyMsg'] = "Please fill the required fields : ServiceFee";
        //    count++;
        //}
        if (PaymentLinksMdl.PaymentAmount === null || PaymentLinksMdl.PaymentAmount === undefined || PaymentLinksMdl.PaymentAmount === "") {
            errors['IsEmpty_PaymentAmount'] = true;
            errors['PaymentAmountEmptyMsg'] = "Please fill the required fields : Total Premium";
            count++;
        }
        if (PaymentLinksMdl.DownPayment === null || PaymentLinksMdl.DownPayment === undefined || PaymentLinksMdl.DownPayment === "") {
            errors['IsEmpty_DownPayment'] = true;
            errors['DownPaymentEmptyMsg'] = "Please fill the required fields : Down Payment";
            count++;
        }
        if (parseFloat(PaymentLinksMdl.DownPayment) > parseFloat(this.state.PaymentAmount)) {
            errors['IsEmpty_DownPayment'] = true;
            errors['DownPaymentEmptyMsg'] = "Down Payment must not be greater than Total Premium";
            count++;
        }
        //if (PaymentLinksMdl.MonthlyAmount === null || PaymentLinksMdl.MonthlyAmount === undefined || PaymentLinksMdl.MonthlyAmount === "") {
        //    errors['IsEmpty_MonthlyPayment'] = true;
        //    errors['MonthlyPaymentEmptyMsg'] = "Please fill the required fields : Monthly Payment";
        //    count++;
        //}
        //if (isNaN(PaymentLinksMdl.TermLength)) {
        //    errors['IsEmpty_TermLength'] = true;
        //    errors['TermLengthEmptyMsg'] = "Please fill the required fields : # of Payments";
        //    count++;
        //}
        if (PaymentLinksMdl.InvoiceNumber === null || PaymentLinksMdl.InvoiceNumber === undefined || PaymentLinksMdl.InvoiceNumber === "") {
            errors['IsEmpty_InvoiceNumber'] = true;
            errors['InvoiceNumberEmptyMsg'] = "Please fill the required fields : Invoice Number";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    _DownloadInsuranceCertificate = () => {
        this.setState({ DownloadLoader: true }); // show loader befour load data
        DownloadInsuranceCertificate().then(res => {
            if (res !== null && res.size > 0) {
                let url = window.URL.createObjectURL(res);
                window.open(url, '_blank');
            }
            else {
                showError(res)
            }
            this.setState({ DownloadLoader: false }); // show loader befour load data
        });
    }
    onClickHandler = (target) => {
        //const hiddenElement = e.currentTarget.nextSibling;
        const hiddenElement = document.querySelector(".section-" + target);
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    //start funstions for pagination
    handlePageClick = (data) => {
        let PageNumber = data.selected + 1;
        this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
        this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, this.state.userId);
    }

    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
        this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, this.state.userId);
    }

    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
        this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, this.state.userId);
    }

    //end pagination function
    handleChange = (selectedUsers) => {
        this.setState({ selectedUsers });
    };

    _ReactivateAccount = (PaymentId) => {
        this.setState({ SendLoader: true }); // show loader befour send mail payment link
        $('#btnsendmail').prop('disabled', true);
        $('#btncanclemail').prop('disabled', true);
        ReactivatePaymentLink(PaymentId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    window.$('#reactivateaccount').modal('hide');
                    this.GetAllCancelledAccountsByCommonUsersId(this.state.searchMdl, this.state.userId);
                    showSuccess(response.message);
                } else {
                    showError(response.message);
                }
                this.setState({ SendLoader: false }); // hide loader after send mail
                $('#btnsendmail').prop('disabled', false);
                $('#btncanclemail').prop('disabled', false);
            }
        });
    }

    render() {
        let url = subDomain().SubDomain === 'Xgen' ? "" : "/" + subDomain().SubDomain;
        return (
            <div className="main-wrapper-newDesign w-100">
                <InternalLayoutHeader />	 {/*internal header user wise */}
                <div className="content-main p-lg-4 p-3">
                    <h1 className="h3 mb-0 fw-normal"><strong>Cancelled Accounts</strong></h1>
                    <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card flex-fill mt-4 border-light w-100  dashboard-card-newDesign">
                                <div className="card-body">
                                    <div className="dashboard-options">
                                        <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                            <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Insured Name or Policy Number" />
                                            {this.state.SaveLoaderStatus &&
                                                <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                            }
                                        </div>

                                        <div className="card-header bg-white border-white p-2">
                                            <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 p-3 table-responsive dashboard-table">
                                        <table className="table display">
                                            <thead className="tbl-head">
                                                <tr>
                                                    <th>Insured name</th>
                                                   {/* <th>Policy number</th>*/}
                                                    <th>Status</th>
                                                    <th>Effective/ Expiration Dates</th>
                                                    <th>Premium amount</th>
                                                    <th>Account balance</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbl-body">
                                                {this.state.AllCancelledAccounts.map((items, index) => (
                                                    <Fragment key={items.id}>
                                                        <tr key={index}>
                                                            {/*<td>{index + 1}</td>*/}
                                                            {/*<td>{(items.userInfoMdl !== null && items.userInfoMdl.fullName !== null) && items.userInfoMdl.fullName}</td>*/}
                                                            <td>{items.insuredName}</td>
                                                           {/* <td>{items.policyNumber}</td>*/}
                                                            <td>{items.policyStatus}</td>
                                                            <td>{items.policyPeriod}</td>
                                                            <td>{CustomCurrencyFormat(items.premimumAmount)}</td>
                                                            <td>{CustomCurrencyFormat(items.accountBalance)}</td>
                                                            
                                                            <td className="dashboard-btn">
                                                                <button className="btn btn-newDesign" onClick={() => this.onClickHandler(items.paymentId)}>
                                                                    View
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr className={`collapse-bg section-${items.paymentId} collapse`}>
                                                            <td colSpan="10">
                                                                <div className="card-body-inner">
                                                                    <h5 className="card-title border-bottom mb-3 pb-3">Payment detail/history</h5>
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-sm-12 ml-auto">
                                                                            {this.state.showhidedetails === 1 &&
                                                                                <table className="table table-clear">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Account balance :</strong>
                                                                                            </td>
                                                                                            <td className="right">{CustomCurrencyFormat(items.accountBalance)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Payment amount :</strong>
                                                                                            </td>
                                                                                            <td className="right">{CustomCurrencyFormat(items.premimumAmount)}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Payments made :</strong>
                                                                                            </td>
                                                                                            <td className="right">
                                                                                                <strong>{items.paymentMade}</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Remaining payments:</strong>
                                                                                            </td>
                                                                                            <td className="right">
                                                                                                <strong>{items.remainingMonths}</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Next payment due date:</strong>
                                                                                            </td>
                                                                                            <td className="right text-danger">
                                                                                                <strong>{DateFormat(items.dueDate)}</strong>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            }
    
                                                                            {this.state.showhidedetails === 2 &&
                                                                                <table className="table table-clear">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Email Address :</strong>
                                                                                            </td>
                                                                                            <td className="right">{items.emailAddress}</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="left">
                                                                                                <strong>Phone Number :</strong>
                                                                                            </td>
                                                                                            <td className="right">{items.phoneNumber}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            }
    
                                                                        </div>
                                                                        <div className="col-lg-6 col-sm-7 ml-auto">
                                                                            <div className="content-main pb-0">
                                                                                <div className="row data-alignment inner-data-alignment">
                                                                                    <div className="col-lg-6  col-md-6 col-sm-6 col-12">
                                                                                        <div className="mysetting-btn card card-border">
                                                                                            <NavLink to="#" className="change-address-btn" onClick={() => this.showhidedetails(1)}>
                                                                                                <div className="icon-svg"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24">
                                                                                                    <path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                                                                                                </svg>
                                                                                                </div>
                                                                                                <div className="btn-content" >View payment history details</div></NavLink>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                        <div className="mysetting-btn card card-border">
                                                                                            <NavLink to="#" className="change-address-btn" onClick={() => this.showhidedetails(2)}>
                                                                                                <div className="icon-svg">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24">
                                                                                                        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.021 17.824c-3.907 0-6.021-2.438-6.021-5.586 0-3.363 2.381-6.062 6.638-6.062 3.107 0 5.362 2.019 5.362 4.801 0 4.356-5.165 5.506-4.906 3.021-.354.555-.927 1.177-2.026 1.177-1.257 0-2.04-.92-2.04-2.403 0-2.222 1.461-4.1 3.19-4.1.829 0 1.399.438 1.638 1.11l.232-.816h1.169c-.122.416-1.161 4.264-1.161 4.264-.323 1.333.675 1.356 1.562.648 1.665-1.29 1.75-4.664-.499-6.071-2.411-1.445-7.897-.551-7.897 4.347 0 2.806 1.976 4.691 4.914 4.691 1.719 0 2.771-.465 3.648-.974l.588.849c-.856.482-2.231 1.104-4.391 1.104zm-1.172-7.153c-.357.67-.588 1.538-.588 2.212 0 1.805 1.761 1.816 2.626.12.356-.697.586-1.586.586-2.265 0-1.458-1.748-1.717-2.624-.067z" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="btn-content" >Contact information</div></NavLink>
                                                                                        </div>
                                                                                    </div>
                                                                      
    
                                                                                    <div className="col-lg-12  col-md-12 col-sm-12 col-12 mb-3">
                                                                                        <div className="mysetting-btn card card-border">
                                                                                            <NavLink to="#" onClick={() => this._DownloadInsuranceCertificate()} className="change-address-btn">
                                                                                                <div className="icon-svg">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="svg-color" viewBox="0 0 24 24">
                                                                                                        <path d="M20.021 0c-1.646 0-2.98 1.335-2.98 2.98 0 .652.21 1.256.566 1.746l-3.275 3.274h2.144l2.309-2.309c.376.173.795.269 1.236.269 1.645 0 2.979-1.334 2.979-2.98s-1.334-2.98-2.979-2.98zm0 2.338c-.513 0-.929-.292-.929-.653s.416-.653.929-.653c.512 0 .928.292.928.653s-.417.653-.928.653zm-14.021 10.662h10v1h-10v-1zm10-2h-10v-1h10v1zm-3-4v1h-7v-1h7zm8 .861v6.524c0 2.392-6.648 9.615-9.811 9.615h-10.189v-24h15.055c-.439.584-.767 1.257-.915 2h-12.14v20h7.362c4.156 0 2.638-6 2.638-6s6 1.65 6-2.457v-5.746c.668.215 1.33.198 2 .064z" />
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="btn-content">View /print insurance certificate</div>
                                                                                                {this.state.DownloadLoader && <i className="fa fa-refresh fa-spin fa-3x"></i>}
                                                                                            </NavLink>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                        <p className="text-center">
                                            {(this.state.Pagecount === 0 && this.state.SaveLoaderStatus === false) && "No Record Found"}
                                        </p>
                                        <div className="pt20px">
                                            { /*loader show befoure load contents*/}
                                            {this.state.SaveLoaderStatus && <LoaderContents />}
                                        </div>
                                    </div>
                                    <div className="d-flex paginate-div">
                                        <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={this.state.Pagecount ?? 0}
                                        marginPagesDisplayed={"4"}
                                        pageRangeDisplayed={"3"}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-start"}
                                        pageClassName={"page-item page-item-none"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item page-item-none"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                        disabledLinkClassName={"disabled"} />
                                        <div className="pagination-page">
                                            Page {this.state.searchMdl.PageNumber} of {this.state.Pagecount > 0 ? this.state.Pagecount : 1}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SiteFooter />  {/*this call for site footer */}
                <div className="modal fade" id="reactivateaccount" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Re-Activate Payment Link</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body">
                                Do you want to Re-Activate this Account ? {this.state.SendLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="button-container">
                                <button type="button" id="btncanclemail" className={`btn btn-newDesign-reverse`} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" id="btnsendmail" className={`btn btn-newDesign `} onClick={() => this._ReactivateAccount(this.state.PaymentId)}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for create payment link */}
                <div className="modal fade" tabIndex="-1" id="modalGenerateLink" aria-labelledby="GenerateLink" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">Modify Link</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.SaveLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="w-75 m-auto" id="frmpaymentlink" onKeyDown={this._SavePaymentLink_EnterButton}>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className="form-control" ref="Name" id="Name" aria-describedby="Name" defaultValue={this.state.mdlinsuredName} placeholder="Name" />
                                        <label htmlFor="Name">Name</label>
                                    </div>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <input type="text" className="form-control" ref="InsuranceCompany" id="insuranceCompany" defaultValue={this.state.mdlinsuranceCompany} aria-describedby="insuranceCompany" placeholder="Insurance Company" />*/}
                                    {/*    <label htmlFor="insuranceCompany">Insurance Company</label>*/}
                                    {/*</div>*/}
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className="form-control" ref="Address" id="Address" aria-describedby="address" defaultValue={this.state.mdladdress} placeholder="Address" />
                                        <label htmlFor="address">Address</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className="form-control" ref="City" id="City" aria-describedby="city" defaultValue={this.state.mdlcity} placeholder="City" />
                                        <label htmlFor="city">City</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className="form-control" ref="State" id="State" aria-describedby="state" placeholder="State" defaultValue={this.state.mdlstate ?? 'CA'} readOnly />
                                        <label htmlFor="state">State</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className="form-control" ref="Zip" id="Zip" aria-describedby="zip" defaultValue={this.state.mdlzip} placeholder="Zip" maxLength="5" />
                                        <label htmlFor="zip">Zip</label>
                                    </div>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <input type="text" className="form-control" ref="PolicyNumber" id="policyNumber" aria-describedby="policyNumber" defaultValue={this.state.mdlpolicyNumber} placeholder="Policy Number" disabled />*/}
                                    {/*    <label htmlFor="policyNumber">Policy Number</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <input type="text" className="form-control" ref="CoverageType" maxLength="2" id="coverageType" aria-describedby="coverageType" defaultValue={this.state.mdlcoverageType} placeholder="Coverage Type" />*/}
                                    {/*    <label htmlFor="coverageType">Coverage Type</label>*/}
                                    {/*</div>*/}
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_PhoneNumber'] === true ? ' has-err' : '')} ref="PhoneNumber" id="PhoneNumber" defaultValue={this.state.mdlphoneNumber} name="phoneNumber" placeholder="Phone Number*" maxLength="14" required />
                                        {this.state.Errors['IsEmpty_PhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PhoneNumberEmptyMsg']}</div> : ''}
                                        <label htmlFor="PhoneNumber">Phone Number</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="email" className={'form-control' + (this.state.Errors['IsEmpty_EmailAddress'] === true ? ' has-err' : '')} ref="EmailAddress" id="EmailAddress" defaultValue={this.state.mdlemailAddress} placeholder="Email Address*" required disabled />
                                        {this.state.Errors['IsEmpty_EmailAddress'] === true ? <div className="has-err-msg">{this.state.Errors['EmailAddressEmptyMsg']}</div> : ''}
                                        <label htmlFor="EmailAddress">Email Address</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            prefix={'$'}
                                            placeholder="payment Amount*"
                                            value={this.state.PaymentAmount ?? ''}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className={'form-control' + (this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err' : '')}
                                            name="PaymentAmount"
                                            id="PaymentAmount"
                                            onValueChange={(values) => {
                                                this.setState({ PaymentAmount: values.value ?? 0 });
                                            }}
                                            required
                                        />
                                        {/* <input type="number" className={'form-control' + (this.state.Errors['IsEmpty_PaymentAmount'] === true ? ' has-err' : '')} ref="PaymentAmount" id="PaymentAmount" placeholder="Payment Amount*" required />*/}
                                        {this.state.Errors['IsEmpty_PaymentAmount'] === true ? <div className="has-err-msg">{this.state.Errors['PaymentAmountEmptyMsg']}</div> : ''}
                                        <label htmlFor="PaymentAmount">Total Premium</label>
                                    </div>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <CurrencyFormat
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                            prefix={'$'}
                                            placeholder="Down Payment*"
                                            value={this.state.DownPayment ?? ''}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            className={'form-control' + (this.state.Errors['IsEmpty_DownPayment'] === true ? ' has-err' : '')}
                                            name="DownPayment"
                                            id="DownPayment"
                                            onValueChange={(values) => {
                                                this.setState({ DownPayment: values.value ?? 0 });
                                            }}
                                            required
                                        />
                                        {this.state.Errors['IsEmpty_DownPayment'] === true ? <div className="has-err-msg">{this.state.Errors['DownPaymentEmptyMsg']}</div> : ''}
                                        <label htmlFor="DownPayment">Down Payment</label>
                                    </div>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <CurrencyFormat*/}
                                    {/*        thousandSeparator={true}*/}
                                    {/*        decimalSeparator="."*/}
                                    {/*        prefix={'$'}*/}
                                    {/*        placeholder="monthly Amount*"*/}
                                    {/*        value={this.state.MonthlyAmount ?? ''}*/}
                                    {/*        decimalScale={2}*/}
                                    {/*        fixedDecimalScale={true}*/}
                                    {/*        className={'form-control' + (this.state.Errors['IsEmpty_MonthlyPayment'] === true ? ' has-err' : '')}*/}
                                    {/*        name="MonthlyAmount"*/}
                                    {/*        id="MonthlyAmount"*/}
                                    {/*        onValueChange={(values) => {*/}
                                    {/*            this.setState({ MonthlyAmount: values.value ?? 0 });*/}
                                    {/*        }}*/}
                                    {/*        required*/}

                                    {/*    />*/}
                                    {/*    {this.state.Errors['IsEmpty_MonthlyPayment'] === true ? <div className="has-err-msg">{this.state.Errors['MonthlyPaymentEmptyMsg']}</div> : ''}*/}
                                    {/*    <label htmlFor="MonthlyAmount">Monthly Amount</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_TermLength'] === true ? ' has-err' : '')} ref="TermLength" id="TermLength" defaultValue={this.state.mdlnoofPayments} placeholder="# of Payments" required />*/}
                                    {/*    {this.state.Errors['IsEmpty_TermLength'] === true ? <div className="has-err-msg">{this.state.Errors['TermLengthEmptyMsg']}</div> : ''}*/}
                                    {/*    <label htmlFor="TermLength"># of Payments</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <CurrencyFormat*/}
                                    {/*        thousandSeparator={true}*/}
                                    {/*        decimalSeparator="."*/}
                                    {/*        prefix={'$'}*/}
                                    {/*        placeholder="APR"*/}
                                    {/*        value={this.state.APR ?? ''}*/}
                                    {/*        decimalScale={2}*/}
                                    {/*        fixedDecimalScale={true}*/}
                                    {/*        className={'form-control' + (this.state.Errors['IsEmpty_APR'] === true ? ' has-err' : '')}*/}
                                    {/*        name="APR"*/}
                                    {/*        id="APR"*/}
                                    {/*        onValueChange={(values) => {*/}
                                    {/*            this.setState({ APR: values.value ?? 0 });*/}
                                    {/*        }}*/}
                                    {/*        required*/}
                                    {/*    />*/}
                                    {/*    {this.state.Errors['IsEmpty_APR'] === true ? <div className="has-err-msg">{this.state.Errors['APREmptyMsg']}</div> : ''}*/}
                                    {/*    <label htmlFor="APR">APR</label>*/}
                                    {/*</div>*/}

                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <CurrencyFormat*/}
                                    {/*        thousandSeparator={true}*/}
                                    {/*        decimalSeparator="."*/}
                                    {/*        prefix={'$'}*/}
                                    {/*        placeholder="Service Fee"*/}
                                    {/*        value={this.state.ServiceFee ?? ''}*/}
                                    {/*        decimalScale={2}*/}
                                    {/*        fixedDecimalScale={true}*/}
                                    {/*        className={'form-control' + (this.state.Errors['IsEmpty_ServiceFee'] === true ? ' has-err' : '')}*/}
                                    {/*        name="ServiceFee"*/}
                                    {/*        id="ServiceFee"*/}
                                    {/*        onValueChange={(values) => {*/}
                                    {/*            this.setState({ ServiceFee: values.value ?? 0 });*/}
                                    {/*        }}*/}
                                    {/*        required*/}
                                    {/*    />*/}
                                    {/*    {this.state.Errors['IsEmpty_ServiceFee'] === true ? <div className="has-err-msg">{this.state.Errors['ServiceFeeEmptyMsg']}</div> : ''}*/}
                                    {/*    <label htmlFor="ServiceFee">ServiceFee</label>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <DatePicker*/}
                                    {/*        selected={new Date(this.state.mdldueDate) ?? ''}*/}
                                    {/*        onChange={(date) => this.setState({ mdldueDate: date })}*/}
                                    {/*        dateFormat="MMM dd, yyyy"*/}
                                    {/*        value={this.state.mdldueDate ?? ''}*/}
                                    {/*        locale="en-US"*/}
                                    {/*        ref="duedate"*/}
                                    {/*        placeholderText="Due date of payment"*/}
                                    {/*        id="duedateofpayment"*/}
                                    {/*        className="w-100 form-control"*/}

                                    {/*    />*/}
                                    {/*</div>*/}
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <DatePicker*/}
                                    {/*        selected={new Date(this.state.mdlpolicyEffective) ?? ''}*/}
                                    {/*        onChange={(date1) => this.setState({ mdlpolicyEffective: date1 })}*/}
                                    {/*        dateFormat="MMM dd, yyyy"*/}
                                    {/*        value={this.state.mdlpolicyEffective ?? ''}*/}
                                    {/*        locale="en-US"*/}
                                    {/*        ref="policyeffective"*/}
                                    {/*        placeholderText="Policy Effective Date"*/}
                                    {/*        id="policyeffectivedate"*/}
                                    {/*        className="w-100 form-control"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_InvoiceNumber'] === true ? ' has-err' : '')} ref="InvoiceNumber" id="InvoiceNumber" defaultValue={this.state.mdlinvoiceNumber} placeholder="Invoice number" required />
                                        {this.state.Errors['IsEmpty_InvoiceNumber'] === true ? <div className="has-err-msg">{this.state.Errors['InvoiceNumberEmptyMsg']}</div> : ''}
                                        <label htmlFor="InvoiceNumber">Invoice Number</label>
                                    </div>
                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                    {/*    <textarea className="form-control" rows="3" ref="Note" placeholder="Note" id="Note" autoComplete="nope" autoCorrect="off" spellCheck="off" name="Note"></textarea>*/}
                                    {/*    <label htmlFor="Note">Note</label>*/}
                                    {/*</div>*/}
                                    <div className="button-container">
                                        <button type="button" id="btncloseMdl" className="btn btn-newDesign-reverse" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.clearFormOnClose()}></button>
                                        <button type="button" className="btn btn-newDesign" onClick={() => this._SavePaymentLink()}>Modify</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default CancelledAccounts;