function refreshCache() {
    if (caches) {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}


function clearStoragesAndCookies() {
    localStorage.clear();
    sessionStorage.clear();
    //deleteAllCookies();
}

async function checkIfVersionChange(appVersion) {
    try {
        // Fetch the meta.json file from the public folder
        const response = await fetch(`${process.env.PUBLIC_URL}/meta.json`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const meta = await response.json();
        const latestVersion = meta.version;
        const currentVersion = appVersion;
        const oldVersion = localStorage.getItem("current_version");


        if (oldVersion !== latestVersion) {
            console.log("Version mismatch detected. Clearing storage and reloading...");
            clearStoragesAndCookies();
            refreshCache();
            localStorage.setItem("current_version", latestVersion);
            location.reload(); // Force reload
        }
    } catch (error) {
        console.error("Error fetching meta.json:", error);
    }
}

export { checkIfVersionChange, refreshCache, deleteAllCookies };