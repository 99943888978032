import React, { Component } from 'react';
import { LoginUser, RegisterUserInfo, ForgetPassword, GetUserInfoByMailLink, CheckEmailAreadyexists, GetUserTextActiveByUserId, GetUserActiveStatus } from '../../../services/UserServices';
import { AddProductTourData, GetProductTourDataByUserId } from '../../../services/ProductTourServices';
import { SiteFooter } from '../../layout/SiteFooter';
import GetPlace from "../../../services/GetPlace";
import { LoaderContents } from "../../layout/LoaderContents";
import { NavLink } from 'react-router-dom';
import validator from 'validator';
import { FlowGroups, ProductTourQuestions } from '../../../common/Enum';
import { showError, ExternalDomain } from '../../../common/Common';
import $ from 'jquery';

class Login extends Component {
    constructor(props) {
        super(props);
        //this.IsTextActive = this.IsTextActive.bind(this);
        this.state = {
            reponseMessage: '',
            isForgotPassword: null,
            ForgetPassLoaderStatus: false,
            has_account: true,
            FlowGroupId: 1,
            isLoginButtondisable: true,
            isRegisterButtondisable: true,
            Errors: {},
            IsTextActive: false,
            Address: null,
            Answers: [],
            QuestionIds: [],
            AspNetUsersMdl: {
                url: "",
                userInfoMdl: {}
            },
            queryParams: new URLSearchParams(window.location.search),
            Isdisabled: false,
            IsEmailAreadyexists: null,
            SaveLoaderStatus: false,
            Email: '',
            isTextAct: false,
            LoaderStatus: false,
            DynamicLogoPath: JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo,
            RememberMe: false
        };
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    componentDidMount() {
        //this.setState({ LoaderStatus: true })
        this.setState({ SaveLoaderStatus: true });
        $("input[name='phoneNumber']").keyup(function () {
            $(this).val($(this).val().replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3"));
        });
        if (this.state.queryParams.has("userId")) {
            this._GetUserInfoByMailLink();
            GetUserActiveStatus(this.state.queryParams.get("userId")).then(data => {
                if (!data) {
                    $('.step-login').removeClass('form-selected').addClass('form-dismiss');
                    $('.step-register').removeClass('form-dismiss').addClass('form-selected');
                } else {
                    $('.step-register').removeClass('form-selected').addClass('form-dismiss');
                    $('.step-login').removeClass('form-dismiss').addClass('form-selected');
                }
            })
        }
        else {
            if (this.state.UserDetails != null) {
                // call get profile local function here
                this._GetUserProfile();
            }
        }

    }
    _GetUserActiveStatus = (userId) => {
        GetUserActiveStatus(userId).then(data => {
            console.log("data", data)
            return data;
        })
    }

    _AddProductTourData = () => {
        // POST request using fetch with async/await

        if (this.refs.q1.value !== null && this.refs.q1.value.trim() !== "") {
            this.state.Answers.push(this.refs.q1.value);
            this.state.QuestionIds.push(ProductTourQuestions.Q1);
        }
        if (this.refs.q2.value !== null && this.refs.q2.value.trim() !== "") {
            this.state.Answers.push(this.refs.q2.value);
            this.state.QuestionIds.push(ProductTourQuestions.Q2);
        }
        if (this.refs.q3.value !== null && this.refs.q3.value.trim() !== "") {
            this.state.Answers.push(this.refs.q3.value);
            this.state.QuestionIds.push(ProductTourQuestions.Q3);
        }
        if (this.refs.q4.value !== null && this.refs.q4.value.trim() !== "") {
            this.state.Answers.push(this.refs.q4.value);
            this.state.QuestionIds.push(ProductTourQuestions.Q4);
        }
        let productTourMdl = {
            UserId: this.state.queryParams.get("userId"),
            QuestionIds: this.state.QuestionIds,
            Answers: this.state.Answers
        }

        AddProductTourData(productTourMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    $("#btnclose").click();
                } else {
                    showError(response.message);
                }
            }
        });

    }
    _GetProductTourDataByUserId() {
        GetProductTourDataByUserId(this.state.queryParams.get("userId")).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data === null || response.data.length === 0) {
                        window.$('#modalStartOver').modal('show');
                    }
                }
            }
        });
    }
    _GetUserInfoByMailLink() {
        GetUserInfoByMailLink(this.state.queryParams.get("userId")).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    let res = response.data;
                    this.setState({ AspNetUsersMdl: res });
                    this.setState({ FlowGroupId: res.flowGroupId });
                    this.setState({ Isdisabled: true });
                    if (res.flowGroupId === FlowGroups.Agent) {
                       //this._GetProductTourDataByUserId();
                        window.$('#modalStartOver').modal('show');
                    }
                    if (res.flowGroupId === FlowGroups.GAProgramManager) {
                        //this._GetProductTourDataByUserId();
                        window.$('#GAmodalStartOver').modal('show');
                    }
                    this.setState({ SaveLoaderStatus: false });
                } else {
                    showError(response.message);
                }
            }
            //this.setState({ LoaderStatus: false })
        });
        if (this.state.queryParams.has("userId") !== null) {
            GetUserTextActiveByUserId(this.state.queryParams.get("userId")).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        let res = response.data;
                        this.setState({ IsTextActive: res, isTextAct: res });
                    } else {
                        showError(response.message);
                    }
                }
            });
        }
    }
    handleChangeEmail(event) { this.setState({ Email: event.target.value }); }
    // form submit on enter key down (Selecttion form login or register)
    //_LoginStep_EnterButton = (event) => {
    //    let keyCode = event.which || event.keyCode;
    //    if (keyCode === 13) {
    //        if (this.state.has_account) {
    //            this.onButtonEvent('Login');
    //        }
    //        else {
    //            this.onButtonEvent('Register');
    //        }
    //    };
    //};
    //form submit on enter key down - (Login From)
    _LoginUser_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._LoginUser();
        };
    };
    //form submit on enter key down
    _RegisterUser_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._RegisterUser();
        };
    };
    //form submit on enter key down model for forgote pass
    _ForgetPassword_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ForgetPassword();
        };
    };
    // login user
    _LoginUser = () => {
        let UserCredential = {
            UserName: this.refs.UserName.value,
            Password: this.refs.Password.value
        }
        let status = this.LoginValidation(UserCredential.UserName, UserCredential.Password);
        if (status) {
            LoginUser(UserCredential.UserName, UserCredential.Password, this.state.RememberMe);
        }
    }
    //register new user
    _RegisterUser = () => {
        // POST request using fetch with async/await
        let AspNetUsersMdl = {
            Id: this.state.AspNetUsersMdl.id,
            Email: this.refs.Email.value,
            ConfirmEmail: this.refs.ConfirmEmail.value,
            PhoneNumber: this.refs.PhoneNumber.value,
            PasswordHash: this.refs.PasswordHash.value,
            ConfirmPasswordHash: this.refs.ConfirmPasswordHash.value,
            FlowGroupId: this.state.FlowGroupId,
            userInfoMdl: {
                FullName: this.refs.FullName.value,
                Address: this.state.Address,
                IsTextActive: this.state.IsTextActive
            }
        }
        let status = this.RegisterValidation(AspNetUsersMdl);
        if (status) {
            RegisterUserInfo(AspNetUsersMdl);
        }
    }
    // validation for login 
    LoginValidation = (UserName, Password) => {
        let errors = {};
        let count = 0;
        if (UserName === null || UserName === undefined || UserName.trim() === "") {
            errors['Is_UserNameEmpty'] = true;
            errors['UserNameEmptyMsg'] = "Please fill the required fields : Email";
            count++;
        }
        if (Password === null || Password === undefined || Password.trim() === "") {
            errors['Is_PasswordEmpty'] = true;
            errors['PasswordEmptyMsg'] = "Please fill the required fields : Password";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //Checkbox on change
    IsTextActive = (event) => {
        if (event.target.checked) {
            this.setState({ IsTextActive: true });
        }
        else {
            this.setState({ IsTextActive: false });
        }
    }
    //validation for registration 
    RegisterValidation = (AspNetUsersMdl) => {
        let errors = {};
        let count = 0;
        if (AspNetUsersMdl.userInfoMdl.FullName === null || AspNetUsersMdl.userInfoMdl.FullName === undefined || AspNetUsersMdl.userInfoMdl.FullName.trim() === "") {
            errors['IsEmpty_FullName'] = true;
            errors['FullNameEmptyMsg'] = "Please fill the required fields : Full Name";
            count++;
        }

        if (AspNetUsersMdl.Email === null || AspNetUsersMdl.Email === undefined || AspNetUsersMdl.Email.trim() === "") {
            errors['IsEmpty_Email'] = true;
            errors['EmailEmptyMsg'] = "Please fill the required fields : Email";
            count++;
        }
        //else {
        //    if (!validator.isEmail(AspNetUsersMdl.Email)) {
        //        errors['IsEmpty_Email'] = true;
        //        errors['EmailEmptyMsg'] = "Please fill the Valid fields : Email";
        //        count++;
        //    }
        //}
        if (AspNetUsersMdl.ConfirmEmail === null || AspNetUsersMdl.ConfirmEmail === undefined || AspNetUsersMdl.ConfirmEmail.trim() === "") {
            errors['IsEmpty_ConfirmEmail'] = true;
            errors['ConfirmEmailEmptyMsg'] = "Required fields : Confirm email";
            count++;
        }
        else {
            //if (!validator.isEmail(AspNetUsersMdl.ConfirmEmail)) {
            //    errors['IsEmpty_ConfirmEmail'] = true;
            //    errors['ConfirmEmailEmptyMsg'] = "Please fill the Valid fields : Confirm email";
            //    count++;
            //}
            if (!validator.equals(AspNetUsersMdl.Email, AspNetUsersMdl.ConfirmEmail)) {
                errors['IsEmpty_ConfirmEmail'] = true;
                errors['ConfirmEmailEmptyMsg'] = "Email doesn't match : Confirm email";
                count++;
            }
        }
        if (AspNetUsersMdl.PasswordHash === null || AspNetUsersMdl.PasswordHash === undefined || AspNetUsersMdl.PasswordHash.trim() === "") {
            errors['IsEmpty_PasswordHash'] = true;
            errors['PasswordHashEmptyMsg'] = "Please fill the required fields : Password";
            count++;
        }
        else {
            if (!validator.isLength(AspNetUsersMdl.PasswordHash, 8)) {
                errors['IsEmpty_PasswordHash'] = true;
                errors['PasswordHashEmptyMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (AspNetUsersMdl.ConfirmPasswordHash === null || AspNetUsersMdl.ConfirmPasswordHash === undefined || AspNetUsersMdl.ConfirmPasswordHash.trim() === "") {
            errors['IsEmpty_ConfirmPasswordHash'] = true;
            errors['ConfirmPasswordHashEmptyMsg'] = "Please fill the required fields : Password";
            count++;
        }
        else {
            if (!validator.isLength(AspNetUsersMdl.ConfirmPasswordHash, 8)) {
                errors['IsEmpty_ConfirmPasswordHash'] = true;
                errors['ConfirmPasswordHashEmptyMsg'] = "Password must at least 8 characters long";
                count++;
            }
            else if (!validator.equals(AspNetUsersMdl.PasswordHash, AspNetUsersMdl.ConfirmPasswordHash)) {
                errors['IsEmpty_ConfirmPasswordHash'] = true;
                errors['ConfirmPasswordHashEmptyMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
        }
        //if (this.state.FlowGroupId === 0 || this.state.FlowGroupId === null || this.state.FlowGroupId === undefined) {
        //    errors['IsEmpty_Role'] = true;
        //    errors['RoleEmptyMsg'] = "Please Select the required fields : Flow group";
        //    count++;
        //}

        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    // this is for selection login or register 
    onChangeValue = (event) => {
        const step = event.target.value;
        if (step === 'has_account') {
            this.setState({ has_account: true });
            this.setState({ isLoginButtondisable: false });
            this.setState({ isRegisterButtondisable: true });
        }
        else {
            this.setState({ has_account: false });
            this.setState({ isLoginButtondisable: true });
            this.setState({ isRegisterButtondisable: false });
        }
    }
    // change user for register new user
    ChangeUserRoleValue = e => {
        this.setState({ FlowGroupId: e.target.value });
    };
    _CheckEmailAreadyexists = (event) => {
        let email = event.target.value;
        this.setState({ reponseMessage: null, IsEmailAreadyexists: null });
        if (email !== null && email !== undefined && email.trim() !== "") {
            let status = this.EmailValidation(email);
            if (status) {
                CheckEmailAreadyexists(email).then(data => {
                    let response = data;
                    if (response.message !== Error) {
                        if (response.status === true) {
                            this.setState({ reponseMessage: response.message, IsEmailAreadyexists: true });
                            //showSuccess(response.message);
                        }
                        else {
                            this.setState({ reponseMessage: response.message, IsEmailAreadyexists: false });
                            //showError(response.message);
                        }
                    }
                });
            }
        }
    }
    _ForgetPassword = async () => {
        let email = this.refs.emailForgotPassword.value;
        let status = this.ForgotPasswordValidation(email);
        this.setState({ reponseMessage: null, isForgotPassword: null });
        if (status) {
            this.setState({ ForgetPassLoaderStatus: true });
            ForgetPassword(email).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#modalForgotPassword').modal('hide');
                        this.props.history.push(`/enter-passcode?RequestToken=${response.data.requestToken}`);
                    }
                    else {
                        this.setState({ reponseMessage: response.message, isForgotPassword: false });
                    }
                    this.setState({ ForgetPassLoaderStatus: false }); // hide loader after  data save
                }
            });
        }
    }
    //validtion for forget password
    ForgotPasswordValidation = (email) => {
        let errors = {};
        let count = 0;
        if (email === null || email === undefined || email.trim() === "") {
            errors['Is_emailForgotPasswordEmpty'] = true;
            errors['emailForgotPasswordEmptyMsg'] = "Please fill the required fields : email";
            count++;
        }
        else {
            if (!validator.isEmail(email)) {
                errors['Is_emailForgotPasswordEmpty'] = true;
                errors['emailForgotPasswordEmptyMsg'] = "Please fill the Valid fields : Email";
                count++;
            }
        }

        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //validtion for forget password
    EmailValidation = (email) => {
        let errors = {};
        let count = 0;
        let pattern = /^([\w\.\-\+]+)@([\w\-]+)((\.(\w){2,3})+)$/;
        let ttt = email.match(pattern);
        /* Match match = pattern.Match(this.EmailAddress);*/
        if (ttt === null) {
            errors['IsEmpty_Email'] = true;
            errors['EmailEmptyMsg'] = "Please fill the Valid fields : Email";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    // this is for form select like ligin or register
    //onButtonEvent = (NextStep) => {
    //    if (NextStep === 'Login') {
    //        $('.step-login-start').removeClass('form-selected').addClass('form-dismiss');
    //        $('.step-login').removeClass('form-dismiss').addClass('form-selected');
    //        // $("#step-login-start").css("display", "none");
    //        //$("#step-login").css("display", "block");
    //    }
    //    else if (NextStep === 'Register') {
    //        $('.step-login-start').removeClass('form-selected').addClass('form-dismiss');
    //        $('.step-register').removeClass('form-dismiss').addClass('form-selected');
    //        // $("#step-login-start").css("display", "none");
    //        // $("#step-register").css("display", "block");
    //    }
    //    else if (NextStep === 'LoginBack' || NextStep === 'RegisterBack') {
    //        $('.step-register').removeClass('form-selected').addClass('form-dismiss');
    //        $('.step-login').removeClass('form-selected').addClass('form-dismiss');
    //        $('.step-login-start').removeClass('form-dismiss').addClass('form-selected-Out');
    //        //$("#step-register").css("display", "none");
    //        //$("#step-login").css("display", "none");
    //        //$("#step-login-start").css("display", "block");
    //    }
    //}
    render() {
        var ButtonDynamic = ExternalDomain(JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutColor, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutHoverFocus, JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutSubDomainId);
        return (
            <>
                <main>
                    <div className="container form-space p-space">
                        <div className="justify-content-center">
                            <div className="col-md-auto">
                                <form className="step-form-wrapper position-relative step-login form-selected" onKeyDown={this._LoginUser_EnterButton}>
                                    <fieldset id="step-login" className="step-form" data-field="Login" data-next="Dashboard">
                                        <div className="mx-auto mb-3 login-welcome">
                                            <img className="mb-5" src= { this.state.DynamicLogoPath }/>
                                            <h2 className="fw-bold">Welcome Back</h2>
                                            <h6>Enter your email and password to log in.</h6>
                                        </div>

                                        <div className="input-wrapper mx-auto mb-3">
                                            <h6>Email</h6>
                                            <input className={this.state.Errors['Is_UserNameEmpty'] === true ? 'has-err' : ''} placeholder="Enter your email" type="text" ref="UserName" name="UserName" autoComplete="username" id="input-user" onChange={this.handleChangeEmail} required />
                                            {this.state.Errors['Is_UserNameEmpty'] === true ? <div className="has-err-msg">{this.state.Errors['UserNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="input-wrapper mx-auto">
                                            <h6>Password</h6>
                                            <input className={this.state.Errors['Is_PasswordEmpty'] === true ? 'has-err' : ''} placeholder="Enter your password" ref="Password" type="password" name="Password" autoComplete="current-password" id="input-pwd" required />
                                            {this.state.Errors['Is_PasswordEmpty'] === true ? <div className="has-err-msg">{this.state.Errors['PasswordEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="input-radio checkbox remember_acc input-checkbox-h6">
                                            <input type="checkbox" name="remember_acc" id="radio-remember" value="remember_acc" onChange={() => this.setState({ RememberMe: event.target.checked }) } />
                                            <label htmlFor="radio-remember">
                                                <p className="remember-label">Remember Me</p>
                                            </label>
                                            <p className="forgot-pwd">
                                                <NavLink to="#modalForgotPassword" className="change-pass-btn" data-bs-toggle="modal" style={{ color: "inherit", textDecoration:"none" }}>Forgot Password?</NavLink>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex ps-2  Dashboard-btn">
                                                <ButtonDynamic type="button" id="Dashboard" className="center-aligned" onClick={() => this._LoginUser()}>
                                                    <span className="label">Login</span>
                                                </ButtonDynamic>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                                    
                                <form className="step-form-wrapper position-relative step-register form-dismiss" onKeyDown={this._RegisterUser_EnterButton}>
                                    <fieldset id="step-register" className="step-form" data-field="Register" data-next="Dashboard">
                                        {/*<h2 className="mb-5 text-center">Please enter your details. </h2>*/}
                                        <h2 className="mb-5 text-center" style={{ fontSize: 20 }}>Please create a password below to access your billing account. </h2>
                                        {this.state.SaveLoaderStatus &&
                                            <span style={{ position: "absolute", right: "20px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                        }
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_FullName'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Your full name" ref="FullName" type="text" defaultValue={this.state.AspNetUsersMdl.userInfoMdl.fullName ?? ''} id="FullName" required />
                                            <label htmlFor="FullName">Your full name</label>
                                            {this.state.Errors['IsEmpty_FullName'] === true ? <div className="has-err-msg">{this.state.Errors['FullNameEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={(this.state.Errors['IsEmpty_Email'] === true || this.state.IsEmailAreadyexists === false) ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Your email" ref="Email" type="text" name="Email" autoComplete="off" defaultValue={this.state.AspNetUsersMdl.email ?? ""} id="email" disabled={this.state.Isdisabled} onBlur={() => this._CheckEmailAreadyexists(event)} required />
                                            <label htmlFor="email">Your email</label>
                                            {this.state.Errors['IsEmpty_Email'] === true ? <div className="has-err-msg">{this.state.Errors['EmailEmptyMsg']}</div> : ''}
                                            {this.state.IsEmailAreadyexists === true && <span className="text-success">{this.state.reponseMessage ?? ""}</span>}
                                            {this.state.IsEmailAreadyexists === false && <span className="has-err-msg">{this.state.reponseMessage ?? ""}</span>}
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_ConfirmEmail'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Confirm email" ref="ConfirmEmail" type="text" name="ConfirmEmail" autoComplete="off" id="ConfirmEmail" defaultValue={this.state.AspNetUsersMdl.email ?? ""} disabled={this.state.Isdisabled} required />
                                            <label htmlFor="ConfirmEmail">Confirm email</label>
                                            {this.state.Errors['IsEmpty_ConfirmEmail'] === true ? <div className="has-err-msg">{this.state.Errors['ConfirmEmailEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input type="text" className={(this.state.Errors['IsEmpty_PhoneNumber'] === false ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="PhoneNumber" id="PhoneNumber" defaultValue={this.state.AspNetUsersMdl.phoneNumber ?? ""} name="phoneNumber" placeholder="Phone Number" maxLength="14" required />
                                            {this.state.Errors['IsEmpty_PhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PhoneNumberEmptyMsg']}</div> : ''}
                                            <label htmlFor="PhoneNumber">Phone Number</label>
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_PasswordHash'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Password" ref="PasswordHash" type="password" name="PasswordHash" autoComplete="new-password" id="password" required />
                                            <label htmlFor="password">Password</label>
                                            {this.state.Errors['IsEmpty_PasswordHash'] === true ? <div className="has-err-msg">{this.state.Errors['PasswordHashEmptyMsg']}</div> : ''}
                                        </div>
                                        <div className="form-floating input-wrapper mx-auto mb-3">
                                            <input className={this.state.Errors['IsEmpty_ConfirmPasswordHash'] === true ? 'has-err input-newDesign form-control' : 'input-newDesign form-control'} placeholder="Confirm password" ref="ConfirmPasswordHash" type="password" name="ConfirmPasswordHash" autoComplete="new-password" id="ConfirmPassword" required />
                                            <label htmlFor="ConfirmPassword">Confirm Password</label>
                                            {this.state.Errors['IsEmpty_ConfirmPasswordHash'] === true ? <div className="has-err-msg">{this.state.Errors['ConfirmPasswordHashEmptyMsg']}</div> : ''}
                                        </div>
                                        {this.state.isTextAct === false &&
                                            <div className="mx-auto mb-12" >
                                                <div className='input-wrapper-radio' style={{ width: "375px" }}>
                                                    <div className="col-12 promotional-modal-check">
                                                        <input type="checkbox" id="radio-Active" name="IsTextActive" checked={this.state.IsTextActive} required className="form-check-input" onChange={this.IsTextActive} />
                                                        <label className="form-check-label">
                                                        You agree to recieve automated promotional messages. This agreement isn't a condition of any purchase. 4 Msgs/Month.
                                                         Msg & Data rates may apply. Reply STOP to end or HELP for help.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="input-wrapper mx-auto">
                                            <div className="d-flex justify-content-center mt-4">
                                                <div className="d-flex ps-3">
                                                    <button type="button" className="btn btn-newDesign btn-large" onClick={() => this._RegisterUser()}>

                                                        <span className="label">Register</span>
                                                        <svg className="icon-arrow after">
                                                            <use xlinkHref="#arrow"></use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow-left" viewBox="0 0 35 15">
                                            <title>Arrow Left</title>
                                            <path id="Layer" className="s0" d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
                                        </symbol>
                                    </defs>
                                </svg>
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow" viewBox="0 0 35 15">
                                            <title>Arrow</title>
                                            <path d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z " />
                                        </symbol>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </main>
                { /*model for Forget password*/}
                <div className="modal fade" tabIndex="-1" id="modalForgotPassword" aria-labelledby="modalForgotPassword" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">Forgot password</h5>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.ForgetPassLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="w-75 m-auto" id="frmforgotpassword" onKeyDown={this._ForgetPassword_EnterButton}>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="text" className={(this.state.Errors['Is_emailForgotPasswordEmpty'] === true || this.state.isForgotPassword === false ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} ref="emailForgotPassword" id="forgotPassword" aria-describedby="forgotPassword" defaultValue={this.state.Email ?? ''} placeholder="Enter your email *" required />
                                        <label htmlFor="forgotPassword">Enter your email</label>
                                        {this.state.isForgotPassword === true && <span className="text-success">{this.state.reponseMessage ?? ""}</span>}
                                        {this.state.isForgotPassword === false && <span className="text-danger">{this.state.reponseMessage ?? ""}</span>}
                                        {this.state.Errors['Is_emailForgotPasswordEmpty'] === true ? <span className="has-err-msg">{this.state.Errors['emailForgotPasswordEmptyMsg']}</span> : ''}
                                    </div>
                                    <div className="text-center d-flex justify-content-center">
                                        <button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ForgetPassword()}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/*for Agent user*/}
                <div className="modal fade" tabIndex="-1" id="modalStartOver" aria-labelledby="ModalStartOver" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0 justify-content-center">
                                {/* <h5 className="modal-title">WANT TO START OVER?</h5>*/}
                                <button type="button" id="btnclose" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center p-0">
                                <div className="row">

                                    <div className="col-12">
                                        <div id="product-tour" className="carousel carousel-dark slide" data-bs-interval="false">

                                            <div className="carousel-inner">
                                                <div className="carousel-item active" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3 ActiveSidebar">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />Agent User creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Agency Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg  column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/step1.gif" />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="carousel-item" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />Agent User creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3 ActiveSidebar"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Agency Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg  column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/step2.gif" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="carousel-item" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />Agent User creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3 ActiveSidebar"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Agency Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/step3.gif" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ButtonDynamic className="carousel-control-prev" type="button" data-bs-target="#product-tour" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </ButtonDynamic>
                                            <ButtonDynamic className="carousel-control-next" type="button" data-bs-target="#product-tour" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </ButtonDynamic>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*for GA progarmm manger*/}
                <div className="modal fade" tabIndex="-1" id="GAmodalStartOver" aria-labelledby="GAModalStartOver" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header border-0 p-0 justify-content-center">
                                {/* <h5 className="modal-title">WANT TO START OVER?</h5>*/}
                                <button type="button" id="btnclose" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body text-center p-0">
                                <div className="row">

                                    <div className="col-12">
                                        <div id="GA-product-tour" className="carousel carousel-dark slide" data-bs-interval="false">

                                            <div className="carousel-inner">
                                                <div className="carousel-item active" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3 ActiveSidebar">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />GA Programm Manger creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg  column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/GA/step1.gif" />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="carousel-item" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />GA Programm Manger creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3 ActiveSidebar"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg  column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/GA/step2.gif" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="carousel-item" data-bs-interval="false">
                                                    <div className="row">
                                                        <div className="col-4 col-sm-4 column">
                                                            <img src={this.state.DynamicLogoPath} />
                                                            <div className="carousel-caption d-none d-md-block">
                                                                <h5>Get Started with Xgen</h5>
                                                                <ul className="carousel-list">
                                                                    <li className="mb-3">
                                                                        <i className="fa fa-check pe-1 text-success" aria-hidden="true" />GA Programm Manger creating account using invitation link.
                                                                    </li>
                                                                    <li className="mb-3"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add new user</li>
                                                                    <li className="mb-3 ActiveSidebar"><i className="fa fa-check pe-1 text-success" aria-hidden="true" />Add Operating Account ACH information </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 col-sm-8 form-bg column">
                                                            <div>
                                                                <img src="./assets/Content/images/gif/step3.gif" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ButtonDynamic className="carousel-control-prev" type="button" data-bs-target="#GA-product-tour" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </ButtonDynamic>
                                            <ButtonDynamic className="carousel-control-next" type="button" data-bs-target="#GA-product-tour" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </ButtonDynamic>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
export default Login;