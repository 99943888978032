import { authHeader } from '../common/Common';

//Get StoreProcedure Headers
async function GetAllHeaders(Name) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/reports/GetAllHeaders?name=USP_Rpt${Name}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllBillingAccountsDetailsByInsured(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetAllBillingAccountsDetailsByInsured?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountsPending(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountsPending?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountsDue(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountsDue?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountsLate(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountsLate?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountsCancelled(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountsLate?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllCommissionPaidandDue(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetAllCommissionPaidandDue?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountsByPaymentTerm(searchMdl, SubDomainId, start, end, PaymentTerm) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountsByPaymentTerm?SubDomainId=${SubDomainId}&start=${start}&end=${end}&PaymentTerm=${PaymentTerm}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAgentMasterLists(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetAgentMasterLists?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetPaymentTermSummaryByInsuredAndAgency(searchMdl, SubDomainId, start, end, paymentterm, agentagencyid) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetPaymentTermSummaryByInsuredAndAgency?SubDomainId=${SubDomainId}&start=${start}&end=${end}&PaymentTerm=${paymentterm}&Id=${agentagencyid}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetBillingAccountDetailsbyAgent(searchMdl, SubDomainId, start, end, agentagencyid) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetBillingAccountDetailsbyAgent?SubDomainId=${SubDomainId}&start=${start}&end=${end}&Id=${agentagencyid}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function PayoutInfoByPaymentId(paymentId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/reports/PayoutInfoByPaymentId?paymentId=${paymentId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpdatePaymentStatus(updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(updateModel)
    };
    const GetResponse = await fetch(`/api/reports/PaymentStatusChange`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function UpdatePaymentAmount(updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(updateModel)
    };
    const GetResponse = await fetch(`/api/reports/PaymentAmountChange`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllPaymentHistory(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetAllPaymentHistory?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetAllPaymentLog(searchMdl, SubDomainId, start, end) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch(`/api/reports/GetAllPaymentLog?SubDomainId=${SubDomainId}&start=${start}&end=${end}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

export {
    GetAllHeaders, GetAllBillingAccountsDetailsByInsured, GetBillingAccountsPending, GetBillingAccountsDue, GetBillingAccountsLate,
    GetBillingAccountsCancelled, GetAllCommissionPaidandDue, GetBillingAccountsByPaymentTerm, GetAgentMasterLists, GetPaymentTermSummaryByInsuredAndAgency,
    GetBillingAccountDetailsbyAgent, PayoutInfoByPaymentId, UpdatePaymentStatus, UpdatePaymentAmount, GetAllPaymentHistory, GetAllPaymentLog
}