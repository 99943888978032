//enum list
enum FlowGroups  {
    XgenOwner = 1,
    SuperAdmin = 2,
    Users = 3
}
enum PaymentTypeList {
    CreditCard = 1,
    ACH = 2
};
enum PaymentAmountOptions {
    MinimumDue = 1,
    PayInFull = 2,
    OtherPaymentAmount = 3
}
enum ProductTourQuestions {
    Q1 = 1, // What are you interested in using XGen for?
    Q2 = 2, // What is the user flow of your website and how will this fit into that flow?
    Q3 = 3, // Will you need to sync up with an agency management system? If so, which management system do you use?
    Q4 = 4 // Any custom or special reporting request?
}
enum DesignLayout {
    InternalLayout = "InternalLayout",
    ExternalLayout = "ExternalLayout",
    Route = "Route"
}
enum PartnerId {
    rt = 2
}
enum Reportsfilters {
    BillingAccountsDetailsbyInsured = 1,
    BillingAccountsPending = 2,
    BillingAccountsDue = 3,
    BillingAccountsLate = 4,
    BillingAccountsCancelled = 5,
    BillingAccountyDetailsByPaymentTerm = 6,
    CommissionPaidandDue = 7,
    PaymentTermSummaryByInsuredwtAgencyId = 8,
    AgentMasterLists = 9,
    BillingAccountDetailsByAgents = 10,
    PaymentHistory = 11,
    PaymentLog = 12,
}
export { PaymentTypeList, FlowGroups, PaymentAmountOptions, DesignLayout, ProductTourQuestions, Reportsfilters, PartnerId };