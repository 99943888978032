import React, { Component } from "react";
import { withRouter } from "react-router-dom";
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};
class AuthVerify extends Component {

    constructor(props) {
        super(props);  
        props.history.listen(() => {
        
            const user = JSON.parse(localStorage.getItem("UserCredential"));

            if (user != null) {
                const decodedJwt = parseJwt(user.Token);
                var time = new Date().getTime();
                if (decodedJwt.exp > time) {
                    props.logOut();
                }
            }
        });
    }

    render() {
        return <div></div>;
    }
}

export default withRouter(AuthVerify);