import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { Logout } from '../../../common/Common';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import { SiteFooter } from "../../layout/SiteFooter";
class ApiDocs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partner: null
        }
    }

    componentDidMount() {

        var elements = [];
        const pathname = window.location.pathname;
        const hasShieldknot = pathname.includes("shieldknot");
        if (hasShieldknot) {
            this.setState({ partner: "shieldknot" });
        }
        else if (pathname.includes("rt")) {
            this.setState({ partner: "rt" });
        }
        else if (pathname.includes("geico")) {
            this.setState({ partner: "geico" });
        }
        else {
            this.setState({ partner: "rt" });
        }
        [].forEach.call(document.querySelectorAll('.scroll-to-link'), function (div) {
            div.onclick = function (e) {
                e.preventDefault();
                var target = this.dataset.target;
                document.getElementById(target).scrollIntoView({ behavior: 'smooth' });
                var elems = document.querySelectorAll(".content-menu ul li");
                [].forEach.call(elems, function (el) {
                    el.classList.remove("active");
                });
                this.classList.add("active");
                return false;
            };
        });

        //document.getElementById('button-menu-mobile').click = function (e) {
        //    e.preventDefault();
        //    document.querySelector('html').classList.toggle('menu-opened');
        //}
        //document.querySelector('.left-menu').click = function (e) {
        //    e.preventDefault();
        //    document.querySelector('html').classList.remove('menu-opened');
        //}

        $("#button-menu-mobile").off('click').on('click', ShowHideMenu);

        $("#button-menu-desktop").off('click').on('click', ShowHideDesktopMenu);

        function ShowHideMenu() {
            $('html').toggleClass("menu-opened");
        }

        function ShowHideDesktopMenu() {
            ShowHideMenu();
            $('.left-menu').toggleClass("hide");
            $('#sidebar').toggleClass('active');
        }

        function debounce(func) {
            var timer;
            return function (event) {
                if (timer) clearTimeout(timer);
                timer = setTimeout(func, 100, event);
            };
        }

        function calculElements() {
            var totalHeight = 0;
            elements = [];
            [].forEach.call(document.querySelectorAll('.content-section'), function (div) {
                var section = {};
                section.id = div.id;
                totalHeight += div.offsetHeight;
                section.maxHeight = totalHeight - 25;
                elements.push(section);
            });
            onScroll();
        }

        function onScroll() {
            var scroll = window.pageYOffset;
            for (var i = 0; i < elements.length; i++) {
                var section = elements[i];
                if (scroll <= section.maxHeight) {
                    var elems = document.querySelectorAll(".content-menu ul li");
                    [].forEach.call(elems, function (el) {
                        el.classList.remove("active");
                    });
                    var activeElems = document.querySelectorAll(".content-menu ul li[data-target='" + section.id + "']");
                    [].forEach.call(activeElems, function (el) {
                        el.classList.add("active");
                    });
                    break;
                }
            }
            if (window.innerHeight + scroll + 5 >= document.body.scrollHeight) { // end of scroll, last element
                var elems = document.querySelectorAll(".content-menu ul li");
                [].forEach.call(elems, function (el) {
                    el.classList.remove("active");
                });
                var activeElems = document.querySelectorAll(".content-menu ul li:last-child");
                [].forEach.call(activeElems, function (el) {
                    el.classList.add("active");
                });
            }
        }

        calculElements();
        window.onload = () => {
            calculElements();
        };
        window.addEventListener("resize", debounce(function (e) {
            e.preventDefault();
            calculElements();
        }));
        window.addEventListener('scroll', function (e) {
            e.preventDefault();
            onScroll();
        });
    }


    render() {
        const { partner } = this.state;
        //this is my test component 
        return (
            <>
                <meta charSet="utf-8" />
                <title>API - Documentation</title>
                <meta name="description" content="" />
                <meta name="author" content="ticlekiwi" />
                <meta httpEquiv="cleartype" content="on" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link rel="stylesheet" href="ApiDocs/css/hightlightjs-dark.css" />
                <script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.8.0/highlight.min.js"></script>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&family=Source+Code+Pro:wght@300&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="ApiDocs/css/style.css" media="all" />
                <script>hljs.initHighlightingOnLoad();</script>

                <div className="main-wrapper-newDesign w-100">
                    {/*  API Docs Header */}
                    <nav className="d-flex bg-dark navbar navbar-expand p-3 pe-4 ms-5" id="api-navbar">
                        <div>
                            <button className="burger-menu-icon-desktop" id="button-menu-desktop">
                                <svg width="34" height="34" viewBox="0 0 100 100"><path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path><path className="line line2" d="M 20,50 H 80"></path><path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path></svg>
                            </button>
                        </div>
                        <div className="header-dropdown d-flex">

                            <div className="dropdown" data-bs-popper="none">
                                <NavLink to="#" activeClassName="" id="notifications" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                </NavLink>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 dropdown-size" aria-labelledby="notifications">
                                    <div className="dropdown-menu-header text-center font-weight-bold p-2 border-bottom">
                                        4 New Notifications
                                    </div>
                                    <div className="list-group">
                                        <NavLink className="list-group-item" activeClassName="" to="#">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle text-danger"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                                </div>
                                                <div className="col-10">
                                                    <div className="text-dark">Update completed</div>
                                                    <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                                    <div className="text-muted small mt-1">30m ago</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                        <NavLink activeClassName="" to="#" className="list-group-item" title="">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell text-warning"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                                </div>
                                                <div className="col-10">
                                                    <div className="text-dark">Lorem ipsum</div>
                                                    <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                                                    <div className="text-muted small mt-1">2h ago</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                        <NavLink activeClassName="" className="list-group-item" to="#">
                                            <div className="dropdown-menu-footer text-center text-muted">
                                                Show all notifications
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown" data-bs-popper="none">
                                <NavLink activeClassName="" to="#" id="message" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square align-middle"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </NavLink>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 dropdown-size" aria-labelledby="message">
                                    <div className="dropdown-menu-header text-center font-weight-bold p-2 border-bottom">
                                        4 New Messages
                                    </div>
                                    <div className="list-group">
                                        <NavLink activeClassName="" className="list-group-item" to="#">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-alert-circle text-danger"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                                </div>
                                                <div className="col-10">
                                                    <div className="text-dark">Update completed</div>
                                                    <div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
                                                    <div className="text-muted small mt-1">30m ago</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                        <NavLink activeClassName="" to="#" className="list-group-item">
                                            <div className="row g-0 align-items-center">
                                                <div className="col-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell text-warning"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                                                </div>
                                                <div className="col-10">
                                                    <div className="text-dark">Lorem ipsum</div>
                                                    <div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                                                    <div className="text-muted small mt-1">2h ago</div>
                                                </div>
                                            </div>
                                        </NavLink>
                                        <NavLink activeClassName="" className="list-group-item" to="#">
                                            <div className="dropdown-menu-footer text-center text-muted">
                                                Show all notifications
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown" data-bs-popper="none">
                                <NavLink onClick={() => Logout()} to="#" id="Logout" aria-expanded="false">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-2">
                                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square align-middle">
                                                <path d="M2.598 9h-1.055c1.482-4.638 5.83-8 10.957-8 6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5c-5.127 0-9.475-3.362-10.957-8h1.055c1.443 4.076 5.334 7 9.902 7 5.795 0 10.5-4.705 10.5-10.5s-4.705-10.5-10.5-10.5c-4.568 0-8.459 2.923-9.902 7zm12.228 3l-4.604-3.747.666-.753 6.112 5-6.101 5-.679-.737 4.608-3.763h-14.828v-1h14.826z" /></svg>
                                        </div>
                                    </div>
                                </NavLink>
                            </div>

                        </div>


                    </nav>
                    {/* ----- APIDocs Header ----  */}
                    <div className="left-menu">
                        <div className="content-logo">
                            <div className="logo">
                                <img alt="#" title="#" src="https://xgenpayments-new-design.azurewebsites.net/assets/Content/images/xgen-logo.png" height="32" />
                                <span>API Documentation</span>
                            </div>
                            <button className="burger-menu-icon" id="button-menu-mobile">
                                <svg width="34" height="34" viewBox="0 0 100 100"><path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path><path className="line line2" d="M 20,50 H 80"></path><path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path></svg>
                            </button>
                        </div>
                        <div className="mobile-menu-closer"></div>
                        <div className="content-menu">
                            <div className="content-infos">
                                <div className="info"><b>Version:</b> 1.0.2</div>
                                <div className="info"><b>Last Updated:</b> September 12th, 2024</div>
                            </div>
                            <ul>
                                {/*<li className="scroll-to-link active" data-target="content-get-started">*/}
                                {/*    <a>GET STARTED</a>*/}
                                {/*</li>*/}
                                <li className="scroll-to-link" data-target="content-authorization">
                                    <a>AUTHORIZATION CODE</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-create-payments">
                                    <a>CREATE PAYMENT LINK</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-modify-payments">
                                    <a>MODIFY PAYMENT LINK</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-cancel-payments">
                                    <a>CANCEL PAYMENT LINK</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-cancel-policy">
                                    <a>CANCEL A POLICY</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-policy-reinstatement-request">
                                    <a>REINSTATEMENT REQUEST</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-policy-reactivate-request">
                                    <a>REACTIVATE PAYMENT LINK</a>
                                </li>
                                {/*<li className="scroll-to-link" data-target="content-reverse-payment">*/}
                                {/*    <a>REVERSE PAYMENT LINK</a>*/}
                                {/*</li>*/}
                                <li className="scroll-to-link" data-target="content-policy-return-endorsement">
                                    <a>RETURN ENDORSEMENT LINK</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-policy-additional-endorsement">
                                    <a>ADDITIONAL ENDORSEMENT LINK</a>
                                </li>
                                {/*<li className="scroll-to-link" data-target="content-renewal-payments">*/}
                                {/*    <a>RENEWAL PAYMENT LINK</a>*/}
                                {/*</li>*/}
                                <li className="scroll-to-link" data-target="content-get-account-details-paymentid">
                                    <a>GET ACCOUNT DETAILS BY PAYMENT ID</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-get-account-details-email">
                                    <a>GET ACCOUNT DETAILS BY EMAIL</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-get-agent-details">
                                    <a>GET AGENT DETAILS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-get-agent-account-details">
                                    <a>GET AGENT ACCOUNT DETAILS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-get-past-due">
                                    <a>GET PAST DUE ACCOUNTS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-authentication">
                                    <a>AUTHENTICATION</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-roles">
                                    <a>GET ROLES</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-createuser">
                                    <a>CREATE USER</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-get-user-list">
                                    <a>USER LIST</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-account-setup">
                                    <a>ACCOUNT SETUP</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-commission-setup">
                                    <a>COMMISSION SETUP</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-update-commission-setup">
                                    <a>UPDATE COMMISSION SETUP</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-commission-list">
                                    <a>GET COMMISSION LIST</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-transaction-list">
                                    <a>TRANSACTION LIST</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-approve-transaction">
                                    <a>APPROVE TRANSACTION</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-account-list">
                                    <a>ACCOUNT LIST</a>
                                </li>

                                <li className="scroll-to-link" data-target="content-delete-customer-details">
                                    <a>DELETE CUSTOMER DETAIL</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-delete-transaction">
                                    <a>DELETE TRANSACTION</a>
                                </li>

                                <li className="report-header">
                                    <span className="report-header-span"> REPORTS</span>
                                </li>

                                <li className="scroll-to-link" data-target="content-get-headers">
                                    <a>GET ALL HEADERS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-billing-account-by-insured">
                                    <a>ALL BILLING ACCOUNTS BY INSURED</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-pending-billing">
                                    <a>PENDING BILLING ACCOUNTS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-due-billing">
                                    <a>DUE BILLING ACCOUNTS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-late-billing">
                                    <a>LATE BILLING ACCOUNTS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-cancelled-billing">
                                    <a>CANCELLED BILLING ACCOUNTS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-commission-paid-due">
                                    <a>GET ALL COMMISSION PAID AND DUE </a>
                                </li>
                                <li className="scroll-to-link" data-target="content-payout-info">
                                    <a>PAYOUT INFO BY CONFIRMATION NUMBER</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-payment-term">
                                    <a>BILLING ACCOUNT BY PAYMENT TERM</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-agent-master">
                                    <a>GET AGENT MASTER LIST</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-term-summary">
                                    <a>PAYMENT TERMS SUMMARY BY INSURED AND AGENCY</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-detail-by-agent">
                                    <a>BILLING ACCOUNT DETAILS BY AGENT</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-all-report-users">
                                    <a>GET ALL REPORT USERS</a>
                                </li>
                                <li className="scroll-to-link" data-target="content-all-payment-logs">
                                    <a>GET ALL PAYMENT LOGS</a>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="content-page">
                        <div className="content-code"></div>
                        <div className="content">
                            {/*<div className="overflow-hidden content-section" id="content-get-started">*/}
                            {/*    <h1>Get started</h1>*/}
                            {/*    <pre>*/}
                            {/*        API Endpoint*/}

                            {/*        https://xgenpaymentapiwebapp-new-design.azurewebsites.net/*/}
                            {/*    </pre>*/}
                            {/*    <p>The XGen API gives you the ability to create payment links and obtain reporting data to integrate into your systems.</p>*/}
                            {/*    <p>To use this API, you need an <strong>API key</strong>. Please contact us at <a href="mailto:jerry@spectertech.io">jerry@spectertech.io</a> to get your own API key.</p>*/}
                            {/*</div>*/}
                            <div className="overflow-hidden content-section" id="content-authorization">
                                <h1>Authorization Code</h1>
                                <p>Please provide the below Authorization Code and pass it in the Headers of Postman to access the APIs.</p>
                                <pre>
                                    KEY: Authorization
                                    <br />
                                    <br />
                                    {partner == "rt" &&
                                    <>
                                        VALUE: Basic demo_UqM1J5YzMXiOPD9s21RUIC74asdsa4ASadmRlGG_g5dLnwx5FzzRDmG2wiXM
                                    </>
                                    }
                                    {partner == "shieldknot" &&
                                        <>
                                        VALUE: Basic demo_UqM1J5YzMXiOPD9daiul21RUIC74asdsa4ASadmRlGG_g5dLnwftyezFzzRDmG2wiXM
                                        </>
                                    }
                                    {partner == "geico" &&
                                        <>
                                        VALUE: Basic demo_UqM1J5YzMXiOPD9s2fhguRUIC74asdsa4ASdymRlGG_g5dLnwx5FzzRDmG2yoibXM
                                    </>
                                    }
                                </pre>

                            </div>
                            <div className="overflow-hidden content-section" id="content-create-payments">
                                <h2>create a payment link</h2>
                                <pre><code className="bash">
                                    # URL
                                    POST https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/linkcreate
                                </code></pre>
                                <p>The core of the xGen system is creating payment links for the insured to pay. After the payment is completed the insured will have the option to set up an account.</p>
                                <p>You can use the InsuredId in the response to look up data for this insured in later API calls (if desired).</p>
                                <br />
                                <pre><code className="json">
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    {partner != "shieldknot" &&
                                        <>
                                        "AgentID": "7c0df437-390f-496a-b545-9bbbf3c03d77",
                                        <br />
                                        </>
                                    }
                                    {partner == "shieldknot" &&
                                        <>
                                        "AgentID": "f2929ddf-88a3-4a00-a9f7-76f15708f4cc",
                                        <br />
                                        </>
                                    }
                                    {/*<br />*/}
                                    {/*"PolicyNumber":  "A123456-00",*/}
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "PolicyPremium": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "GarageKeep"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "XProp"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"QuoteFlag": true,*/}
                                    {/*<br />*/}
                                    {/*"DownPayment": 5000.00,*/}
                                    {/*<br />*/}
                                    {/*"monthlyAmount": 2800.00,*/}
                                    {/*<br />*/}
                                    {/*"TermLength": 6,*/}
                                    {/*<br />*/}
                                    {/*"APR": 0.00,*/}
                                    {/*<br />*/}
                                    {/*"FinanceCharge": 150.20,*/}
                                    <br />
                                    "DueDate": "2024-03-15",
                                    <br />
                                    "PhoneNumber": "(354) 354-5354",
                                    <br />
                                    "ExpirationDate": "2024-03-25",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    {/*<br />*/}
                                    {/*"ServiceFee": 15.00,*/}
                                    <br />
                                    "PolicyEffectiveDate": "2024-03-15",
                                    <br />
                                    {/*"InsuranceCompany": "TestInsurance",*/}
                                    {/*<br />*/}
                                    "Address": "231, CapeTown",
                                    <br />
                                    "AddressLine2": "Spring St.",
                                    <br />
                                    "AddressLine3": "Los Angles",
                                    <br />
                                    "City" : "Reno",
                                    <br />
                                    "State" : "CA",
                                    <br />
                                    "Zip": "85215",
                                    <br />
                                    {/*"AISCompanyCode": "4Y44",*/}
                                    {/*<br />*/}
                                    {console.log("partner",partner)}
                                    {partner == "rt" &&
                                        <>
                                            "CoverageType": "18",
                                            <br />
                                            "QuoteId": "GLFX001",
                                            <br />
                                            "ContractId": "CFX001",
                                            <br />
                                            "ProcId": "PFX001",
                                            <br />
                                            "EntityId": "EID001",
                                            <br />
                                            "ContractSetId": "CSID001",
                                            <br />
                                            "PrinBrokEntityId": "PBEID001",
                                            <br />
                                            "BookEntityId": "BID001",
                                            <br />
                                            "BookLocId": "BLID001",
                                            <br />
                                            "LineCode": "LC001",
                                            <br />
                                            "ExpirationDatePolicy": "2024-03-20",
                                            <br />
                                            "Term": 5,
                                            <br />
                                            "ContractState": "CA",
                                            <br />
                                            "BillToRoleType": "BRT001",
                                            <br />
                                            "BillToEntity": "BE01",
                                            <br />
                                            "ReceivableRole": "RR001",
                                            <br />
                                            "ContractLineId": "CLID001",
                                            <br />
                                            "AgencyId": "8011888",
                                            <br />
                                            "CarrierId": "69936105",
                                            <br />
                                            "AgencyName": "RT Insurance Agency Corp",
                                            <br />
                                            "ContractNum": "CUSNUM001"
                                            <br />
                                            "AgencyAffiliateId": 9224223,
                                            <br />
                                            "AgencyAffiliateName": "Jessica Bernal",
                                            <br />
                                        </>
                                    }
                                    "PolicyTaxesAndFees": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "AAA"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "BBB"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "insuredId": "CH993W",
                                    <br />
                                    "paymentId": "12197979",
                                    <br />
                                    "paymentLink": "https://app-staging.xgenbilling.com/make-payment?PaymentId=12197979"
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}


                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>InsuredName</td>
                                            <td>String</td>
                                            <td>(Required) The name of the person paying the bill, the named insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>(Required) The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>PolicyNumber</td>*/}
                                        {/*    <td>String</td>*/}
                                        {/*    <td>(Required) The policy number for the policy the insured is paying on. </td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>EmailAddress</td>
                                            <td>String</td>
                                            <td>(Required) The email address that the payment link will be sent to.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyPremium</td>
                                            <td>Object Array</td>
                                            <td>(Required) An array of objects representing the policy premium amount. Each object contains Tran_Code, Written_Premium, and Line_Code.</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>QuoteFlag</td>*/}
                                        {/*    <td>Boolean</td>*/}
                                        {/*    <td>If "QuoteFlag" set to "Y"=Yes, system will calculate the values for calculated fields. Whatever values passed in for calculated fields will be accepted. (Valid Values: "Y"=Yes, or "N"/Blank=No).</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>DownPayment</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>(Required if "QuoteFlag" is set to No) The amount to be collected on the first payment.</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>MonthlyAmount</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>(Required if "QuoteFlag" is set to No) The amount to be collected on the subsequent payments for the remainder of the term.</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>TermLength</td>*/}
                                        {/*    <td>Integer</td>*/}
                                        {/*    <td>(Required if "QuoteFlag" is set to No) The term length of the policy. Sending 6 means there will be one down payment and then 5 automatic payments afterwards</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>APR</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>(Required if "QuoteFlag" is set to No) Anual Percentage Rate from the rate chart or a special rate used</td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>Finance Charge</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>(Required if "QuoteFlag" is set to No) Amount financed (amountFinanced = totalPremiumAmount - totalDownpayAmount).</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>DueDate</td>
                                            <td>Date</td>
                                            <td>(Required) The due date of the payment and the subsequent date for autopayments. Send in yyyy-mm-dd format.</td>
                                        </tr>
                                        <tr>
                                            <td>PhoneNumber</td>
                                            <td>String</td>
                                            <td>The phone number for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>ExpirationDate</td>
                                            <td>Date</td>
                                            <td>(Required) The date the link expires. Send in yyyy-mm-dd format. If blank, the default of 72 hours will be used.</td>
                                        </tr>
                                        <tr>
                                            <td>InvoiceNumber</td>
                                            <td>String</td>
                                            <td>An invoice number or submission number for the payment, to display for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyStatus</td>
                                            <td>String</td>
                                            <td>The status of the policy (i.e., Active, Lapsed)</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>Service Fee</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>(Required) Service Fee Finance charge to be billed to the Insured</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>Policy Effective Date</td>
                                            <td>Date</td>
                                            <td>(Required) The date which the current policy enters into force. Send in yyyy-mm-dd format</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>Insurance Company</td>*/}
                                        {/*    <td>String</td>*/}
                                        {/*    <td>Insured that belongs to Insurance Company</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>Address</td>
                                            <td>String</td>
                                            <td>(Required) Address that belongs to Insured</td>
                                        </tr>
                                        {partner != "shieldknot" &&
                                            <>
                                            <tr>
                                                <td>AddressLine2</td>
                                                <td>String</td>
                                                <td>Address Line 2 that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>AddressLine3</td>
                                                <td>String</td>
                                                <td>Address Line 3 that belongs to Insured</td>
                                            </tr>
                                            </>
                                        }
                                        <tr>
                                            <td>City</td>
                                            <td>String</td>
                                            <td>(Required) City that belongs to Insured</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>String</td>
                                            <td>(Required) State that belongs to Insured</td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>String</td>
                                            <td>(Required) Zip that belongs to Insured</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>AISCompanyCode</td>*/}
                                        {/*    <td>String</td>*/}
                                        {/*    <td>(Required) This is the Policy Insurance Number</td>*/}
                                        {/*</tr>*/}
                                        {partner == "rt" &&
                                            <>
                                            <tr>
                                                <td>Coverage Type</td>
                                                <td>String</td>
                                                <td>(Required) Coverage Type of the policy</td>
                                            </tr>
                                            <tr>
                                                <td>QuoteId</td>
                                                <td>String</td>
                                                <td>QuoteId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>ContractId</td>
                                                <td>String</td>
                                                <td>(Required) ContractId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>ProcId</td>
                                                <td>String</td>
                                                <td>(Required) ProcId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>EntityId</td>
                                                <td>String</td>
                                                <td>EntityId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>ContractSetId</td>
                                                <td>String</td>
                                                <td>ContractSetId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>PrinBrokEntityId</td>
                                                <td>String</td>
                                                <td>ProcId of the Policy</td>
                                            </tr>
                                            <tr>
                                                <td>BookEntityId</td>
                                                <td>String</td>
                                                <td>(Required) Book_Entity_Id that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>BookLocId</td>
                                                <td>String</td>
                                                <td>(Required) Book_Loc_Id that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>LineCode</td>
                                                <td>String</td>
                                                <td>(Required) Line_code that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ExpirationDatePolicy</td>
                                                <td>String</td>
                                                <td>(Required) Expiration_Date_Policy that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>Term</td>
                                                <td>String</td>
                                                <td>(Required) Term that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ContractState</td>
                                                <td>String</td>
                                                <td>(Required) Contract_State that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>BillToRoleType</td>
                                                <td>String</td>
                                                <td>(Required) Bill_to_Role_Type that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>BillToEntity</td>
                                                <td>String</td>
                                                <td>(Required) Bill_to_Entity that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ReceivableRole</td>
                                                <td>String</td>
                                                <td>(Required) Receivable_Role that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ContractNum</td>
                                                <td>String</td>
                                                <td>(Required) Contract_Num that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ContractLineId</td>
                                                <td>String</td>
                                                <td>(Required) ContractLineId that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>AgencyId</td>
                                                <td>String</td>
                                                <td>AgencyId that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>CarrierId</td>
                                                <td>String</td>
                                                <td>CarrierId that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>AgencyName</td>
                                                <td>String</td>
                                                <td>AgencyName that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>AgencyAffiliateId</td>
                                                <td>Integer </td>
                                                <td>AgencyAffiliateId that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>AgencyAffiliateName</td>
                                                <td>String</td>
                                                <td>AgencyAffiliateName that belongs to Insured</td>
                                            </tr>
                                            <tr>
                                                <td>ContractNum</td>
                                                <td>String</td>
                                                <td>ContractNum that belongs to Insured</td>
                                            </tr>
                                            </>
                                        }
                                        <tr>
                                            <td>PolicyTaxesAndFees</td>
                                            <td>Object Array</td>
                                            <td>An array of objects representing policy taxes and fees. Each object contains Type, Description, Amount, and Line_Code.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-modify-payments">
                                <h2>modify a payment link</h2>
                                <pre><code className="bash">
                                    # URL
                                    POST https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/linkmodify
                                </code></pre>
                                <p>Almost an identical call to Create a link, except every field is optional now. This is just to change any data related to the link itself.</p>
                                <br />
                                <pre><code className="json">
                                    {'{'}
                                    <br />
                                    "PaymentId": "TKD0LZ",
                                    <br />
                                    "InsuredName": "1",
                                    <br />
                                    {partner != "shieldknot" &&
                                        <>
                                            "AgentID": "7c0df437-390f-496a-b545-9bbbf3c03d77",
                                            <br />
                                        </>
                                    }
                                    {partner == "shieldknot" &&
                                        <>
                                            "AgentID": "f2929ddf-88a3-4a00-a9f7-76f15708f4cc",
                                            <br />
                                        </>
                                    }
                                    "EmailAddress": "1@1.com",
                                    <br />
                                    "PolicyPremium": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "GarageKeep"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "XProp"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"DownPayment": 500.00,*/}
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "ExpirationDate" : "2022-01-01",
                                    <br />
                                    "InvoiceNumber": "1",
                                    <br />
                                    "PolicyStatus":"Active",
                                    <br />
                                    "Address": "231, CapeTown",
                                    <br />
                                    "City" : "Reno",
                                    <br />
                                    "State" : "CA",
                                    <br />
                                    "Zip": "85215"
                                    <br />
                                    {partner == "rt" &&
                                        <>
                                            "CoverageType": "18",
                                            <br />
                                            "QuoteId": "GLFX001",
                                            <br />
                                            "ProcId": "PFX001",
                                            <br />
                                            "EntityId": "EID001",
                                            <br />
                                            "ContractSetId": "CSID001",
                                            <br />
                                            "PrinBrokEntityId": "PBEID001",
                                            <br />
                                            "BookEntityId": "BID001",
                                            <br />
                                            "BookLocId": "BLID001",
                                            <br />
                                            "LineCode": "LC001",
                                            <br />
                                            "ExpirationDatePolicy": "2024-03-20",
                                            <br />
                                            "Term": 5,
                                            <br />
                                            "ContractState": "CA",
                                            <br />
                                            "BillToRoleType": "BRT001",
                                            <br />
                                            "BillToEntity": "BE01",
                                            <br />
                                            "ReceivableRole": "RR001",
                                            <br />
                                            "ContractLineId": "CLID001",
                                            <br />
                                            "AgencyId": "8011888",
                                            <br />
                                            "CarrierId": "69936105",
                                            <br />
                                            "AgencyName": "RT Insurance Agency Corp",
                                            <br />
                                            "ContractNum": "CUSNUM001"
                                            <br />
                                            "AgencyAffiliateId": 9224223,
                                            <br />
                                            "AgencyAffiliateName": "Jessica Bernal",
                                            <br />
                                        </>
                                    }
                                    "PolicyTaxesAndFees": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "AAA"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "BBB"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}

                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "insuredId": "CH993W",
                                    <br />
                                    "paymentId": "12197979",
                                    <br />
                                    "paymentLink": "https://app-staging.xgenbilling.com/make-payment?PaymentId=12197979"
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}


                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>(Required) The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                        <tr>
                                            <td>InsuredName</td>
                                            <td>String</td>
                                            <td>The name of the person paying the bill, the named insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>(Required) The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>EmailAddress</td>
                                            <td>String</td>
                                            <td>The email address that the payment link will be sent to.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyPremium</td>
                                            <td>Object Array</td>
                                            <td>(Required) An array of objects representing the policy premium amount. Each object contains Tran_Code, Written_Premium, and Line_Code.</td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>DownPayment</td>*/}
                                        {/*    <td>Decimal</td>*/}
                                        {/*    <td>The amount to be collected on the first payment.</td>*/}
                                        {/*</tr>*/}
                                        <tr>
                                            <td>PhoneNumber</td>
                                            <td>String</td>
                                            <td>The phone number for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>ExpirationDate</td>
                                            <td>Date</td>
                                            <td>The date the link expires. Send in yyyy-mm-dd format. If blank, the default of 72 hours will be used.</td>
                                        </tr>
                                        <tr>
                                            <td>InvoiceNumber</td>
                                            <td>String</td>
                                            <td>an invoice number or submission number for the payment, to display for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyStatus</td>
                                            <td>String</td>
                                            <td>the status of the policy (i.e., Active, Lapsed)</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>String</td>
                                            <td>Address that belongs to Insured</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>String</td>
                                            <td>City that belongs to Insured</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>String</td>
                                            <td>State that belongs to Insured</td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>String</td>
                                            <td>Zip that belongs to Insured</td>
                                        </tr>
                                        {partner == "rt" &&
                                            <>
                                                <tr>
                                                    <td>Coverage Type</td>
                                                    <td>String</td>
                                                    <td>Coverage Type of the policy</td>
                                                </tr>
                                                <tr>
                                                    <td>QuoteId</td>
                                                    <td>String</td>
                                                    <td>QuoteId of the Policy</td>
                                                </tr>
                                                <tr>
                                                    <td>ProcId</td>
                                                    <td>String</td>
                                                    <td>ProcId of the Policy</td>
                                                </tr>
                                                <tr>
                                                    <td>EntityId</td>
                                                    <td>String</td>
                                                    <td>EntityId of the Policy</td>
                                                </tr>
                                                <tr>
                                                    <td>ContractSetId</td>
                                                    <td>String</td>
                                                    <td>ContractSetId of the Policy</td>
                                                </tr>
                                                <tr>
                                                    <td>PrinBrokEntityId</td>
                                                    <td>String</td>
                                                    <td>ProcId of the Policy</td>
                                                </tr>
                                                <tr>
                                                    <td>BookEntityId</td>
                                                    <td>String</td>
                                                    <td>Book_Entity_Id that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>BookLocId</td>
                                                    <td>String</td>
                                                    <td>Book_Loc_Id that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>LineCode</td>
                                                    <td>String</td>
                                                    <td> Line_code that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ExpirationDatePolicy</td>
                                                    <td>String</td>
                                                    <td> Expiration_Date_Policy that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>Term</td>
                                                    <td>String</td>
                                                    <td> Term that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ContractState</td>
                                                    <td>String</td>
                                                    <td> Contract_State that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>BillToRoleType</td>
                                                    <td>String</td>
                                                    <td> Bill_to_Role_Type that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>BillToEntity</td>
                                                    <td>String</td>
                                                    <td> Bill_to_Entity that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ReceivableRole</td>
                                                    <td>String</td>
                                                    <td> Receivable_Role that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ContractNum</td>
                                                    <td>String</td>
                                                    <td>Contract_Num that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ContractLineId</td>
                                                    <td>String</td>
                                                    <td>ContractLineId that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>AgencyId</td>
                                                    <td>String</td>
                                                    <td>AgencyId that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>CarrierId</td>
                                                    <td>String</td>
                                                    <td>CarrierId that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>AgencyName</td>
                                                    <td>String</td>
                                                    <td>AgencyName that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>AgencyAffiliateId</td>
                                                    <td>Integer </td>
                                                    <td>AgencyAffiliateId that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>AgencyAffiliateName</td>
                                                    <td>String</td>
                                                    <td>AgencyAffiliateName that belongs to Insured</td>
                                                </tr>
                                                <tr>
                                                    <td>ContractNum</td>
                                                    <td>String</td>
                                                    <td>ContractNum that belongs to Insured</td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td>PolicyTaxesAndFees</td>
                                            <td>Object Array</td>
                                            <td>An array of objects representing policy taxes and fees. Each object contains Type, Description, Amount, and Line_Code.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-cancel-policy">
                                <h2>Cancel a Policy.</h2>
                                <pre><code className="bash">
                                    # URL
                                        POST https://api-staging.xgenbilling.com/api/payment/policycancel?PaymentId=18981832
                                </code></pre>
                                <p>Cancel a Policy.</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "responseReference": "02100190048",
                                    <br />
                                    "processingStatus": "ACCEPTED",
                                    <br />
                                    "returnCode": "0000",
                                    <br />
                                    "returnMessage": "",
                                    <br />
                                    "processedDateTime": "2024-09-11T03:14:31",
                                    <br />
                                    "processedDate": "09112024",
                                    <br />
                                    "processedTime": "03143191",
                                    <br />
                                    "premiumFinanceCompanyCode": null,
                                    <br />
                                    "billingProgramCompanyCode": "995",
                                    <br />
                                    "agentCode": "550001",
                                    <br />
                                    "agentCodeCrossReference": "",
                                    <br />
                                    "insuredId": "LSFLN2",
                                    <br />
                                    "aisAccountNumber": "00905326",
                                    <br />
                                    "insuredName1": "John Smith",
                                    <br />
                                    "totalAccountBalanceAmount": "6000.00",
                                    <br />
                                    "paymentTerm": "10",
                                    <br />
                                    "paymentAmount": "500.00",
                                    <br />
                                    "nextPaymentDueDate": "08/30/2024",
                                    <br />
                                    "nextPaymentDueAmount": "500.00",
                                    <br />
                                    "isPremiumFinance": false,
                                    <br />
                                    "isDirectBilling": false
                                    <br />
                                    "PolicyTaxesAndFees": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "AAA"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "BBB"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}



                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-cancel-payments">
                                <h2>Cancel a payment link.</h2>
                                <pre><code className="bash">
                                    # URL
                                        POST https://api-staging.xgenbilling.com/api/payment/linkcancel?PaymentId=18981832
                                </code></pre>
                                <p>Cancel a payment link.</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                        "status": true,
                                    <br />
                                        "data": "Policy link canceled successfully."
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/*<div className="overflow-hidden content-section" id="content-reactivate-payments">*/}
                            {/*    <h2>Reactivate a payment link.</h2>*/}
                            {/*    <pre><code className="bash">*/}
                            {/*        # URL*/}
                            {/*        POST https://api-staging.xgenbilling.com/api/payment/linkreactivate?PaymentId=18981832*/}
                            {/*    </code></pre>*/}
                            {/*    <p>Reactivate a payment link.</p>*/}
                            {/*    <br />*/}
                            {/*    <pre><code className="json">*/}
                            {/*        <br />*/}
                            {/*        <br />*/}
                                    {/*Response example :*/}
                                    {/*<br />*/}
                                    {/*{'{'}*/}
                                    {/*<br />*/}
                                    {/*"status": true,*/}
                                    {/*<br />*/}
                                    {/*"data": {'{'}*/}
                                    {/*<br />*/}
                                    {/*"responseReference": "02100190049",*/}
                                    {/*<br />*/}
                                    {/*"processingStatus": "ACCEPTED",*/}
                                    {/*<br />*/}
                                    {/*"returnCode": "0000",*/}
                                    {/*<br />*/}
                                    {/*"returnMessage": "",*/}
                                    {/*<br />*/}
                                    {/*"processedDateTime": "2024-09-11T03:19:56",*/}
                                    {/*<br />*/}
                                    {/*"processedDate": "09112024",*/}
                                    {/*<br />*/}
                                    {/*"processedTime": "03195683",*/}
                                    {/*<br />*/}
                                    {/*"premiumFinanceCompanyCode": null,*/}
                                    {/*<br />*/}
                                    {/*"billingProgramCompanyCode": "995",*/}
                                    {/*<br />*/}
                                    {/*"agentCode": "550001",*/}
                                    {/*<br />*/}
                                    {/*"agentCodeCrossReference": "",*/}
                                    {/*<br />*/}
                                    {/*"insuredId": "LSFLN2",*/}
                                    {/*<br />*/}
                                    {/*"aisAccountNumber": "00905326",*/}
                                    {/*<br />*/}
                                    {/*"insuredName1": "John Smith",*/}
                                    {/*<br />*/}
                                    {/*"totalAccountBalanceAmount": "6000.00",*/}
                                    {/*<br />*/}
                                    {/*"paymentTerm": "10",*/}
                                    {/*<br />*/}
                                    {/*"paymentAmount": "500.00",*/}
                                    {/*<br />*/}
                                    {/*"nextPaymentDueDate": "08/30/2024",*/}
                                    {/*<br />*/}
                                    {/*"nextPaymentDueAmount": "500.00",*/}
                                    {/*<br />*/}
                                    {/*"isPremiumFinance": false,*/}
                                    {/*<br />*/}
                                    {/*"isDirectBilling": false*/}
                                    {/*<br />*/}
                                    {/*{'}'}*/}
                                    {/*<br />*/}
                                    {/*{'}'}*/}



                            {/*    </code></pre>*/}
                            {/*    <h4>QUERY PARAMETERS</h4>*/}
                            {/*    <table className="central-overflow-x">*/}
                            {/*        <thead>*/}
                            {/*            <tr>*/}
                            {/*                <th>Field</th>*/}
                            {/*                <th>Type</th>*/}
                            {/*                <th>Description</th>*/}
                            {/*            </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*            <tr>*/}
                            {/*                <td>PaymentId</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>The ID of the payment generated in the Create Payment Link step.</td>*/}
                            {/*            </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}
                            <div className="overflow-hidden content-section" id="content-policy-reinstatement-request">
                                <h2>Reinstatement a payment link.</h2>
                                <pre><code className="bash">
                                    # URL
                                    {partner == "rt" &&
                                        <>
                                        POST https://api-staging.xgenbilling.com/api/payment/linkreinstatement?ContractId=18981832
                                        </>
                                    }
                                    {partner != "rt" &&
                                        <>
                                        POST https://api-staging.xgenbilling.com/api/payment/linkreinstatement?PaymentId=18981832
                                        </>
                                    }
                                </code></pre>
                                <p>Reinstatement a payment link.</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "responseReference": "02100190049",
                                    <br />
                                    "processingStatus": "ACCEPTED",
                                    <br />
                                    "returnCode": "0000",
                                    <br />
                                    "returnMessage": "",
                                    <br />
                                    "processedDateTime": "2024-09-11T03:19:56",
                                    <br />
                                    "processedDate": "09112024",
                                    <br />
                                    "processedTime": "03195683",
                                    <br />
                                    "premiumFinanceCompanyCode": null,
                                    <br />
                                    "billingProgramCompanyCode": "995",
                                    <br />
                                    "agentCode": "550001",
                                    <br />
                                    "agentCodeCrossReference": "",
                                    <br />
                                    "insuredId": "LSFLN2",
                                    <br />
                                    "aisAccountNumber": "00905326",
                                    <br />
                                    "insuredName1": "John Smith",
                                    <br />
                                    "totalAccountBalanceAmount": "6000.00",
                                    <br />
                                    "paymentTerm": "10",
                                    <br />
                                    "paymentAmount": "500.00",
                                    <br />
                                    "nextPaymentDueDate": "08/30/2024",
                                    <br />
                                    "nextPaymentDueAmount": "500.00",
                                    <br />
                                    "isPremiumFinance": false,
                                    <br />
                                    "isDirectBilling": false
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {partner == "rt" && <> <td>ContractId</td> </>}
                                            {partner != "rt" && <> <td>PaymentId</td> </>}
                                            <td>String</td>
                                            <td>The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-policy-reactivate-request">
                                <h2>Reactivate a payment link.</h2>
                                <pre><code className="bash">
                                    # URL
                                    POST https://api-staging.xgenbilling.com/api/payment/linkreactivate?PaymentId=18981832
                                </code></pre>
                                <p>Reactivate a payment link.</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": "Policy link reactivate successfully."
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/*<div className="overflow-hidden content-section" id="content-reverse-payment">*/}
                            {/*    <h2>Reverse a payment link.</h2>*/}
                            {/*    <pre><code className="bash">*/}
                            {/*        # URL*/}
                            {/*        POST https://api-staging.xgenbilling.com/api/payment/linkreverse?PaymentId=ZFG1TD*/}
                            {/*    </code></pre>*/}
                            {/*    <p>Reverse a payment link.</p>*/}
                            {/*    <br />*/}
                            {/*    <pre><code className="json">*/}
                            {/*        <br />*/}
                            {/*        <br />*/}
                            {/*        Response example :*/}
                            {/*        <br />*/}
                            {/*        <br />*/}
                            {/*        {'{'}*/}
                            {/*        <br />*/}
                            {/*        "Success": True*/}
                            {/*        <br />*/}
                            {/*        {'}'}*/}
                            {/*    </code></pre>*/}
                            {/*    <h4>QUERY PARAMETERS</h4>*/}
                            {/*    <table className="central-overflow-x">*/}
                            {/*        <thead>*/}
                            {/*            <tr>*/}
                            {/*                <th>Field</th>*/}
                            {/*                <th>Type</th>*/}
                            {/*                <th>Description</th>*/}
                            {/*            </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*            <tr>*/}
                            {/*                <td>PaymentId</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>The ID of the payment generated in the Create Payment Link step.</td>*/}
                            {/*            </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}
                            <div className="overflow-hidden content-section" id="content-policy-return-endorsement">
                                <h2>Policy Return Endorsement</h2>
                                <pre><code className="bash">
                                    # URL
                                    POST https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/policy/endorsement
                                </code></pre>
                                <p>After the payment is completed used to decrease the premium amount. The fields are described below:</p>
                                <br />
                                <pre><code className="json">
                                    {'{'}
                                    <br />
                                    "PaymentId": "0PS1FL",
                                    <br />
                                    "InsuredName": "1",
                                    <br />
                                    "AgentID": "7c0df437-390f-496a-b545-9bbbf3c03d77",
                                    <br />
                                    "EmailAddress": "a@aa.com",
                                    <br />
                                    "PolicyReturnAmount": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "GarageKeep"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"TranCode": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"WrittenPremium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "XProp"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ],
                                    <br />
                                    "PhoneNumber": "7182222222",
                                    <br />
                                    "ExpirationDate": "2024-07-25",
                                    <br />
                                    "InvoiceNumber": "1",
                                    <br />
                                    "PolicyStatus": "Active",
                                    <br />
                                    "Address": "231, CapeTown",
                                    <br />
                                    "City": "Reno",
                                    <br />
                                    "State": "CA",
                                    <br />
                                    "Zip": "85215",
                                    <br />
                                    "AgencyAffiliateName": "1",
                                    <br />
                                    "PolicyTaxesAndFees": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "AAA"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"LineCode": "BBB"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}

                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "insuredId": "CH993W",
                                    <br />
                                    "paymentId": "12197979",
                                    <br />
                                    "paymentLink": "https://app-staging.xgenbilling.com/make-payment?PaymentId=12197979"
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>(Required) The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                        <tr>
                                            <td>InsuredName</td>
                                            <td>String</td>
                                            <td>The name of the person paying the bill, the named insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>(Required) The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>EmailAddress</td>
                                            <td>String</td>
                                            <td>The email address that the payment link will be sent to.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyReturnAmount</td>
                                            <td>Object Array</td>
                                            <td>(Required) An array of objects representing the amounts to be returned for each line of coverage. Each object contains Tran_Code, Written_Premium, and Line_Code.</td>
                                        </tr>
                                        <tr>
                                            <td>PhoneNumber</td>
                                            <td>String</td>
                                            <td>The phone number for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>ExpirationDate</td>
                                            <td>Date</td>
                                            <td>The date the link expires. Send in yyyy-mm-dd format.</td>
                                        </tr>
                                        <tr>
                                            <td>InvoiceNumber</td>
                                            <td>String</td>
                                            <td>An invoice number or submission number for the payment, to display for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyStatus</td>
                                            <td>String</td>
                                            <td>The status of the policy (e.g., Active, Lapsed).</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>String</td>
                                            <td>Address of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>String</td>
                                            <td>City of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>String</td>
                                            <td>State of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>String</td>
                                            <td>Zip code of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgencyAffiliateName</td>
                                            <td>String</td>
                                            <td>Name of the agency affiliate.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyTaxesAndFees</td>
                                            <td>Object Array</td>
                                            <td>An array of objects representing policy taxes and fees. Each object contains Type, Description, Amount, and Line_Code.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-policy-additional-endorsement">
                                <h2>Policy Additional Endorsement</h2>
                                <pre><code className="bash">
                                    # URL
                                    POST https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/policy/endorsement
                                </code></pre>
                                <p>After the payment is completed used to increase the premium amount. The fields are described below:</p>
                                <br />
                                <pre><code className="json">
                                    {'{'}
                                    <br />
                                    "PaymentId": "0PS1FL",
                                    <br />
                                    "InsuredName": "1",
                                    <br />
                                    "AgentID": "7c0df437-390f-496a-b545-9bbbf3c03d77",
                                    <br />
                                    "EmailAddress": "a@aa.com",
                                    <br />
                                    "PolicyAdditionalAmount": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Tran_Code": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Written_Premium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "GarageKeep"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Tran_Code": "Premium",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Written_Premium": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "XProp"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ],
                                    <br />
                                    "PhoneNumber": "7182222222",
                                    <br />
                                    "ExpirationDate": "2024-07-25",
                                    <br />
                                    "InvoiceNumber": "1",
                                    <br />
                                    "PolicyStatus": "Active",
                                    <br />
                                    "Address": "231, CapeTown",
                                    <br />
                                    "City": "Reno",
                                    <br />
                                    "State": "CA",
                                    <br />
                                    "Zip": "85215",
                                    <br />
                                    "AgencyAffiliateName": "1",
                                    <br />
                                    "PolicyTaxesAndFees": [
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "AAA"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'},
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'{'}
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "BBB"
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}

                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "insuredId": "CH993W",
                                    <br />
                                    "paymentId": "12197979",
                                    <br />
                                    "paymentLink": "https://app-staging.xgenbilling.com/make-payment?PaymentId=12197979"
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>(Required) The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                        <tr>
                                            <td>InsuredName</td>
                                            <td>String</td>
                                            <td>The name of the person paying the bill, the named insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>(Required) The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>EmailAddress</td>
                                            <td>String</td>
                                            <td>The email address that the payment link will be sent to.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyAdditionalAmount</td>
                                            <td>Object Array</td>
                                            <td>(Required) An array of objects representing the amounts to be additional for each line of coverage. Each object contains Tran_Code, Written_Premium, and Line_Code.</td>
                                        </tr>
                                        <tr>
                                            <td>PhoneNumber</td>
                                            <td>String</td>
                                            <td>The phone number for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>ExpirationDate</td>
                                            <td>Date</td>
                                            <td>The date the link expires. Send in yyyy-mm-dd format.</td>
                                        </tr>
                                        <tr>
                                            <td>InvoiceNumber</td>
                                            <td>String</td>
                                            <td>An invoice number or submission number for the payment, to display for the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyStatus</td>
                                            <td>String</td>
                                            <td>The status of the policy (e.g., Active, Lapsed).</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>String</td>
                                            <td>Address of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>String</td>
                                            <td>City of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>String</td>
                                            <td>State of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>String</td>
                                            <td>Zip code of the insured.</td>
                                        </tr>
                                        <tr>
                                            <td>AgencyAffiliateName</td>
                                            <td>String</td>
                                            <td>Name of the agency affiliate.</td>
                                        </tr>
                                        <tr>
                                            <td>PolicyTaxesAndFees</td>
                                            <td>Object Array</td>
                                            <td>An array of objects representing policy taxes and fees. Each object contains Type, Description, Amount, and Line_Code.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/*<div className="overflow-hidden content-section" id="content-renewal-payments">*/}
                            {/*    <h2>Renewal a payment link</h2>*/}
                            {/*    <pre><code className="bash">*/}
                            {/*        # URL*/}
                            {/*        POST https://api-staging.xgenbilling.com/api/payment/linkrenewal*/}
                            {/*    </code></pre>*/}
                            {/*    <p>Renewal an account previously setup on AIS system.</p>*/}
                            {/*    <br />*/}
                            {/*    <pre><code className="json">*/}
                            {/*        {'{'}*/}
                            {/*        <br />*/}
                            {/*        "PaymentId": "TKD0LZ",*/}
                            {/*        <br />*/}
                            {/*        "InsuredName": "1",*/}
                            {/*        <br />*/}
                            {/*        {partner != "shieldknot" &&*/}
                            {/*            <>*/}
                            {/*                "AgentID": "7c0df437-390f-496a-b545-9bbbf3c03d77",*/}
                            {/*                <br />*/}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*        {partner == "shieldknot" &&*/}
                            {/*            <>*/}
                            {/*                "AgentID": "f2929ddf-88a3-4a00-a9f7-76f15708f4cc",*/}
                            {/*                <br />*/}
                            {/*            </>*/}
                            {/*        }*/}
                            {/*        "EmailAddress": "1@1.com",*/}
                            {/*        <br />*/}
                            {/*        "PolicyPremium": [*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'{'}*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Tran_Code": "Premium",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Written_Premium": 25.00,*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "GarageKeep"*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'}'},*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'{'}*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Tran_Code": "Premium",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Written_Premium": 25.00,*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "XProp"*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'}'}*/}
                            {/*        <br />*/}
                            {/*        ],*/}
                            {/*        <br />*/}
                            {/*        }*/}
                            {/*        "PhoneNumber": "1111111111",*/}
                            {/*        <br />*/}
                            {/*        "ExpirationDate" : "2022-01-01",*/}
                            {/*        <br />*/}
                            {/*        "InvoiceNumber": "1",*/}
                            {/*        <br />*/}
                            {/*        "PolicyStatus":"Active",*/}
                            {/*        <br />*/}
                            {/*        "Address": "231, CapeTown",*/}
                            {/*        <br />*/}
                            {/*        "City" : "Reno",*/}
                            {/*        <br />*/}
                            {/*        "State" : "CA",*/}
                            {/*        <br />*/}
                            {/*        "Zip": "85215",*/}
                            {/*        <br />*/}
                            {/*        "PolicyTaxesAndFees": [*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'{'}*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "F",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Policy Fee",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "AAA"*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'}'},*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'{'}*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Type": "T",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Description": "Filing Fee",*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Amount": 25.00,*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"Line_Code": "BBB"*/}
                            {/*        <br />*/}
                            {/*        &nbsp;&nbsp;&nbsp;&nbsp;{'}'}*/}
                            {/*        <br />*/}
                            {/*        ]*/}
                            {/*        <br />*/}
                            {/*        {'}'}*/}

                            {/*        <br />*/}
                            {/*        <br />*/}
                            {/*        Response example :*/}
                            {/*        <br />*/}
                            {/*        <br />*/}

                            {/*        {'{'}*/}
                            {/*        <br />*/}
                            {/*        "InsuredId": "IGJ111D9",*/}
                            {/*        <br />*/}
                            {/*        "paymentId": "8KUJCX",*/}
                            {/*        <br />*/}
                            {/*        "PaymentLink":  "https://app-staging.xgenbilling.com/make-payment?PaymentId=8KUJCX"*/}
                            {/*        <br />*/}
                            {/*        {'}'}*/}


                            {/*    </code></pre>*/}
                            {/*    <h4>QUERY PARAMETERS</h4>*/}
                            {/*    <table className="central-overflow-x">*/}
                            {/*        <thead>*/}
                            {/*            <tr>*/}
                            {/*                <th>Field</th>*/}
                            {/*                <th>Type</th>*/}
                            {/*                <th>Description</th>*/}
                            {/*            </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*            <tr>*/}
                            {/*                <td>PaymentId</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>(Required) The ID of the payment generated in the Create Payment Link step.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>InsuredName</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>The name of the person paying the bill, the named insured.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>AgentId</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>(Required) The Agent ID given to you by XGen for the agent.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>EmailAddress</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>The email address that the payment link will be sent to.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>PolicyPremium</td>*/}
                            {/*                <td>Object Array</td>*/}
                            {/*                <td>The total amount of the full policy.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>PhoneNumber</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>The phone number for the insured.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>ExpirationDate</td>*/}
                            {/*                <td>Date</td>*/}
                            {/*                <td>The date the link expires. Send in yyyy-mm-dd format. If blank, the default of 72 hours will be used.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>InvoiceNumber</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>an invoice number or submission number for the payment, to display for the insured.</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>PolicyStatus</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>the status of the policy (i.e., Active, Lapsed)</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>Address</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>Address that belongs to Insured</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>City</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>City that belongs to Insured</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>State</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>State that belongs to Insured</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>Zip</td>*/}
                            {/*                <td>String</td>*/}
                            {/*                <td>Zip that belongs to Insured</td>*/}
                            {/*            </tr>*/}
                            {/*            <tr>*/}
                            {/*                <td>PolicyTaxesAndFees</td>*/}
                            {/*                <td>Object Array</td>*/}
                            {/*                <td>An array of objects representing policy taxes and fees. Each object contains Type, Description, Amount, and Line_Code.</td>*/}
                            {/*            </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}
                            <div className="overflow-hidden content-section" id="content-get-account-details-paymentid">
                                <h2>get details by PaymentId</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/GetInsuredByPaymentId?PaymentId=ZFG1TD
                                </code></pre>
                                <p>Obtain the latest information on an insured's account</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    <br />
                                    "TermLength": 6,
                                    <br />
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />
                                    {'}'}
                                    <br />

                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentId</td>
                                            <td>String</td>
                                            <td>The ID of the payment generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-account-details-email">
                                <h2>get details by Email Address</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/GetInsuredByEmail?EmailAddress=test@gmail.com
                                </code></pre>
                                <p>Obtain the latest information on an insured's account</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    "TermLength": 6,
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />

                                    {'}'}


                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Email Address</td>
                                            <td>String</td>
                                            <td>The Email Address of the insured generated in the Create Payment Link step.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-agent-details">
                                <h2>get agent details</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/GetAgentDetails?AgentId=b709236d-ead1-4e50-b3d4-039916f7c448&Start=2022-07-01&End=2022-07-22
                                </code></pre>
                                <p>Get the information for an agent in the system. Agents are anyone who generates payment links or receives a commission.</p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "FullName": "John Smith",
                                    <br />
                                    "Address":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "Programs": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "ProgramName" : "GL",
                                    <br />
                                    "CommissionPercent": 30,
                                    <br />
                                    "CommissionAmount": 30,
                                    <br />
                                    "ProgramType" : "Test1",
                                    <br />
                                    {'}'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "ProgramName" : "Property",
                                    <br />
                                    "CommissionPercent": 15,
                                    <br />
                                    "CommissionAmount": 15,
                                    <br />
                                    "ProgramType" : "Test2",
                                    <br />
                                    {'}'}
                                    ],
                                    <br />
                                    "Type": "Agent"
                                    <br />
                                    {'}'}
                                    <br />

                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>Start</td>
                                            <td>Date</td>
                                            <td>(optional) The start date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                        <tr>
                                            <td>End</td>
                                            <td>Date</td>
                                            <td>(optional)  The end date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-agent-account-details">
                                <h2>get accounts by agent</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/GetAgentAccountDetails?AgentId=b709236d-ead1-4e50-b3d4-039916f7c448&Start=2022-07-01&End=2022-07-22
                                </code></pre>
                                <p></p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "FullName": "John Smith",
                                    <br />
                                    "Address":  "123 Anywhere Street, Sacramento CA 94203",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "Insureds": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    <br />
                                    "TermLength": 6,
                                    <br />
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />
                                    {'}'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    <br />
                                    "TermLength": 6,
                                    <br />
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />
                                    {'}'}
                                    ],
                                    <br />
                                    "Programs": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "ProgramName" : "GL",
                                    <br />
                                    "CommissionPercent": 30,
                                    <br />
                                    "CommissionAmount": 30,
                                    <br />
                                    "ProgramType" : "Test1",
                                    <br />
                                    {'}'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "ProgramName" : "Property",
                                    <br />
                                    "CommissionPercent": 15,
                                    <br />
                                    "CommissionAmount": 15,
                                    <br />
                                    "ProgramType" : "Test2",
                                    <br />
                                    {'}'}
                                    ],
                                    <br />
                                    "Type": "Agent"
                                    <br />
                                    {'}'}
                                    <br />

                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>Start</td>
                                            <td>Date</td>
                                            <td>(optional) The start date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                        <tr>
                                            <td>End</td>
                                            <td>Date</td>
                                            <td>(optional)  The end date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-past-due">
                                <h2>get past due accounts</h2>

                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/payment/GetAgentAccountDetails?AgentId=642de9c2-38aa-4fe8-b9a7-e03a95e44971&Start=2022-07-21&End=2022-07-26
                                </code></pre>
                                <p>Get a list of all accounts that are currently past due. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "FullName": "John Smith",
                                    <br />
                                    "Address":  "123 Anywhere Street, Sacramento CA 94203",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "Insureds": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    <br />
                                    "TermLength": 6,
                                    <br />
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />
                                    {'}'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "InsuredName": "John Smith",
                                    <br />
                                    "AgentID" : "02CF22B1",
                                    <br />
                                    "PolicyNumber":  "A123456-00",
                                    <br />
                                    "EmailAddress": "test@testco.com",
                                    <br />
                                    "TotalPremium": 19000.00,
                                    <br />
                                    "DownPayment": 5000.00,
                                    <br />
                                    "MonthlyAmount": 2800.00,
                                    <br />
                                    "TermLength": 6,
                                    <br />
                                    "DueDate": "2022-04-12",
                                    <br />
                                    "PhoneNumber": "1111111111",
                                    <br />
                                    "InvoiceNumber": "B12345670",
                                    <br />
                                    "PolicyStatus":"Pending",
                                    <br />
                                    "PaymentLink":  "https://xgenpayments-new-design.azurewebsites.net/make-payment?PaymentId=8KUJCX",
                                    <br />
                                    "RemainingBalance" : 14000.00,
                                    <br />
                                    "RemainingPayments" : 5
                                    <br />
                                    {'}'}
                                    ],
                                    <br />
                                    "Type": "Agent"
                                    <br />
                                    {'}'}
                                    <br />

                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>AgentId</td>
                                            <td>String</td>
                                            <td>The Agent ID given to you by XGen for the agent.</td>
                                        </tr>
                                        <tr>
                                            <td>Start</td>
                                            <td>Date</td>
                                            <td>(optional) The start date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                        <tr>
                                            <td>End</td>
                                            <td>Date</td>
                                            <td>(optional)  The end date for the commissions and aggregate. Leave this blank to return everything. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-authentication">
                                <h2>AUTHENTICATION</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/account/Authentication
                                </code></pre>
                                <p>You can use this api for getting authorize token as well user details. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "UserName": "Test@mailinator.com",
                                    <br />
                                    "Password":  "123456"
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": ""
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "UserName": "Test@mailinator.com",
                                    <br />
                                    "FullName": "",
                                    <br />
                                    "normalizedUserName": "TEST@MAILINATOR.COM",
                                    <br />
                                    "Email": "test@mailnator.com",
                                    <br />
                                    "EmailConfirmed": "false",
                                    <br />
                                    "PasswordHash": "kPF4Fy/dWEW78IIbU0qA+lV9vNRMJJvmbMQowmbXi0Q=",
                                    <br />
                                    "securityStamp": null,
                                    <br />
                                    "concurrencyStamp": null,
                                    <br />
                                    "phoneNumber": "(894) 855-1516",
                                    <br />
                                    "phoneNumberConfirmed": false,
                                    <br />
                                    "twoFactorEnabled": false,
                                    <br />
                                    "lockoutEnd": "0001-01-01T00:00:00+00:00",
                                    <br />
                                    "lockoutEnabled": false,
                                    <br />
                                    "accessFailedCount": 0,
                                    <br />
                                    "flowGroupId": 2,
                                    <br />
                                    "userRoleIds": [2],
                                    <br />
                                    "isActive": null,
                                    <br />
                                    "createdDate": "0001-01-01T00:00:00",
                                    <br />
                                    "userInfoMdl"
                                    <br />
                                    {'{'}
                                    "id": 413,
                                    <br />
                                    "userId": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "fullName": "super admin",
                                    <br />
                                    "address": null,
                                    <br />
                                    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImRhNTFkZjkzLWMzZmQtNDlhNy1iZWY4LTlkODliZDJjNTFmNiIsImVtYWlsIjoieGdlbitmbHV4QG1haWxpbmF0b3IuY29tIiwiZ3JvdXBzaWQiOiIyIiwiU3ViRG9tYWluSWQiOiIxOSIsIlBheW1lbnRQcm9jZXNzSWQiOiIyIiwibmJmIjoxNjc4NjkzODE4LCJleHAiOjE2NzkyOTg2MTgsImlhdCI6MTY3ODY5MzgxOH0.OHrA72X0oNOV00odL54XRLk7SWeTWztGM7HCYD21lPM",
                                    <br />
                                    "createdDate": "0001-01-01T00:00:00",
                                    <br />
                                    "modifiedDate": "2022-11-23T08:19:35.12",
                                    <br />
                                    "createdBy": null,
                                    <br />
                                    "modifiedBy": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "isActive": true,
                                    <br />
                                    "isDelete": false,
                                    <br />
                                    "isTextActive": false,
                                    <br />
                                    "phoneNumber": "(894) 855-1516",
                                    <br />
                                    "city": null,
                                    <br />
                                    "state": null,
                                    <br />
                                    "zip": null

                                    <br />
                                    {'},'}
                                    <br />
                                    "AgentInfoMdl": null,
                                    <br />
                                    "RoleListMdl": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": 2,
                                    <br />
                                    "Name": "SuperAdmin",
                                    <br />
                                    "NormalizedName": "SUPER ADMIN",
                                    <br />
                                    "ConcurrencyStamp": null,
                                    <br />
                                    "SubDomainId": 0
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    <br />
                                    "subDomainId": "19",
                                    <br />
                                    "url": "flux",
                                    <br />
                                    "logo": "assets/Content/images/crop_imageGITSPI9I.png",
                                    <br />
                                    "fontFamily": "'Encode Sans', sans-serif;",
                                    <br />
                                    "label1": "Down Payment Amount",
                                    <br />
                                    "label2": "Monthly Payment Amount",
                                    <br />
                                    "color": "#0cd2e1",
                                    <br />
                                    "hoverFocus": "#314a4c",
                                    <br />
                                    "address": null,
                                    <br />
                                    "city": null,
                                    <br />
                                    "state": null,
                                    <br />
                                    "zip": null,
                                    <br />
                                    "paymentProcessId": 2
                                    <br />
                                    {'},'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>UserName</td>
                                            <td>String</td>
                                            <td>(Required) The User name given to you by XGen for the SuperAdmin.</td>
                                        </tr>
                                        <tr>
                                            <td>Password</td>
                                            <td>String</td>
                                            <td>(Required) Enter your password. </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-roles">
                                <h2>Get Roles</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/account/GetRoleList
                                </code></pre>
                                <p>Obtain the all roles in dmonin. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": ""
                                    <br />
                                    {'[{'}
                                    <br />
                                    "Id": "27",
                                    <br />
                                    "Name": "Agent",
                                    <br />
                                    "NormalizedName": "AGENT",
                                    <br />
                                    "ConcurrencyStamp": "null",
                                    <br />
                                    "subDomainId": "19",

                                    <br />
                                    {'}],'}

                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}

                                </code></pre>

                            </div>
                            <div className="overflow-hidden content-section" id="content-createuser">
                                <h2>User</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/account/user
                                </code></pre>
                                <p>You can use this api for add/edit user (Payables). </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "FullName": "Test@mailinator.com",
                                    <br />
                                    "Email":  "123456"
                                    <br />
                                    "UserRoleIds": [21],
                                    <br />
                                    "Address": "123 schedule",
                                    <br />
                                    "City": "Donver",
                                    <br />
                                    "State": "NV",
                                    <br />
                                    "Zip": "80001",
                                    <br />
                                    "PhoneNumber": "8549612369"
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": ""
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "UserName": null,
                                    <br />
                                    "FullName": "test user",
                                    <br />
                                    "normalizedUserName": "TEST@MAILINATOR.COM",
                                    <br />
                                    "Email": "test@mailnator.com",
                                    <br />
                                    "EmailConfirmed": "false",
                                    <br />
                                    "PasswordHash": "kPF4Fy/dWEW78IIbU0qA+lV9vNRMJJvmbMQowmbXi0Q=",
                                    <br />
                                    "securityStamp": null,
                                    <br />
                                    "concurrencyStamp": null,
                                    <br />
                                    "phoneNumber": "(894) 855-1516",
                                    <br />
                                    "phoneNumberConfirmed": false,
                                    <br />
                                    "twoFactorEnabled": false,
                                    <br />
                                    "lockoutEnd": "0001-01-01T00:00:00+00:00",
                                    <br />
                                    "lockoutEnabled": false,
                                    <br />
                                    "accessFailedCount": 0,
                                    <br />
                                    "flowGroupId": 2,
                                    <br />
                                    "userRoleIds": [2],
                                    <br />
                                    "isActive": null,
                                    <br />
                                    "createdDate": "0001-01-01T00:00:00",
                                    <br />
                                    "userInfoMdl"
                                    <br />
                                    {'{'}
                                    "id": 413,
                                    <br />
                                    "userId": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "fullName": "test user",
                                    <br />
                                    "address": null,
                                    <br />
                                    "token": "",
                                    <br />
                                    "createdDate": "0001-01-01T00:00:00",
                                    <br />
                                    "modifiedDate": "2022-11-23T08:19:35.12",
                                    <br />
                                    "createdBy": null,
                                    <br />
                                    "modifiedBy": "da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "isActive": true,
                                    <br />
                                    "isDelete": false,
                                    <br />
                                    "isTextActive": false,
                                    <br />
                                    "phoneNumber": "(894) 855-1516",
                                    <br />
                                    "city": null,
                                    <br />
                                    "state": null,
                                    <br />
                                    "zip": null

                                    <br />
                                    {'},'}
                                    <br />
                                    "AgentInfoMdl": null,
                                    <br />
                                    "RoleListMdl": null
                                    <br />
                                    "subDomainId": "19",
                                    <br />
                                    "url": "flux",
                                    <br />
                                    "logo": "assets/Content/images/crop_imageGITSPI9I.png",
                                    <br />
                                    "fontFamily": "'Encode Sans', sans-serif;",
                                    <br />
                                    "label1": "Down Payment Amount",
                                    <br />
                                    "label2": "Monthly Payment Amount",
                                    <br />
                                    "color": "#0cd2e1",
                                    <br />
                                    "hoverFocus": "#314a4c",
                                    <br />
                                    "address": null,
                                    <br />
                                    "city": null,
                                    <br />
                                    "state": null,
                                    <br />
                                    "zip": null,
                                    <br />
                                    "paymentProcessId": 2
                                    <br />
                                    {'},'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>FullName</td>
                                            <td>String</td>
                                            <td>(Required) This name display as a user name .</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>String</td>
                                            <td>(Required) Email using for login. </td>
                                        </tr>
                                        <tr>
                                            <td>UserRoleIds</td>
                                            <td>Integer array</td>
                                            <td>(Required) This is used for assgin role . </td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>String</td>
                                            <td>(Required) This is used for address. </td>
                                        </tr>
                                        <tr>
                                            <td>City</td>
                                            <td>String</td>
                                            <td>(Required) Enter city name. </td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>String</td>
                                            <td>(Required) Enter state name. </td>
                                        </tr>
                                        <tr>
                                            <td>Zip</td>
                                            <td>String</td>
                                            <td>(Required) Enter Zip code. </td>
                                        </tr>
                                        <tr>
                                            <td>Phone Number</td>
                                            <td>String</td>
                                            <td>(Required) Enter Phone number. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-user-list">
                                <h2>Get Users</h2>
                                <pre><code className="bash">
                                    # URL
                                    GET https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/account/GetUserList
                                </code></pre>
                                <p>Obtain the all user in dmonin. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": ""
                                    <br />
                                    {'[{'}
                                    <br />
                                    "Id": "065b4409-1317-4d26-b027-7e6b328b2573",
                                    <br />
                                    "UserName": "Agent",
                                    <br />
                                    "FullName": null,
                                    <br />
                                    "NormalizedUserName": "DSFHLWEF@MAILINATOR.COM",
                                    <br />
                                    "Email": "dsfhlwef@mailinator.com",
                                    <br />
                                    "NormalizedEmail":"DSFHLWEF@MAILINATOR.COM",
                                    <br />
                                    "EmailConfirmed":false,
                                    <br />
                                    "PasswordHash":null,
                                    <br />
                                    "SecurityStamp":null,
                                    <br />
                                    "ConcurrencyStamp":null,
                                    <br />
                                    "PhoneNumber":"(958) 498-4894",
                                    <br />
                                    "PhoneNumberConfirmed":false,
                                    <br />
                                    "TwoFactorEnabled":false,
                                    <br />
                                    "LockoutEnd":"0001-01-01T00:00:00+00:00",
                                    <br />
                                    "LockoutEnabled":false,
                                    <br />
                                    "AccessFailedCount":0,
                                    <br />
                                    "FlowGroupId":3,
                                    <br />
                                    "UserRoleIds":null,
                                    <br />
                                    "IsActive":null,
                                    <br />
                                    "CreatedDate":"0001-01-01T00:00:00",
                                    <br />
                                    "UserInfoMdl": <br />
                                    {'{'}

                                    "Id":1470,
                                    <br />
                                    "UserId":"065b4409-1317-4d26-b027-7e6b328b2573",
                                    <br />
                                    "FullName":"ddt123",
                                    <br />
                                    "Address":"123",
                                    <br />
                                    "Token":null,
                                    <br />
                                    "CreatedDate":"2023-02-28T06:14:21.46",
                                    <br />
                                    "ModifiedDate":"2023-02-28T06:14:21.46",
                                    <br />
                                    "CreatedBy":"da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "ModifiedBy":"da51df93-c3fd-49a7-bef8-9d89bd2c51f6",
                                    <br />
                                    "IsActive":true,
                                    <br />
                                    "IsDelete":false,
                                    <br />
                                    "IsTextActive":false,
                                    <br />
                                    "PhoneNumber":"(958) 498-4894",
                                    <br />
                                    "City":"123",
                                    <br />
                                    "State":"123",
                                    <br />
                                    "Zip":"123",
                                    <br />
                                    {'}'}
                                    <br />
                                    "AgentInfoMdl":null,
                                    <br />
                                    "RoleListMdl":null,
                                    <br />
                                    "SubDomainId":"19",
                                    <br />
                                    "Url":null,
                                    <br />
                                    "Logo":null,
                                    <br />
                                    "FontFamily":null,
                                    <br />
                                    "Label1":null,
                                    <br />
                                    "Label2":null,
                                    <br />
                                    "Color":null,
                                    <br />
                                    "HoverFocus":null,
                                    <br />
                                    "Address":null,
                                    <br />
                                    "City":null,
                                    <br />
                                    "State":null,
                                    <br />
                                    "Zip":null,
                                    <br />
                                    "PaymentProcessId":0
                                    <br />
                                    {'}]'}

                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}

                                </code></pre>

                            </div>
                            <div className="overflow-hidden content-section" id="content-account-setup">
                                <h2>ACCOUNT SETUP</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/AccountSetup
                                </code></pre>
                                <p>You can use this api for ADD ACH Details In user (Payables). </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "PaymentTypeId": 1,
                                    <br />
                                    "Email": "test@malinator@mailinator.com",
                                    <br />
                                    "FirstName": "Rohit",
                                    <br />
                                    "LastName": "Flux36",
                                    <br />
                                    "AchRoutingNumber": "012365478",
                                    <br />
                                    "AchAccountNumber": "12365479890"
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": ""
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": "693",
                                    <br />
                                    "dba":
                                    <br />
                                    {'{'}
                                    "id": 123
                                    <br />
                                    {'},'}
                                    <br />
                                    "FirstName": Test,
                                    <br />
                                    "LastName": "Flux",
                                    <br />
                                    "Phone": "",
                                    <br />
                                    "Email": "test@mailnator.com",
                                    <br />
                                    "Address1": "kPF4Fy/dWEW78IIbU0qA+lV9vNRMJJvmbMQowmbXi0Q=",
                                    <br />
                                    "Address2": null,
                                    <br />
                                    "City": donver,
                                    <br />
                                    "State": "CA",
                                    <br />
                                    "ZipCode": 80001,
                                    <br />
                                    "AccountName": "test flux",
                                    <br />
                                    "AccountNumber": "12365479890",
                                    <br />
                                    "AccountType": "Savings",
                                    <br />
                                    "RoutingNumber": "012365478",
                                    <br />
                                    "Identifier": "dfa330d2-6c68-43f3-b58c-198a3d3db1e9",
                                    <br />
                                    {'},'}
                                    {/*<br />*/}
                                    {/*"Message": "Success"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PaymentTypeId</td>
                                            <td>int</td>
                                            <td>(Required)Enter Payment type id .</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>String</td>
                                            <td>(Required) Created user email. </td>
                                        </tr>
                                        <tr>
                                            <td>FirstName</td>
                                            <td>Integer array</td>
                                            <td>(Required) First name of account holder. </td>
                                        </tr>
                                        <tr>
                                            <td>LastName</td>
                                            <td>String</td>
                                            <td>(Required) Last name on account holder. </td>
                                        </tr>
                                        <tr>
                                            <td>AchRoutingNumber</td>
                                            <td>String</td>
                                            <td>(Required) Payable Routing number. </td>
                                        </tr>
                                        <tr>
                                            <td>AchAccountNumber</td>
                                            <td>String</td>
                                            <td>(Required) Payable account number. </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-commission-setup">
                                <h2>SET PAYBLE COMMISSION</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/usercommission/SetCommission
                                </code></pre>
                                <p>You can use this api for set user (Payables) commission. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": 0,
                                    <br />
                                    "UserId": "["dfa330d2-6c68-43f3-b58c-198a3d3db1e9"]",
                                    <br />
                                    "From": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "To": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "SingleUserId": "",
                                    <br />
                                    "CommissionPercent": 30
                                    <br />
                                    "FlatCommission": 100
                                    <br />
                                    "Program": "XL"
                                    <br />
                                    "Type": "TL"
                                    <br />
                                    "FullName": "Test"
                                    <br />
                                    "Payout": "$100+ Commission"
                                    <br />
                                    "PaymentType": "ACH"
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":
                                    <br />
                                    {'{'}
                                    <br />
                                    <br />
                                    "Id": 0,
                                    <br />
                                    "UserId": "["dfa330d2-6c68-43f3-b58c-198a3d3db1e9"]",
                                    <br />
                                    "From": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "To": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "SingleUserId": "",
                                    <br />
                                    "CommissionPercent": 30
                                    <br />
                                    "FlatCommission": 100
                                    <br />
                                    "Program": "XL"
                                    <br />
                                    "Type": "TL"
                                    <br />
                                    "FullName": "Test"
                                    <br />
                                    "Payout": "$100+ Commission"
                                    <br />
                                    "PaymentType": "ACH"
                                    <br />
                                    {'},'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Id</td>
                                            <td>int</td>
                                            <td>(Required)Enter this id will always 0 for new commission setup .</td>
                                        </tr>
                                        <tr>
                                            <td>UserId</td>
                                            <td>String</td>
                                            <td>(Required) User id for set user commission. </td>
                                        </tr>
                                        <tr>
                                            <td>From</td>
                                            <td>Date Time</td>
                                            <td>(Required) From date for commission effctive date. </td>
                                        </tr>
                                        <tr>
                                            <td>To</td>
                                            <td>Date Time</td>
                                            <td>(Required) TO date for commission effctive end date. </td>
                                        </tr>
                                        <tr>
                                            <td>SingleUserId</td>
                                            <td>String</td>
                                            <td>(Optional) </td>
                                        </tr>
                                        <tr>
                                            <td>commissionPercent</td>
                                            <td>decimal</td>
                                            <td>(Required) Commission percent which want you give payable. </td>
                                        </tr>
                                        <tr>
                                            <td>FlatCommission</td>
                                            <td>decimal</td>
                                            <td>(Required) Flat Commission which want you give payable. </td>
                                        </tr>
                                        <tr>
                                            <td>Program</td>
                                            <td>String</td>
                                            <td>(Required) Program type. </td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>String</td>
                                            <td>(Required) Type. </td>
                                        </tr>
                                        <tr>
                                            <td>FullName</td>
                                            <td>String</td>
                                            <td>(Optional) Full name optional. </td>
                                        </tr>
                                        <tr>
                                            <td>Payout</td>
                                            <td>String</td>
                                            <td>(Required) Set payable payout. </td>
                                        </tr>
                                        <tr>
                                            <td>PaymentType</td>
                                            <td>String</td>
                                            <td>(Required) Set payment type for payable. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-update-commission-setup">
                                <h2>UPDATE PAYBLE COMMISSION</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/usercommission/SetCommission
                                </code></pre>
                                <p>You can use this api for set user (Payables) commission. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    {'{'}
                                    <br />
                                    "Id": 20,
                                    <br />
                                    "UserId": "["dfa330d2-6c68-43f3-b58c-198a3d3db1e9"]",
                                    <br />
                                    "From": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "To": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "SingleUserId": "",
                                    <br />
                                    "CommissionPercent": 30
                                    <br />
                                    "FlatCommission": 100
                                    <br />
                                    "Program": "XL"
                                    <br />
                                    "Type": "TL"
                                    <br />
                                    "FullName": "Test"
                                    <br />
                                    "Payout": "$100+ Commission"
                                    <br />
                                    "PaymentType": "ACH"
                                    <br />
                                    {'}'}
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":
                                    <br />
                                    {'{'}
                                    <br />
                                    <br />
                                    "Id": 20,
                                    <br />
                                    "UserId": "["dfa330d2-6c68-43f3-b58c-198a3d3db1e9"]",
                                    <br />
                                    "From": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "To": "2023-03-15T16:36:55.029Z",
                                    <br />
                                    "SingleUserId": "",
                                    <br />
                                    "CommissionPercent": 30
                                    <br />
                                    "FlatCommission": 100
                                    <br />
                                    "Program": "XL"
                                    <br />
                                    "Type": "TL"
                                    <br />
                                    "FullName": "Test"
                                    <br />
                                    "Payout": "$100+ Commission"
                                    <br />
                                    "PaymentType": "ACH"
                                    <br />
                                    {'},'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Id</td>
                                            <td>int</td>
                                            <td>(Required)Enter this id commission id for update commission .</td>
                                        </tr>
                                        <tr>
                                            <td>UserId</td>
                                            <td>String</td>
                                            <td>(Required) User id for set user commission. </td>
                                        </tr>
                                        <tr>
                                            <td>From</td>
                                            <td>Date Time</td>
                                            <td>(Required) From date for commission effctive date. </td>
                                        </tr>
                                        <tr>
                                            <td>To</td>
                                            <td>Date Time</td>
                                            <td>(Required) TO date for commission effctive end date. </td>
                                        </tr>
                                        <tr>
                                            <td>SingleUserId</td>
                                            <td>String</td>
                                            <td>(Optional) </td>
                                        </tr>
                                        <tr>
                                            <td>commissionPercent</td>
                                            <td>decimal</td>
                                            <td>(Required) Commission percent which want you give payable. </td>
                                        </tr>
                                        <tr>
                                            <td>FlatCommission</td>
                                            <td>decimal</td>
                                            <td>(Required) Flat Commission which want you give payable. </td>
                                        </tr>
                                        <tr>
                                            <td>Program</td>
                                            <td>String</td>
                                            <td>(Required) Program type. </td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>String</td>
                                            <td>(Required) Type. </td>
                                        </tr>
                                        <tr>
                                            <td>FullName</td>
                                            <td>String</td>
                                            <td>(Optional) Full name optional. </td>
                                        </tr>
                                        <tr>
                                            <td>Payout</td>
                                            <td>String</td>
                                            <td>(Required) Set payable payout. </td>
                                        </tr>
                                        <tr>
                                            <td>PaymentType</td>
                                            <td>String</td>
                                            <td>(Required) Set payment type for payable. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="overflow-hidden content-section" id="content-commission-list">
                                <h2>Get Commission Last</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/usercommission/GetCommissionList
                                </code></pre>
                                <p>You can use this api get user commission setup list. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data"[
                                    <br />
                                    {'{'}
                                    "totalRecord": 17,
                                    "data": [
                                    {'{'}
                                    <br />
                                    "id": 30,
                                    <br />
                                    "userId": "dfa330d2-6c68-43f3-b58c-198a3d3db1e9",
                                    <br />
                                    "fullName": "test flux",
                                    <br />
                                    "email": "testflux456@mailinator.com",
                                    <br />
                                    "commissionPercent": 10,
                                    <br />
                                    "phoneNumber": null,
                                    <br />
                                    "program": "XL",
                                    <br />
                                    "type": "TL",
                                    <br />
                                    "from": "2023-03-16T16:48:28.093",
                                    <br />
                                    "to": "2023-03-16T16:48:28.093",
                                    <br />
                                    "totalCommissionPaid": 0,
                                    <br />
                                    "flatCommission": 100,
                                    <br />
                                    "payout": "$100+Commission",
                                    <br />
                                    "paymentType": "ACH"
                                    {'}]'}
                                    <br />
                                    {'}'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>

                            <div className="overflow-hidden content-section" id="content-transaction-list">
                                <h2>Transaction List</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/GetTransactions
                                </code></pre>
                                <p>You can use this api for get payout transactions. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":
                                    <br />
                                    {'['}
                                    <br />
                                    {'{'}
                                    <br />
                                    "id": 7,
                                    <br />
                                    "accountId": 659,
                                    <br />
                                    "commissionAmount": 37.6,
                                    <br />
                                    "program": "xl",
                                    <br />
                                    "type": "TL",
                                    <br />
                                    "isProcess": true,
                                    <br />
                                    "fullName": "Rohit Chaturvedi",
                                    <br />
                                    "brandName": "Flux",
                                    <br />
                                    "subDomainId": 19,
                                    <br />
                                    "createdDate": "2023-02-17T13:14:11.197",
                                    <br />
                                    "transactionId": null,
                                    <br />
                                    "status": "True"
                                    {'},'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "id": 8,
                                    <br />
                                    "accountId": 659,
                                    <br />
                                    "commissionAmount": 37.6,
                                    <br />
                                    "program": "xl",
                                    <br />
                                    "type": "TL",
                                    <br />
                                    "isProcess": true,
                                    <br />
                                    "fullName": "Rohit Chaturvedi",
                                    <br />
                                    "brandName": "Flux",
                                    <br />
                                    "subDomainId": 19,
                                    <br />
                                    "createdDate": "2023-02-17T13:24:28.583",
                                    <br />
                                    "transactionId": null,
                                    <br />
                                    "status": null
                                    <br />
                                    {'}'}
                                    <br />
                                    {'],'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-approve-transaction">
                                <h2>Approve Transaction</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/ApproveTransaction?id=123
                                </code></pre>
                                <p>You can use this api for approve transaction. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":null,
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-account-list">
                                <h2>Transaction List</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/GetAccounts
                                </code></pre>
                                <p>You can use this api for get payout transactions. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":
                                    <br />
                                    {'['}
                                    <br />
                                    {'{'}
                                    <br />
                                    "id": 20,
                                    <br />
                                    "fullName": "Vin Desel",
                                    <br />
                                    "email": "vindesel@mailinator.com",
                                    <br />
                                    "createdDate": "2023-03-07T15:19:39.4",
                                    <br />
                                    "accountId": "686",
                                    <br />
                                    "bankAccountId": "686",
                                    <br />
                                    "brandName": "Flux",
                                    <br />
                                    "identifier": "0e5327de-09c5-4cdc-b7e6-245a24ffa11d"
                                    <br />
                                    {'},'}
                                    <br />
                                    {'{'}
                                    <br />
                                    "id": 13,
                                    <br />
                                    "fullName": "jacob oram",
                                    <br />
                                    "email": "jacobflux@mailinator.com",
                                    <br />
                                    "createdDate": "2023-02-17T10:36:35.957",
                                    <br />
                                    "accountId": "666",
                                    <br />
                                    "bankAccountId": "666",
                                    <br />
                                    "brandName": "Flux",
                                    <br />
                                    "identifier": "2517f46d-e7ad-4b80-81ff-3b70e378bea2"
                                    <br />
                                    {'}'}
                                    <br />
                                    {'],'}
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-delete-customer-details">
                                <h2>Delete Customer details</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/DeleteCustomerDetails?userId=0e5327de-09c5-4cdc-b7e6-245a24ffa11d
                                </code></pre>
                                <p>This api use for delete user account details. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":null,
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-delete-transaction">
                                <h2>Delete Transaction</h2>
                                <pre><code className="bash">
                                    # URL
                                    Get https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/paymentaccount/DeleteTransaction?transactionId=10
                                </code></pre>
                                <p>This api use for delete user (Payable) transaction. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data":null,
                                    {/*<br />*/}
                                    {/*"Message": null*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-get-headers">
                                <h2>Get all headers</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/fluxreport/getallheaders?name=getallheaders
                                </code></pre>
                                <p>This api use for get all header names. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":87,
                                    <br />
                                    "Data":[
                                    <br />
                                    "Transaction_Id",
                                    <br />
                                    "Agent_Phone_Number",
                                    <br />
                                    "Billing_Address",
                                    <br />
                                    "Amount_Paid",
                                    <br />
                                    "Amount_Due",
                                    <br />
                                    "Status",
                                    <br />
                                    "Transaction_Type",
                                    <br />
                                    "Carrier",
                                    <br />
                                    "Policy_Type",
                                    <br />
                                    "ConfirmationNumber",
                                    <br />
                                    "Payout_Date",
                                    <br />
                                    "Amount_Payable"
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Headers"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                            </div>
                            <div className="overflow-hidden content-section" id="content-billing-account-by-insured">
                                <h2>Get all billing account by insured</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetAllBillingAccountsDetailsByInsured?SubDomainId=9
                                </code></pre>
                                <p>This api use for get all header names. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "totalRecord":2,
                                    <br />
                                    "data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "devduttinsuredflux@mailinator.com",
                                    <br />
                                    "phoneNumber": "(489) 496-9465",
                                    <br />
                                    "policyNumber": "POSUENC-01",
                                    <br />
                                    "paymentId": "GRP4HB",
                                    <br />
                                    "premiumAmount": 2150,
                                    <br />
                                    "createdDate": "2022-11-23T14:17:21.38",
                                    <br />
                                    "remainingBalance": 1500,
                                    <br />
                                    "remainingPayments": 8
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "remainingBalance": 0,
                                    <br />
                                    "remainingPayments": 8
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-pending-billing">
                                <h2>Get Pending billing accounts</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetBillingAccountsPending?SubDomainId=9
                                </code></pre>
                                <p>This api use for get all pending accounts details. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "consult2evolve@gmail.com",
                                    <br />
                                    "phoneNumber": "3543545354",
                                    <br />
                                    "policyNumber": null,
                                    <br />
                                    "paymentId": "638617392012635175",
                                    <br />
                                    "premiumAmount": 11423.59,
                                    <br />
                                    "dueDateOfPayment": "2024-08-12T00:00:00",
                                    <br />
                                    "monthlyAmount": 913.89,
                                    <br />
                                    "downPayment": 2289.71,
                                    <br />
                                    "termLength": 10
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "dueDateOfPayment": "2024-08-14T00:00:00",
                                    <br />
                                    "monthlyAmount": 40,
                                    <br />
                                    "downPayment": 100,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-due-billing">
                                <h2>Get due billing accounts</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetBillingAccountsDue?SubDomainId=9
                                </code></pre>
                                <p>This api use for get all due billing account. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "devduttinsuredflux@mailinator.com",
                                    <br />
                                    "phoneNumber": "(489) 496-9465",
                                    <br />
                                    "policyNumber": "POSUENC-01",
                                    <br />
                                    "paymentId": "GRP4HB",
                                    <br />
                                    "premiumAmount": 2150,
                                    <br />
                                    "dueDateOfPayment": "2024-08-14T00:00:00",
                                    <br />
                                    "monthlyAmount": 150,
                                    <br />
                                    "downPayment": 650,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "dueDateOfPayment": "2024-08-14T00:00:00",
                                    <br />
                                    "monthlyAmount": 40,
                                    <br />
                                    "downPayment": 100,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-late-billing">
                                <h2>Get late billing accounts</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetBillingAccountsLate?SubDomainId=9
                                </code></pre>
                                <p>This api use for get all due late billing account. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "devduttinsuredflux@mailinator.com",
                                    <br />
                                    "phoneNumber": "(489) 496-9465",
                                    <br />
                                    "policyNumber": "POSUENC-01",
                                    <br />
                                    "paymentId": "GRP4HB",
                                    <br />
                                    "premiumAmount": 2150,
                                    <br />
                                    "dueDateOfPayment": "2024-08-14T00:00:00",
                                    <br />
                                    "monthlyAmount": 150,
                                    <br />
                                    "downPayment": 650,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "dueDateOfPayment": "2023-12-05T20:46:06.097",
                                    <br />
                                    "monthlyAmount": 40,
                                    <br />
                                    "downPayment": 100,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-cancelled-billing">
                                <h2>Get cancelled billing accounts</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/fluxreport/GetBillingAccountsCancelled?SubDomainId=19
                                </code></pre>
                                <p>This api use for get all due cancelled billing account. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "devduttinsuredflux@mailinator.com",
                                    <br />
                                    "phoneNumber": "(489) 496-9465",
                                    <br />
                                    "policyNumber": "POSUENC-01",
                                    <br />
                                    "paymentId": "GRP4HB",
                                    <br />
                                    "premiumAmount": 2150,
                                    <br />
                                    "dueDateOfPayment": "2023-12-05T20:46:06.097",
                                    <br />
                                    "monthlyAmount": 150,
                                    <br />
                                    "downPayment": 650,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "dueDateOfPayment": "2023-12-05T20:46:06.097",
                                    <br />
                                    "monthlyAmount": 40,
                                    <br />
                                    "downPayment": 100,
                                    <br />
                                    "termLength": 10,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "createdBy": "fluxconnect@mailinator.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-commission-paid-due">
                                <h2>Get paid and due commission</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetAllCommissionPaidandDue?SubDomainId=9
                                </code></pre>
                                <p>This api used for get paid and due commission. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "totalRecord": 1,
                                    <br />
                                    "data": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "fullName": "Stella Carroll",
                                    <br />
                                    "phoneNumber": (123) 456-7890,
                                    <br />
                                    "address": null,
                                    <br />
                                    "commissionPercent": 0,
                                    <br />
                                    "amountPaid": 1005,
                                    <br />
                                    "amountDue": 5000,
                                    <br />
                                    "program": null,
                                    <br />
                                    "type": null,
                                    <br />
                                    "transactionId": "18352070",
                                    <br />
                                    "agentPhoneNumber": "(345) 345-3443",
                                    <br />
                                    "billingAddress": "test New York NY 10001",
                                    <br />
                                    "status": "Paid",
                                    <br />
                                    "transactionType": "Credit Card",
                                    <br />
                                    "carrier": null,
                                    <br />
                                    "policyType": null,
                                    <br />
                                    "confirmationNumber": "13056",
                                    <br />
                                    "payee": null,
                                    <br />
                                    "amountPayable": 0,
                                    <br />
                                    "payoutDate": "2024-08-29T15:49:09.71",
                                    <br />
                                    "createdDatePayment": "0001-01-01T00:00:00",
                                    <br />
                                    "hasAccountSetup": false
                                    <br />
                                    {'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-payout-info">
                                <h2>Get Payout Info By ConfirmationNumber</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/fluxreports/PayoutInfoByConfirmationNumber?ConfirmationNumber=30976
                                </code></pre>
                                <p>This api used for get payout info by confirmation number. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":1,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "fullName": null,
                                    <br />
                                    "phoneNumber": null,
                                    <br />
                                    "address": null,
                                    <br />
                                    "commissionPercent": 0,
                                    <br />
                                    "amountPaid": 0,
                                    <br />
                                    "amountDue": 0,
                                    <br />
                                    "program": null,
                                    <br />
                                    "type": null,
                                    <br />
                                    "transactionId": "11949",
                                    <br />
                                    "agentPhoneNumber": null,
                                    <br />
                                    "billingAddress": null,
                                    <br />
                                    "status": "Approved",
                                    <br />
                                    "transactionType": null,
                                    <br />
                                    "carrier": null,
                                    <br />
                                    "policyType": null,
                                    <br />
                                    "confirmationNumber": null,
                                    <br />
                                    "payee": "Summit Speciality",
                                    <br />
                                    "amountPayable": 30000,
                                    <br />
                                    "payoutDate": "2023-03-15T15:13:13.627",
                                    <br />
                                    "createdDatePayment": "0001-01-01T00:00:00"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>ConfirmationNumber</td>
                                            <td>int</td>
                                            <td>(Required) Confirmation number used for firlter record.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-payment-term">
                                <h2>Get Get billing account by payment terms</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetBillingAccountsByPaymentTerm?SubDomainId=9&PaymentTerm=1
                                </code></pre>
                                <p>This api used for get paid and due commission. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "Devdutt Insured",
                                    <br />
                                    "insuredEmail": "devduttinsuredflux@mailinator.com",
                                    <br />
                                    "phoneNumber": "(489) 496-9465",
                                    <br />
                                    "policyNumber": "POSUENC-01",
                                    <br />
                                    "paymentId": "GRP4HB",
                                    <br />
                                    "premiumAmount": 2150,
                                    <br />
                                    "createdDate": "2022-11-23T14:17:21.38",
                                    <br />
                                    "remainingBalance": 1500,
                                    <br />
                                    "remainingPayments": 8
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "kgh",
                                    <br />
                                    "insuredEmail": "umigdcs@mailinator.com",
                                    <br />
                                    "phoneNumber": "(364) 289-4754",
                                    <br />
                                    "policyNumber": "63744",
                                    <br />
                                    "paymentId": "18KBKU",
                                    <br />
                                    "premiumAmount": 500,
                                    <br />
                                    "createdDate": "2022-12-05T20:46:06.097",
                                    <br />
                                    "remainingBalance": 0,
                                    <br />
                                    "remainingPayments": 8
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>PaymentTerm</td>
                                            <td>int</td>
                                            <td>(Required) PaymentTerm use for filter record by payment term.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-agent-master">
                                <h2>Get master agent list</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetAgentMasterLists?SubDomainId=9
                                </code></pre>
                                <p>This api used for get master agent list. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "fullName": "Lacey Simpkins",
                                    <br />
                                    "emailAddress": "Lacey@hospitalityia.com",
                                    <br />
                                    "phoneNumber": "(800) 940-9387",
                                    <br />
                                    "address": "20 schedule",
                                    <br />
                                    "createdBy": "flux@mailinator.com",
                                    <br />
                                    "createdDate": "2023-03-01T16:30:14.12",
                                    <br />
                                    "role": "Agent"
                                    <br />
                                    {'},'}
                                    <br />
                                    "fullName": "Tina Gordon",
                                    <br />
                                    "emailAddress": "tina-gordon@leavitt.com",
                                    <br />
                                    "phoneNumber": "(707) 272-8881",
                                    <br />
                                    "address": "20 schedule",
                                    <br />
                                    "createdBy": "flux@mailinator.com",
                                    <br />
                                    "createdDate": "2023-03-01T16:30:14.13",
                                    <br />
                                    "role": "Agent"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-term-summary">
                                <h2>Get payment term summary by Insured and agency</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetPaymentTermSummaryByInsuredAndAgency?SubDomainId=9&PaymentTerm=9&id=0
                                </code></pre>
                                <p>This api used for get master agent list. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "DSFOEWFKEW-01",
                                    <br />
                                    "insuredEmail": "dfsd@fddsa.com",
                                    <br />
                                    "phoneNumber": "0000000000",
                                    <br />
                                    "policyNumber": "efInW9BWMd",
                                    <br />
                                    "paymentId": "00WG89",
                                    <br />
                                    "premiumAmount": 5240.52,
                                    <br />
                                    "dueDateOfPayment": "2023-03-16T00:00:00",
                                    <br />
                                    "monthlyAmount": 362.7,
                                    <br />
                                    "downPayment": 1952.52,
                                    <br />
                                    "termLength": 9,
                                    <br />
                                    "createdDate": "2023-03-06T13:04:53.93",
                                    <br />
                                    "createdBy": "theresa@pauli-shaw.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "delivery0165446546465",
                                    <br />
                                    "insuredEmail": "test@gmail.com",
                                    <br />
                                    "phoneNumber": "0000000000",
                                    <br />
                                    "policyNumber": "oGN2vXZyRo",
                                    <br />
                                    "paymentId": "01M0YY",
                                    <br />
                                    "premium_Amount": 13096.57,
                                    <br />
                                    "dueDateOfPayment": "2023-03-17T00:00:00",
                                    <br />
                                    "monthlyAmount": 1011.81,
                                    <br />
                                    "downPayment": 3782.32,
                                    <br />
                                    "termLength": 9,
                                    <br />
                                    "createdDate": "2023-03-07T14:59:29.21",
                                    <br />
                                    "createdBy": "Bret@360riskpartners.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="overflow-hidden content-section" id="content-detail-by-agent">
                                <h2>Get Account Details by Agent</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://api-staging.xgenbilling.com/api/fluxreports/GetBillingAccountDetailsbyAgent?SubDomainId=9&id=0
                                </code></pre>
                                <p>This api used for get account details by agent. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    "insuredName": "DSFOEWFKEW-01",
                                    <br />
                                    "insuredEmail": "dfsd@fddsa.com",
                                    <br />
                                    "phoneNumber": "0000000000",
                                    <br />
                                    "policyNumber": "efInW9BWMd",
                                    <br />
                                    "paymentId": "00WG89",
                                    <br />
                                    "premiumAmount": 5240.52,
                                    <br />
                                    "dueDateOfPayment": "2023-03-16T00:00:00",
                                    <br />
                                    "monthlyAmount": 362.7,
                                    <br />
                                    "downPayment": 1952.52,
                                    <br />
                                    "termLength": 9,
                                    <br />
                                    "createdDate": "2023-03-06T13:04:53.93",
                                    <br />
                                    "createdBy": "theresa@pauli-shaw.com"
                                    <br />
                                    {'},'}
                                    <br />
                                    "insuredName": "delivery0165446546465",
                                    <br />
                                    "insuredEmail": "test@gmail.com",
                                    <br />
                                    "phoneNumber": "0000000000",
                                    <br />
                                    "policyNumber": "oGN2vXZyRo",
                                    <br />
                                    "paymentId": "01M0YY",
                                    <br />
                                    "premiumAmount": 13096.57,
                                    <br />
                                    "dueDateOfPayment": "2023-03-17T00:00:00",
                                    <br />
                                    "monthlyAmount": 1011.81,
                                    <br />
                                    "downPayment": 3782.32,
                                    <br />
                                    "termLength": 9,
                                    <br />
                                    "createdDate": "2023-03-07T14:59:29.21",
                                    <br />
                                    "createdBy": "Bret@360riskpartners.com"
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                        <tr>
                                            <td>ID</td>
                                            <td>Date Time</td>
                                            <td>(Required) For all pass 0 . </td>
                                        </tr>
                                        <tr>
                                            <td>GeneralSearch</td>
                                            <td>String</td>
                                            <td>(Optional) This is general search text. </td>
                                        </tr>

                                        <tr>
                                            <td>LimitTo</td>
                                            <td>int</td>
                                            <td>(Optional) LimitTo for you want how many records you want get. </td>
                                        </tr>
                                        <tr>
                                            <td>PageNumber</td>
                                            <td>int</td>
                                            <td>(Optional) PageNumber which page number data you want to see. </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="overflow-hidden content-section" id="content-all-report-users">
                                <h2>Get all user list</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/fluxreports/GetAllReportUsers?SubDomainId=19
                                </code></pre>
                                <p>This api used for get all users in a agency. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "TotalRecord":2,
                                    <br />
                                    "Data":[
                                    <br />
                                    {'{'}
                                    <br />
                                    <br />
                                    "id": "00A63EA0-540A-45B1-99B7-93CD62F440CF",
                                    "name": null,
                                    <br />
                                    "emailAddress": null,
                                    <br />
                                    "passwordHash": null,
                                    <br />
                                    "phoneNumber": "(541) 420-8628",
                                    <br />
                                    "flowGroupId": 0,
                                    <br />
                                    "isActive": false,
                                    <br />
                                    "isTextActive": false,
                                    <br />
                                    "createdBy": null,
                                    <br />
                                    "modifiedBy": null,
                                    <br />
                                    "address": null,
                                    <br />
                                    "createdFor": null,
                                    <br />
                                    "policyNumber": null,
                                    <br />
                                    "city": null,
                                    <br />
                                    "state": null,
                                    <br />
                                    "zip": null,
                                    <br />
                                    "policyEffectiveDate": "0001-01-01T00:00:00",
                                    <br />
                                    "coverageType": null,
                                    <br />
                                    "paymentAmount": 0,
                                    <br />
                                    "downPayment": 0,
                                    <br />
                                    "userName": "bob@centuryins.com",
                                    <br />
                                    "fullName": null,
                                    <br />
                                    "normalizedUserName": "bob@centuryins.com",
                                    <br />
                                    "email": "bob@centuryins.com",
                                    <br />
                                    "normalizedEmail": "bob@centuryins.com",
                                    <br />
                                    "emailConfirmed": false,
                                    <br />
                                    "securityStamp": null,
                                    <br />
                                    "concurrencyStamp": null,
                                    <br />
                                    "phoneNumberConfirmed": false,
                                    <br />
                                    "twoFactorEnabled": false,
                                    <br />
                                    "lockoutEnd": "0001-01-01T00:00:00+00:00",
                                    <br />
                                    "lockoutEnabled": false,
                                    <br />
                                    "accessFailedCount": 0,
                                    <br />
                                    "userRoleIds": null,
                                    <br />
                                    "createdDate": "0001-01-01T00:00:00",
                                    <br />
                                    "userInfoMdl": null,
                                    <br />
                                    "agentInfoMdl": null,
                                    <br />
                                    "roleListMdl": null,
                                    <br />
                                    "subDomainId": "19",
                                    <br />
                                    "url": null,
                                    <br />
                                    "logo": null,
                                    <br />
                                    "fontFamily": null,
                                    <br />
                                    "label1": null,
                                    <br />
                                    "label2": null,
                                    <br />
                                    "color": null,
                                    <br />
                                    "hoverFocus": null,
                                    <br />
                                    "paymentProcessId": 0
                                    <br />
                                    {'}'}
                                    <br />
                                    ],
                                    {/*<br />*/}
                                    {/*"Message":"Successfully got Results"*/}
                                    <br />
                                    {'}'}
                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>SubDomainId</td>
                                            <td>int</td>
                                            <td>(Required) SubDomainId used for getting agency details.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="overflow-hidden content-section" id="content-all-payment-logs">
                                <h2>Get All Payment Logs</h2>
                                <pre><code className="bash">
                                    # URL
                                    Post https://xgenpaymentapiwebapp-new-design.azurewebsites.net/api/reports/GetPaymentLog
                                </code></pre>
                                <p>This api used for get all the payment log. </p>
                                <br />
                                <pre><code className="json">
                                    <br />
                                    <br />
                                    Response example :
                                    <br />
                                    {'{'}
                                    <br />
                                    "status": true,
                                    <br />
                                    "data": {'{'}
                                    <br />
                                    "totalRecord": 1,
                                    <br />
                                    "data": [
                                    <br />
                                    {'{'}
                                    <br />
                                    "entityId": "121290225",
                                    <br />
                                    "entityName": "Stella Carroll",
                                    <br />
                                    "prinBrokEntityId": "8656888",
                                    <br />
                                    "procId": "405346225",
                                    <br />
                                    "contractId": "295160225",
                                    <br />
                                    "contractSetId": "295160225",
                                    <br />
                                    "paymentId": "14438446",
                                    <br />
                                    "payorName": "Stella Carroll",
                                    <br />
                                    "payorEmail": "qadevtest@rtspecialty.com",
                                    <br />
                                    "premiumAmount": 5170.00,
                                    <br />
                                    "serviceFee": 5.00,
                                    <br />
                                    "datePaid": "2024-08-19T17:19:57.783",
                                    <br />
                                    "amountPaid": 5175.00,
                                    <br />
                                    "methodofPayment": "Credit Card",
                                    <br />
                                    "paymentStatusId": 4,
                                    <br />
                                    "paymentStatus": "Paid in Full",
                                    <br />
                                    "billingTypeId": 3,
                                    <br />
                                    "billingType": "Paid In Full",
                                    <br />
                                    "transactionTypeId": 1,
                                    <br />
                                    "transactionType": "New Business"
                                    <br />
                                    {'}'}
                                    <br />
                                    ]
                                    <br />
                                    {'}'}
                                    <br />
                                    {'}'}

                                </code></pre>
                                <h4>QUERY PARAMETERS</h4>
                                <table className="central-overflow-x">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Type</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>start</td>
                                            <td>Date Time</td>
                                            <td>(Optional) From date. </td>
                                        </tr>
                                        <tr>
                                            <td>end</td>
                                            <td>Date Time</td>
                                            <td>(Optional) TO date . </td>
                                        </tr>
                                     
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="content-code"></div>
                    </div>
                </div>

            </>
        );
    }
}
export default ApiDocs;