import React from 'react';
import { NavLink } from 'react-router-dom';
import { Logout, authHeader, subDomain } from './Common';
import { GetAccessModulesByRoleId } from "../services/AccessModulesServices";
import {FlowGroups} from "./Enum";
class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserDetails: authHeader(),
            SubDomain: subDomain(),
            userAccessModules: [],
            DynamicLogoPath: JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo
        };
    }
    componentDidMount() {
        if (this.state.UserDetails != null) {
            let RoleIds = this.state.UserDetails.UserRoleIds;
            if (RoleIds != null) {
                this._GetAccessModulesByRoleId(RoleIds);
            }
        }

    }
    htmlDecode(input) {
        var e = document.createElement('span');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    _GetAccessModulesByRoleId = (RoleId) => {
        GetAccessModulesByRoleId(RoleId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ userAccessModules: response.data });
                }
            }
        });
    }
    
    activateSideBar = () => {
        let element = document.getElementById('sidebar-newDesign');
        element.classList.toggle('sidebar-active');
        if (element.classList.contains('sidebar-active')) {
            let brandLogo = document.getElementById('sidebar-brand');
            brandLogo.src = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
            let menu = document.getElementById('sidebar-menu');
            menu.src = './assets/Content/images/x.png';
        }
        else {
            let brandLogo = document.getElementById('sidebar-brand');
            brandLogo.src = JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo;
            let menu = document.getElementById('sidebar-menu');
            menu.src = './assets/Content/images/menu.png';
        }
    }
    render() {
        let url = this.state.SubDomain.SubDomain === 'Xgen' ? "" : "/" + this.state.SubDomain.SubDomain;
        let subDomainId = this.state.SubDomain.SubDomain.toLowerCase();
        const { userAccessModules } = this.state;
        return (
            <>
                <nav id="sidebar-newDesign" className="sidebar sidebar-active navbar-newDesign sidebar">
                    <div className="sidebar-image">
                        <NavLink to="#" className={`sidebar-brand py-3 w-100 text-center `}>
                                {/*<img src={logoUrl} alt="Company Logo" className={url === 'Xgen' ? 'logo' : 'logo-100p'} />*/}
                            <img id="sidebar-brand" src={JSON.parse(localStorage.getItem("LoggedOutDetails"))?.LoggedOutLogo} alt="Company Logo" class="logo-sidebar" />
                        </NavLink>
                    </div>
                    <div className="sidebar-modules">
                        <ul className="list-unstyled w-100">
                            <li className="sidebar-item" key="99">
                                <div onClick={() => this.activateSideBar()} className="sidebar-link d-block p-3 ps-4 text-decoration-none text-white pointer-event">
                                    <img id="sidebar-menu" className="nav-img" src="./assets/Content/images/x.png" />
                                    <span className="sidebar-text">Collapse</span>
                                </div>
                            </li>
                            {(userAccessModules !== null && userAccessModules.length !== 0) &&
                                userAccessModules.map((route, index) => (
                                    route.is_Navbar === true &&
                                    <li title={route.displayName} className="sidebar-item" key={index}>
                                        <NavLink exact activeClassName="ActiveSidebar-newDesign" to={url + "" + route.moduleURL} className="sidebar-link d-block p-3 ps-4 text-decoration-none">
                                            <span className="nav-img" dangerouslySetInnerHTML={{ __html: this.htmlDecode(route.navBarIcon) }}></span>
                                            <span className="sidebar-text">{route.displayName}</span>
                                        </NavLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div id="lower-sidebar" className="">
                        <ul className="list-unstyled">
                            <>
                            {FlowGroups.Users === authHeader().FlowGroupId &&
                                <li title="My Settings" className="sidebar-item" key="98">
                                    <NavLink exact activeClassName="ActiveSidebar-newDesign" to={url + '/mysettings'} className="sidebar-link d-block p-3 ps-4 text-decoration-none">
                                        <img className="nav-img" src="./assets/Content/images/settings.png" />
                                        <span className="sidebar-text sidebar-lower-text">Settings</span>
                                    </NavLink>
                                </li>
                                
                            }
                            </>
                            <li className="sidebar-item" key="99">
                                <NavLink title="Sign Out" exact activeClassName="ActiveSidebar-newDesign" onClick={() => Logout()} to="abc.com"  className="sidebar-link d-block p-3 ps-4 text-decoration-none">
                                    <img className="nav-img" src="./assets/Content/images/log-out.png" />
                                    <span className="sidebar-text sidebar-lower-text">Sign out</span>
                                </NavLink>
                            </li>
                            <hr />
                            <li className="sidebar-item" key="99">
                                <div className="sidebar-link d-block p-3 ps-3 text-decoration-none">
                                    <img className="nav-img" src="./assets/Content/images/Profile-Image.png" />
                                    <span className="sidebar-text sidebar-lower-text text-white">{this.state.UserDetails.FullName}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}

export { NavBar };