import { showError } from '../common/Common';
import { authHeader } from '../common/Common';
//LoginUser
async function LoginUser(UserName, Password, RememberMe) {
    let UserCredential = {
        UserName: UserName,
        Password: Password,
        RememberMe: RememberMe
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(UserCredential)
    };
    const GetResponse = await fetch('/api/account/Authentication', requestOptions)
        .then((response) => {
            return response.json();
        });

    if (GetResponse.status === true) {
        let url = GetResponse.data.url === 'Xgen' ? '/' : '/' + GetResponse.data.url;
        localStorage.setItem("UserCredential", JSON.stringify({ UserName: UserCredential.UserName, Token: GetResponse.data.userInfoMdl.token, FlowGroupId: GetResponse.data.flowGroupId, UserRoleIds: GetResponse.data.userRoleIds, FullName: GetResponse.data.userInfoMdl.fullName, CustomerId: GetResponse.data.id }));
        localStorage.setItem("SubDomain", JSON.stringify({ SubDomainId: GetResponse.data.subDomainId, SubDomain: GetResponse.data.url, LogoPath: GetResponse.data.logo, FontFamily: GetResponse.data.fontFamily, Label1: GetResponse.data.label1, Label2: GetResponse.data.label2, IsLoggedIn: true, Color: GetResponse.data.color, HoverFocus: GetResponse.data.hoverFocus, PaymentProcessId: GetResponse.data.paymentProcessId, isPremiumfinance: GetResponse.data.premiumFinance, isDirectBilling: GetResponse.data.directBillingFinance, isAISPF: GetResponse.data.isAISPF, isAISDB: GetResponse.data.isAISDB, isBDSecurePF: GetResponse.data.isBDSecurePF, isFinanceProPF: GetResponse.data.isFinanceProPF }));
        window.open(url, '_self');
    }
    else {
        showError(GetResponse.message);
    }
}
//get user profile
async function GetUserProfile() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/account/GetUserProfile', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function AutoLogin() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/account/auto-login', requestOptions)
        .then((response) => {
            return response.json();
        });
    console.log("GetResponse", GetResponse.data)
    if (GetResponse.status === true) {
        let url = GetResponse.data.url === 'Xgen' ? '/' : '/' + GetResponse.data.url;
        localStorage.setItem("UserCredential", JSON.stringify({ UserName: GetResponse.data.Email, Token: GetResponse.data.userInfoMdl.token, FlowGroupId: GetResponse.data.flowGroupId, UserRoleIds: GetResponse.data.userRoleIds, FullName: GetResponse.data.userInfoMdl.fullName, CustomerId: GetResponse.data.id }));
        localStorage.setItem("SubDomain", JSON.stringify({ SubDomainId: GetResponse.data.subDomainId, SubDomain: GetResponse.data.url, LogoPath: GetResponse.data.logo, FontFamily: GetResponse.data.fontFamily, Label1: GetResponse.data.label1, Label2: GetResponse.data.label2, IsLoggedIn: true, Color: GetResponse.data.color, HoverFocus: GetResponse.data.hoverFocus, PaymentProcessId: GetResponse.data.paymentProcessId, isPremiumfinance: GetResponse.data.premiumFinance, isDirectBilling: GetResponse.data.directBillingFinance, isAISPF: GetResponse.data.isAISPF, isAISDB: GetResponse.data.isAISDB, isBDSecurePF: GetResponse.data.isBDSecurePF, isFinanceProPF: GetResponse.data.isFinanceProPF }));
        window.open(url, '_self');
    }
    else {
        showError(GetResponse.message);
    }
}
//get insured data
async function GetInsuredData(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/account/GetInsuredAccountActivity?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Update Email For Insured
async function UpdateEmailForInsured(EmailAddress, PaymentId) {
    var object = {
        EmailAddress: EmailAddress,
        PaymentId: PaymentId
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object)
    };
    const GetResponse = await fetch('/api/account/UpdateEmailForInsured', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//register user - if user successfully register => Login
async function RegisterUserInfo(AspNetUsersMdl) {
 const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(AspNetUsersMdl)
    };
    const GetResponse = await fetch('/api/account/SaveUserInfo', requestOptions)
        .then((response) => {
            return response.json();
        });
   
        if (GetResponse.status === true) {
            // call login function for token and user details
            LoginUser(AspNetUsersMdl.Email, AspNetUsersMdl.PasswordHash);
        }
        else {

            showError(GetResponse.message);
        }  
}
//add and edit new user by super admin
async function AddEditUser(AspNetUsersMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(AspNetUsersMdl)
    };
    const GetResponse = await fetch('/api/account/AddEditUser', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Change Password 
async function ChangePassword(ChangePasswordMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
        body: JSON.stringify(ChangePasswordMdl)
    };
    const GetResponse = await fetch('/api/account/ChangePassword', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function ChangePasswordByUserId(ChangePasswordMdl, userId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}`},
        body: JSON.stringify( ChangePasswordMdl )
    };
    const GetResponse = await fetch('/api/account/ChangePasswordByUserId?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Change Address
async function ChangeAddress(userInfoMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userInfoMdl)
    };
    const GetResponse = await fetch('/api/account/ChangeAddress', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function ChangeAddressByUserId(userInfoMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userInfoMdl)
    };
    const GetResponse = await fetch('/api/account/ChangeAddressByUserId', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Forgot Password 
async function ForgetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
    };
    email = email.replaceAll("+", "%2B");
    const GetResponse = await fetch('/api/account/ForgotPassword/?email=' + email, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Delete User By Email Id
async function DeleteUserByEmailId(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    email = email.replaceAll("+", "%2B");
    const GetResponse = await fetch('/api/account/DeleteUserByEmailId/?email=' + email, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Verify Link By RequestToken
async function VerifyLinkByRequestToken(requestToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/account/VerifyLinkByRequestToken/?requestToken=' + requestToken, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get GetAll User
async function GetAllUser(searchMdl, subDomainId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/account/GetAllUser?subDomainId=' + subDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return  GetResponse;
}

//get All Agen tUser
async function GetAllAgentUser() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/account/GetAllAgentUser', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Get All User By RoleId
async function GetAllUserByRoleId(roleId, subDomainId) {
    roleId = roleId === undefined ? 1 : roleId;
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/account/GetAllUserByRoleId?roleId=' + roleId + '&subDomainId=' + subDomainId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get GetAll Roles
async function GetAllRoles(subDomainId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/account/GetAllRoles?subDomainId=${subDomainId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get role by role id
async function GetRoleByRoleId(roleId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/account/GetRoleByRoleId/?roleId=' + roleId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//add/edit role
async function AddEditRole(roleListMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(roleListMdl)
    };
    const GetResponse = await fetch('/api/account/AddEditRole', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get user info for edit
async function GetUserProfileByEmail(email) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    email = email.replaceAll("+", "%2B");
    const GetResponse = await fetch('/api/account/GetUserProfileByEmail/?email=' + email, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get user info for external user for register by email link
async function GetUserInfoByMailLink(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    const GetResponse = await fetch('/api/account/GetUserInfoByMailLink/?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get user info for external user for register by email link
async function GetUserActiveStatus(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
    };
    const GetResponse = await fetch('/api/account/GetUserActiveStatus/?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get user info for external user for register by email link
async function GetUserInfoByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/account/GetUserInfoByUserId/?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//get user isTextActive by UserId
async function GetUserTextActiveByUserId(userId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/account/GetUserTextActiveByUserId/?userId=' + userId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//check email alrady exists 
async function CheckEmailAreadyexists(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    email = email.replaceAll("+", "%2B");
    const GetResponse = await fetch('/api/account/CheckEmailAreadyexists/?email=' + email, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//check  request Token Validate or not for forgot password
async function CheckTokenValidate(requestToken,passCode) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch(`/api/account/CheckTokenValidate/?requestToken=${requestToken}&passCode=${passCode}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}
//Change Password By PassCode
async function ChangePasswordByPassCode(ChangePasswordMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ChangePasswordMdl)
    };
    const GetResponse = await fetch('/api/account/ChangePasswordByPassCode', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function AddManageText(ManageTextMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(ManageTextMdl)
    };
    const GetResponse = await fetch('/api/account/AddManageText', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get Activation Messages status by UserID
async function GetActivationByUserId(userid) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/account/GetActivationByUserId/?userid=' + userid, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetActivation(userInfoMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(userInfoMdl)
    };
    const GetResponse = await fetch('/api/account/GetActivation', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get Get All Report Users
async function GetAllReportUsers(subDomainId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch(`/api/account/GetAllReportUsers?subDomainId=${subDomainId}`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetState() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/account/GetStates', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetCoverageTypes() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/account/GetCoverageTypes', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//Update CommPhoneNumber For Insured
async function UpdateCommPhoneNumberForInsured(CommPhoneNumber, PaymentId) {
    var object = {
        CommPhoneNumber: CommPhoneNumber,
        PaymentId: PaymentId
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object)
    };
    const GetResponse = await fetch('/api/account/UpdateCommPhoneNumberForInsured', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function GetOldPasswordHash(RequestToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/account/GetOldPasswordHash?requestToken=' + RequestToken, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

async function VerifyToken(token) {
    var object = {
        Token: token
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object)
    };
    const GetResponse = await fetch('/api/account/VerifyToken', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


async function ConvertPasswordToHash(password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };
    const GetResponse = await fetch('/api/account/ConvertPasswordToHash?password=' + password, requestOptions)
    return await GetResponse.json();
}

async function GetAgentInformation(paymentId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    }
    const GetResponse = await fetch('/api/account/GetAgentInformation?paymentId=' + paymentId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

export {
    LoginUser, GetUserProfile, RegisterUserInfo, ChangePassword, ChangeAddress, ForgetPassword, GetAllUser, GetAllAgentUser, GetAllUserByRoleId, AddEditRole,
    AddEditUser, GetAllRoles, GetRoleByRoleId, GetUserProfileByEmail, GetUserInfoByMailLink, CheckEmailAreadyexists, CheckTokenValidate, ChangePasswordByPassCode,
    VerifyLinkByRequestToken, AddManageText, DeleteUserByEmailId, GetActivationByUserId, GetActivation, GetUserTextActiveByUserId, GetAllReportUsers, GetUserInfoByUserId,
    GetState, GetCoverageTypes, UpdateEmailForInsured, ChangePasswordByUserId, ChangeAddressByUserId, GetUserActiveStatus, UpdateCommPhoneNumberForInsured, GetInsuredData,
    AutoLogin, GetOldPasswordHash, ConvertPasswordToHash, VerifyToken, GetAgentInformation
};

    