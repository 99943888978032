import { useEffect, useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { showError, authHeader, subDomain, Button } from '../../../common/Common';
import { GetUserProfile } from '../../../services/UserServices';
export default function ACHOnBoard() {
	const stripe = useStripe();
	const elements = useElements();
	const auth = authHeader();
	const subdomain = subDomain();
	const [isLoading, setIsLoading] = useState(false);
	const [userProfileResponse, setUserProfileResonse] = useState({});

	useEffect(() => {
		GetUserProfile().then(data => {
			let response = data;
			setUserProfileResonse(response);
		});
	}, [])

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		if (subdomain.PaymentProcessId === 2) {
			createFppCustomer(e);
		}
		else {
			createBankAccount(e).then(createAccount);
		}

	}
	// when submitted,
	// - tokenize bank details
	const createBankAccount = async (e) => {
		var account = e.target.account_number.value;
		var routing = e.target.routing_number.value;
		var name = e.target.ac_holder_name.value;
		return stripe.createToken('bank_account', {
			country: 'us',
			currency: 'USD',
			account_number: account,
			routing_number: routing,
			account_holder_type: 'individual',
			account_holder_name: name,
		}).then(function (response) {
			return response.token.id; //btok_xxx
		});
	}

	// - create account
	const createAccount = async (bankToken) => { // btok_xxx
		var fd = new FormData(document.getElementById('account-form'));
		fd.set('external_account', bankToken);
		let subDomainName = subdomain.SubDomain.toLowerCase() !== 'xgen' ? subdomain.SubDomain : '';
		fd.set('subDomain', subDomainName);
		return await fetch('/api/stripe/create-express-account', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(Object.fromEntries(fd))
		}).then(response => response.json())
			.then((res) => {
				//redirect user to connect onboarding account creation
				window.location.href = res.data.url;
			}).catch(err => {
				showError(err);
			});
		//.then(function (response) {
		//    console.log(JSON.stringify(response, null, 2));
		//});
	}

	const createFppCustomer = async (e) => {
		var account = e.target.account_number.value;
		var routing = e.target.routing_number.value;
		var name = e.target.ac_holder_name.value;
		let subDomainName = subdomain.SubDomain.toLowerCase() !== 'xgen' ? subdomain.SubDomain : '';
		var customerDetails = {
			firstName: name,
			lastName: ".",
			phone: "",
			email: userProfileResponse.data.userName,
			address1: userProfileResponse.data.userInfoMdl.address,
			address2: "",
			city: userProfileResponse.data.userInfoMdl.city,
			state: userProfileResponse.data.userInfoMdl.state,
			zipCode: userProfileResponse.data.userInfoMdl.zip,
			accountName: name,
			accountNumber: account,
			accountType: "Savings",
			routingNumber: routing,
			identifier: userProfileResponse.data.id
		};

		return await fetch('/api/FortPoint/create-customer', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(customerDetails)
		}).then(response => response.json())
			.then((res) => {
				//redirect user to connect onboarding account creation
				window.location.reload();
			}).catch(err => {
				//showError(err);
			});
	}


	return (
		<form className="w-75 m-auto" id="account-form" onSubmit={handleSubmit}>
			<div className='ACH-Section' id='ACH-Section'>
				<input type="hidden" value={auth.FullName} name="full_name" id="full_name" />
				<input type="hidden" value={auth.UserName} name="user_name" id="user_name" />
				<input type="hidden" value={auth.CustomerId} name="customerId" id="user_name" />
				<div className="form-floating input-wrapper-payment mb-3">
					<input type="text" className="form-control" id="AccountHolderName" name="ac_holder_name" placeholder="Account holder name*" required />
					<label htmlFor="AccountHolderName">Account Holder Name</label>
				</div>
				<div className="form-floating input-wrapper-payment mb-3">
					<input type="number" className="form-control" id="RoutingNumber" name="routing_number" placeholder="9 digit routing number*" maxLength='9' required />
					<label htmlFor="RoutingNumber">9 Digit Routing Number </label>
				</div>
				<div className="form-floating  input-wrapper-payment mb-3">
					<input type="number" className="form-control" id="AccountNumber" name="account_number" placeholder="Account number*" required />
					<label htmlFor="AccountNumber">Account Number</label>
				</div>
			</div>
			<div className="text-center">
				<Button type="submit" className="btn btn-primary btn-lg px-5 submit-btn" >Submit</Button>
			</div>
		</form>
	)
}