import { authHeader } from '../common/Common';

//Get All company list
async function GetAllCompany() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch(`/api/AIS/GetAllCompany`, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

export {
    GetAllCompany
};